import { Log, UserManager, WebStorageStateStore } from "oidc-client";
import jwtDecode from "jwt-decode";
import UserAPI from "../api/UserAPI";
import moment from "moment";
import AuthAPI from "../api/AuthAPI";

export default class AuthService {
  manager;
  user;
  _pendingReLogin;
  constructor() {
    this.init(config);
  }
  init(config) {
    this.manager = new UserManager(config);
    Log.logger = console;
    Log.level = Log.DEBUG;
    this.registerEvents();
  }

  registerEvents() {
    // register the event callbacks
    this.manager.events.addUserLoaded(this.onUserLoaded);
    this.manager.events.addSilentRenewError(this.onSilentRenewError);
    this.manager.events.addAccessTokenExpired(this.onAccessTokenExpired);
    this.manager.events.addAccessTokenExpiring(this.onAccessTokenExpiring);
    this.manager.events.addUserUnloaded(this.onUserUnloaded);
    this.manager.events.addUserSignedOut(this.onUserSignedOut);
  }

  unregisterEvents() {
    // unregister the event callbacks
    this.manager.events.removeUserLoaded(this.onUserLoaded);
    this.manager.events.removeSilentRenewError(this.onSilentRenewError);
    this.manager.events.removeAccessTokenExpired(this.onAccessTokenExpired);
    this.manager.events.removeAccessTokenExpiring(this.onAccessTokenExpiring);
    this.manager.events.removeUserUnloaded(this.onUserUnloaded);
    this.manager.events.removeUserSignedOut(this.onUserSignedOut);
  }

  // event callback when the user has been loaded (on silent renew or redirect)
  onUserLoaded = user => {
    sessionStorage.setItem("access_token", user.access_token);
    sessionStorage.setItem("refresh_token", user.refresh_token);
    this.setUserInfo({
      accessToken: user.access_token,
      refreshToken: user.refresh_token
    });
    if (!this.user) {
      AuthAPI.login().then(res => {
        this._getRemoteUser(user).then(userDto => {
          if (window.location.href.indexOf("/") !== -1) {
            this.navigateToScreen();
          }
        });
      });
    }
  };

  // event callback when silent renew errored
  onSilentRenewError = error => {
    console.log("Event :silent renew error - Redirecting to login");
  };

  // event callback when the access token expired
  onAccessTokenExpired = () => {
    console.log("Event : access token expired");
    this.removeLocalStore();
    // this.silentRenew();
  };

  // event callback when the user is logged out
  onUserUnloaded = () => {
    console.log("Event :user is logged out");
    this.removeLocalStore();
  };

  // event callback when the user is expiring
  onAccessTokenExpiring = () => {
    this.reLogin();
    //this.silentRenew();
  };

  // event callback when the user is signed out
  onUserSignedOut = () => {
    console.log("user is signed out");
    this.user = null;
  };

  isLoggedIn() {
    return this.user != null && this.user.access_token && !this.user.expired;
  }

  getUser() {
    if (this.isLoggedIn()) {
      return this.user;
    }
    return null;
  }

  loadUser = async () => {
    try {
      const user = await this.manager.getUser();
      this.user = user;
      if (!user || user.expired) {
        this.removeUser();
        this.removeLocalStore();
        this.user = null;
        return null;
      }
      return user;
    } catch (error) {
      this.handleError(error);
    }
    return null;
  };

  loadLoggedInUserToStore = () => {
    let cachedUser = sessionStorage.getItem("loggedInUser");
    if (!cachedUser) {
      this.loadUser().then(user => this._getRemoteUser(user));
    }
  };

  async _getRemoteUser(user) {
    if (user) {
      try {
        console.log(
          "Unique id: " + user.profile[process.env.REACT_APP_UNIQUE_ID_ALIAS]
        );
        // let isFirstUser = await UserAPI.isFirstUser(
        //   user.profile[process.env.REACT_APP_UNIQUE_ID_ALIAS]
        // );
        // if (isFirstUser) {
        //   this.logout();
        //   this.removeLocalStore();
        //   return;
        // }
        let userDb = await UserAPI.getProfile(
          user.profile[process.env.REACT_APP_UNIQUE_ID_ALIAS]
        );
        if (!userDb) {
          this.removeUser();
          this.removeLocalStore();
          return;
        }
        sessionStorage.setItem("loggedInUser", JSON.stringify(userDb));
        return userDb;
      } catch (error) {
        this.removeUser();
        this.removeLocalStore();
        console.error(error);
      }
    } else {
      this.removeLocalStore();
      return;
    }
  }

  removeLocalStore = () => {
    this.user = null;
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("loggedInUser");
    sessionStorage.removeItem("menuData");
  };

  setUserInfo = authResult => {
    const data = jwtDecode(authResult.accessToken);
    this.setSessionInfo(authResult);
    this.setUser(data[process.env.REACT_APP_UNIQUE_ID_ALIAS]);
  };

  setUser = data => {
    sessionStorage.setItem("userId", data);
  };

  navigateToScreen = () => {
    window.location.replace("/protected");
  };

  setSessionInfo(authResult) {
    sessionStorage.setItem("access_token", authResult.accessToken);
    sessionStorage.setItem("refresh_token", authResult.refreshToken);
  }

  isAuthenticated = () => {
    const access_token = sessionStorage.getItem("access_token");
    if (access_token) {
      let aToken = jwtDecode(access_token);
      let now = moment()
        .utc()
        .unix();
      return aToken.exp - now > 0;
    }
    return !!access_token;
  };

  login() {
    this.manager.signinPopup().catch(error => this.handleError(error));
  }

  logout() {
    let _user = this.user;
    this.manager
      .removeUser()
      .then(() => {
        this.manager.revokeAccessToken();
        this.removeLocalStore();
      })
      .catch(error => {
        console.error(error);
      });

    this.manager
      .signoutRedirect({ id_token_hint: _user && _user.id_token })
      .catch(error => {
        console.error(error);
      });

    _user = null;
  }

  reLogin() {
    if (this._pendingReLogin) {
      return Promise.reject("re-login already in progress");
    }

    return (this._pendingReLogin = this.manager
      .signinPopup({
        redirect_uri: process.env.REACT_APP_CMP_BASE_URL + "relogin_auth.html"
      })
      .then(() => {
        this._pendingReLogin = null;
      })
      .catch(error => {
        this._pendingReLogin = null;
        this.handleError(error);
      }));
  }

  removeUser() {
    this.manager.removeUser();
    this.manager.revokeAccessToken();
    this.manager.clearStaleState();
  }

  silentRenew() {
    let additional = {
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      client_id: process.env.REACT_APP_CLIENT_ID,
      scope: process.env.REACT_APP_SCOPE
    };
    return this.manager
      .signinSilent(additional)
      .then(user => {
        this.user = user;
        let aToken = jwtDecode(user.access_token);
        let now = moment()
          .utc()
          .unix();
        console.log(
          "Silent Renew successfull :Access Token Expires in :",
          aToken.exp - now
        );
        AuthAPI.login().then(res => {});
        return user;
      })
      .catch(error => this.handleError(error));
  }

  signinRedirect = () => {
    sessionStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  handleError(error) {
    console.error("Problem with authentication endpoint: ", error);
  }
}

export const config = {
  authority: process.env.REACT_APP_IDP_AUTH_URL,
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  response_type: process.env.REACT_APP_RESPONSE_TYPE,
  scope: process.env.REACT_APP_SCOPE,
  acr_values: process.env.REACT_APP_ACR_VALUES,
  ui_locales: process.env.REACT_APP_UI_LOCALES,
  filterProtocolClaims: true,
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  includeIdTokenInSilentRenew: true,
  loadUserInfo: false,
  accessTokenExpiringNotificationTime: 20,
  popup_redirect_uri: process.env.REACT_APP_CMP_BASE_URL + "popup_auth.html",
  redirect_uri: process.env.REACT_APP_CMP_BASE_URL + "popup_auth.html",
  silent_redirect_uri: process.env.REACT_APP_CMP_BASE_URL + "silent_auth.html",
  logout_redirect_uri: process.env.REACT_APP_CMP_BASE_URL + "logout_auth.html",
  post_logout_redirect_uri:process.env.REACT_APP_CMP_BASE_URL + "logout_auth.html",
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  popupWindowFeatures:
    "location=no,toolbar=no,width=500,height=678,left=100,top=100",
  ...(process.env.REACT_APP_RESOURCE_ID !=="" && {extraQueryParams: {resource: process.env.REACT_APP_RESOURCE_ID }})
};
