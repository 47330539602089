import ApiService from "../auth/ApiService";
import { handleResponse, handleError } from "../services/serviceUtil";
const apiEndpoint = process.env.REACT_APP_CMP_API_URL;
const resourceUrl = apiEndpoint + "/secured/role";

const RoleAPI = {
  getRoles: async function() {
    return await new ApiService()
      .callApi(resourceUrl, "get")
      .then(handleResponse)
      .catch(handleError);
  }
};

export default RoleAPI;
