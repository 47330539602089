import React, { Component, Fragment} from "react";
import WorkflowAPI from "../api/WorkflowAPI";
import RoleAPI from "../api/RoleAPI";
import StateAPI from "../api/StateAPI";
import { withTranslation } from "react-i18next";
import DesignAPI from "../api/DesignAPI";
import { CommonContext } from "../context/CommonContext";
const $ = window.$;


class SettingsOverviewPage extends Component {
  state = {
    t: this.props.t,
    workflows: null,
    roles: null,
    caseStates: null,
    skins : null,
    name: '',
    editable:true
  }

  componentDidMount() {
    this.loadWorkflowData();
    this.loadUserRoleData();
    this.loadCaseStatesData();
    this.loadAllSkinsData();
    this.initiateSkinUploadDropArea();
    this.loadCustomTitle();
  }

  loadCustomTitle = async() => {
    await DesignAPI.getCustomTitle(sessionStorage.getItem("userId"))
    .then(data => sessionStorage.setItem("customTitle", data.name))
    .catch(error => {throw error;})
  }

  loadWorkflowData = () => {
    WorkflowAPI.getWorkflows().then(workflows => {
      this.setState({
        workflows: workflows
      });
    }).catch(error => {
      throw error;
    });
  }

  loadUserRoleData = () => {
    RoleAPI.getRoles().then(roles => {
      this.setState({
        roles: roles
      });
    }).catch(error => {
      throw error;
    });
  }

  loadCaseStatesData = () => {
    StateAPI.getAllStates().then(states => {
      this.setState({
        caseStates: states
      });
    }).catch(error => {
      throw error;
    });
  }

  loadAllSkinsData = () => {
    DesignAPI.getAllSkins().then(skins => {
      this.setState({
        skins: skins
      });
    }).catch(error => {
      throw error;
    });
  }

  initiateSkinUploadDropArea = () => {
    let { t } = this.state;

    const dropArea = $(".drag-area")[0];
    const button = dropArea.querySelector("button");
    const input = dropArea.querySelector("input");
    const dragText = dropArea.querySelector(".drag-area-text");

    button.onclick = () =>{
      //if user click on the button then the input also clicked
      input.click();
    }

    //If user Drag File Over DropArea
    dropArea.addEventListener("dragover", (event)=>{
      event.preventDefault(); //preventing from default behaviour
      dropArea.classList.add("active");
      dragText.textContent = t("portalSettings.overview.skins.dropArea.dragover");
    });

    //If user leave dragged File from DropArea
    dropArea.addEventListener("dragleave", ()=>{
      dropArea.classList.remove("active");
      dragText.textContent = t("portalSettings.overview.skins.dropArea.default");
    });

    //If user drop File on DropArea
    dropArea.addEventListener("drop", (event)=>{
      event.preventDefault(); //preventing from default behaviour
      dropArea.classList.remove("active");
      dragText.textContent = t("portalSettings.overview.skins.dropArea.default");
      input.files = event.dataTransfer.files;
      input.onchange = this.onChangeHandlerDrag;
      input.onchange(event);
    });
  }

  onChangeHandler = event => {
    let { t } = this.state;

    console.log(event.target.files[0])

    const data = new FormData();
    data.append('file', event.target.files[0]);

    DesignAPI.uploadSkins(data)
    .then(() => {
      $.notification("success", t("common.notification.success"), t("portalSettings.overview.skins.validations.SUCCESS"));
    }).catch(error => {
      this.errorHandling(error);
    });
  }

  onChangeHandlerDrag = event => {
    let { t } = this.state;

    const data = new FormData();
    data.append('file', event.dataTransfer.files[0]);

    DesignAPI.uploadSkins(data).
    then(() => {
      $.notification("success", t("common.notification.success"), t("portalSettings.overview.skins.validations.SUCCESS"));
    }).catch(error => {
      this.errorHandling(error);
    });
  }

  errorHandling = error => {
    let { t } = this.state;

    if (error.response) {
      $.notification("error",
          t("common.notification.error"),
          (error.response.data=="INVALID_SKIN_FORMAT"?
              t("portalSettings.overview.skins.validations.INVALID_SKIN_FORMAT"):
              error.response.data)
      );
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
  }

  onClickSkinHandler = (filename) => {
    let { t } = this.state;

    const data = new FormData();
    data.append('filename', filename);
    DesignAPI.changeSkin(data)
    .then(() => {
      $.notification("success", t("common.notification.success"), t("portalSettings.overview.skins.validations.SUCCESS"));
    }).catch(error => {
      this.errorHandling(error);
    });
  }

  onClickSkinRemoveHandler = (filename) => {
    let { t } = this.state;

    const data = new FormData();
    data.append('filename', filename);
    DesignAPI.removeSkin(data).
    then(() => {
      $.notification("success", t("common.notification.success"), t("portalSettings.overview.skins.validations.REMOVED"));
      this.loadAllSkinsData();
    }).catch(error => {
      this.errorHandling(error);
    });
  }

  handleChange = event => {
    event.preventDefault()
    const {name, value} = event.target
    this.setState({name:value});
    this.setState({ editable: false });
  }

  handleSave = async(event) => {
    event.preventDefault()
    const { name } = this.state
    const { setTitle } = this.context;
    setTitle(name)
    await DesignAPI.updateCustomTitle(name)
    .then($.notification("success", "CCM title", "title saved successfully"))
    .catch(error => {
      this.errorHandling(error);
    })
    this.loadCustomTitle();
    this.setState({ editable: true });
  }

  generateWorkflowName(field) {
    let { t } = this.state;

    if(field.includes("portalSettings.workflows.new_workflow.name")){
      field = <span className="time-label"><span className="bg-green">{t(field)}</span></span>
    }

    return field;
  }

  static contextType = CommonContext;
  render() {
    const { t } = this.props;
    const { workflows, roles, caseStates, skins, editable } = this.state;
    return (
      <Fragment>
        <div className="content-wrapper">
          <section className="content-header">
            <h1>
              {t("portalSettings.overview.title")} <small />
            </h1>
          </section>

          <section className="content">
            <div className="row">
              <div className="col-md-4">
                <form role="form">
                  <label htmlFor="title">{t("portalSettings.overview.customTitle.title")}</label>
                  <div style={{display:'flex', marginBottom:20}}>
                    <input
                      type="text"
                      name="title"
                      className="form-control"
                      id="title"
                      defaultValue={this.state.name ==="" ? sessionStorage.getItem("customTitle") : this.state.name }
                      onChange={this.handleChange}
                      placeholder={t("portalSettings.overview.customTitle.placeholder")}
                    />
                    <button
                      type="button"
                      disabled={editable}
                      className="btn btn-primary"
                      style={{marginLeft:10}}
                      onClick={this.handleSave}
                      >{t("portalSettings.overview.customTitle.button")}</button>
                  </div>
                </form>
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <div className="box box-solid">
                  <div className="box-header with-border">
                    <i className="fa fa-code-fork"></i>

                    <h3 className="box-title">{t("portalSettings.workflows.title")}</h3>
                  </div>
                  <div className="box-body">
                    <ul>
                      { workflows && workflows.map(option => <li key={option.id}> {this.generateWorkflowName(option.name)} </li>)}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="box box-solid">
                  <div className="box-header with-border">
                    <i className="fa fa-users"></i>

                    <h3 className="box-title">{t("portalSettings.userRoles.title")}</h3>
                  </div>
                  <div className="box-body">
                    <ul>
                      { roles && roles.map(option => <li key={option.id}> {option.name} </li>)}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="box box-solid">
                  <div className="box-header with-border">
                    <i className="fa fa-briefcase"></i>

                    <h3 className="box-title">{t("portalSettings.caseStates.title")}</h3>
                  </div>
                  <div className="box-body">
                    <ul>
                      {  caseStates && caseStates.map(option => <li key={option.id}> {option.name} </li>)}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="box box-solid">
                  <div className="box-header with-border">
                    <i className="fa fa-paint-brush"></i>

                    <h3 className="box-title">{t("portalSettings.overview.skins.title")}</h3>
                  </div>
                  <div className="box-body">
                    <h5>{t("portalSettings.overview.skins.list.title")}</h5>
                    <ul className="uploaded-file-list">
                    { skins && skins.map(option => {
                        if(option.default) {
                          return (
                              <li key={option.id}>
                                <button className="skin-choose-btn" onClick={e => {
                                  e.preventDefault();
                                  this.onClickSkinHandler(option.filename)
                                }}><i className="fa fa-paint-brush"></i></button>
                                <a href={process.env.REACT_APP_CMP_BASE_URL+'skins/'+option.filename}> {option.filename} (Default) </a>
                              </li>
                          )
                        } else {
                          return (
                              <li key={option.id}>
                                <button className="skin-choose-btn" onClick={e => {
                                  e.preventDefault();
                                  this.onClickSkinHandler(option.filename)
                                }}><i className="fa fa-paint-brush"></i></button>
                                <a href={process.env.REACT_APP_CMP_BASE_URL+'skins/'+option.filename}> {option.filename}</a>
                                <button className="skin-remove-btn" onClick={e => {
                                  e.preventDefault();
                                  this.onClickSkinRemoveHandler(option.filename)
                                }}
                                        title="Remove"><i className="fa fa-times"></i></button>
                              </li>
                          )
                        }
                    })}
                    </ul>
                    <div className="drag-area">
                      <button><div className="icon"><i className="fa fa-cloud-upload"></i></div></button>
                      <div className="drag-area-text">{t("portalSettings.overview.skins.dropArea.default")}</div>
                      <input type="file" name="file" onChange={this.onChangeHandler} style={{display:'none'}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation("common")(SettingsOverviewPage);
