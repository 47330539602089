import React from "react";

const Loading = () => {
  return (
    <div className="overlay">
      <i className="fa fa-refresh fa-spin" />
    </div>
  );
};

export default Loading;
