import ApiService from "../auth/ApiService";
const apiEndpoint = process.env.REACT_APP_CMP_API_URL;
const resourceUrl = apiEndpoint + "/secured/states/actions";

const StateActionAPI = {
    stateActionData: {
        columns: [
            { title: "Id", field: "id", visible:false},
            { title: "Title", field: "title" },
            { title: "Predefined actions", field: "predefinedActionsTranslated" },
            { title: "Workflow", field: "selectedWorkflowName" }
        ],
        data: []
    },

    all: async function() {
        let data = await new ApiService().callApi(`${resourceUrl}`, "get");
        this.stateActionData.data = data.data;
        return this.stateActionData;
    }
}

export default StateActionAPI;