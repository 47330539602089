import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from "lodash";
const $ = window.$;

export class CaseExpression extends Component {

    selectedValue = "";
    changeCondition = (event, accessExpressions, isEnable) => {
        var id = event.target.id;
        var currentValue = $('#' + id).attr('value');
        accessExpressions.forEach(c => {
            if (isEnable) {
                if (currentValue == "AND") {
                    $('#enableCondition' + c.expressionId).attr('value', 'OR');
                    $('#enableCondition' + c.expressionId).html('OR');
                } else {
                    $('#enableCondition' + c.expressionId).attr('value', 'AND');
                    $('#enableCondition' + c.expressionId).html('AND');
                }
            } else {
                if (currentValue == "AND") {
                    $('#condition' + c.expressionId).attr('value', 'OR');
                    $('#condition' + c.expressionId).html('OR');
                } else {
                    $('#condition' + c.expressionId).attr('value', 'AND');
                    $('#condition' + c.expressionId).html('AND');
                }
            }
        });
    }
    updateTextArea(information) {
        $('#information').val(information);
    };

    changeExpressionForCurrentCondition(id, condition){
        if(condition!=undefined && condition!=null && condition!=""){
          $('#'+id).attr('value',condition);
          $('#'+id).html(condition);
        }
    }

    render() {
        const stringConditionOptions=[
            {"key":"eq", "title": "equals"},
            {"key":"neq", "title": "not equals"},
            {"key":"contains", "title": "contains"},
            {"key":"ncontains", "title": "not contains"}
        ];
        const integerDecimalConditionOptions=[
            {"key":"eq", "title": "equals"},
            {"key":"neq", "title": "not equals"},
            {"key":"greaterthan", "title": "greater than"},
            {"key":"less than", "title": "less than"}
        ];
        const  dateConditionOptions=[	
            {"key":"before", "title": "before"},	
            {"key":"after", "title": "after"}	
        ];
        const { t, caseAccessExpressions,enableCaseAccessExpressions, workflowAttributes, attributesSorted,oldCondition,enableOldCondition,enableCondition,information} = this.props.params;
        
        if(enableCondition){
            return (
                <div className="modal fade" id="modal-customize-case-access" ref="modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title">{this.props.title}</h4>
                            </div>
                            <div className="modal-body">
                                <ul className="nav nav-tabs pb-10">
                                    <li><a data-toggle="tab" href="#displayConditions" role="tab">{t("caseManagement.caseActions.tabMenu.headerDisplay")}</a></li>
                                    <li><a data-toggle="tab" href="#enableConditions" role="tab">{t("caseManagement.caseActions.tabMenu.headerEnable")}</a></li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="displayConditions" role="tabpanel">
                                        <p className="info-text">{t("caseManagement.caseActions.tabMenu.displayText")}</p>
                                        <div className="row">
                                            <div className="col-lg-1">
                                                <button type="button" className="btn" aria-label="Add expression" onClick={() => this.props.addCaseAccessExpression(attributesSorted)}>
                                                    <span className="fa fa-plus " />
                                                </button>
                                            </div>
                                            <div className="col-lg-11">
                                                <form>
                                                    <table className="expression-table" id="expressionTable">
                                                        {caseAccessExpressions &&
                                                            caseAccessExpressions.sort((a, b) => a.expressionId > b.expressionId ? 1 : -1).map((expression, idx) => {
                                                                if (caseAccessExpressions.length == 1 || (caseAccessExpressions.length > 1 && caseAccessExpressions[caseAccessExpressions.length - 1].expressionId == expression.expressionId)) {
                                                                    return (
                                                                        <tbody key={'tb' + expression.expressionId}>
                                                                            <tr key={expression.expressionId} style={{ border: "none" }}>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        <select className="form-control select2" id={"attribute" + expression.expressionId} defaultValue={expression.attribute} onChange={(event) => this.props.changeAttribute(event, attributesSorted)} >
                                                                                            <option value="">-- Select --</option>
                                                                                            {workflowAttributes && attributesSorted &&
                                                                                                attributesSorted.map(option => {
                                                                                                    if (option.attribute == expression.attribute) {
                                                                                                        return (
                                                                                                            <option key={expression.expressionId + option.id} value={option.attribute} selected>
                                                                                                                {option.attributeName}
                                                                                                            </option>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <option key={expression.expressionId + option.id} value={option.attribute}>
                                                                                                                {option.attributeName}
                                                                                                            </option>
                                                                                                        )
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        <select className="form-control" id={"operator" + expression.expressionId} defaultValue={expression.operator} onChange={this.changeExpression}>
                                                                                            {
                                                                                                expression.type.toUpperCase() === 'DATE' ?
                                                                                                    dateConditionOptions.map(option => {
                                                                                                        return (
                                                                                                            <option key={option.key} value={option.key}>
                                                                                                                {option.title}
                                                                                                            </option>
                                                                                                        )
                                                                                                    }) :
                                                                                                    expression.type.toUpperCase() === 'INTEGER' || expression.type.toUpperCase() === 'DECIMAL' ?
                                                                                                        integerDecimalConditionOptions.map(option => {
                                                                                                            return (
                                                                                                                <option key={option.key} value={option.key}>
                                                                                                                    {option.title}
                                                                                                                </option>
                                                                                                            )
                                                                                                        }) :
                                                                                                        stringConditionOptions.map(option => {
                                                                                                            return (
                                                                                                                <option key={option.key} value={option.key}>
                                                                                                                    {option.title}
                                                                                                                </option>
                                                                                                            )
                                                                                                        })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        {
                                                                                            expression.type.toUpperCase() === 'DATE' ?
                                                                                                <DatePicker id={"expression" + expression.expressionId} selected={expression.value} dateFormat="yyyy-MM-dd" onChange={(date) => this.props.handleDateChange(expression, date)} /> : <input type="text" className="form-control" id={"expression" + expression.expressionId} defaultValue={expression.value} onChange={this.changeExpression}></input>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        <button type="button" className="btn" aria-label="Remove expression" onClick={e => {
                                                                                            e.preventDefault();
                                                                                            this.props.removeCaseAccessExpression(idx);
                                                                                        }}>
                                                                                            <span className="fa fa-times" />
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <tbody key={'tb' + expression.expressionId}>
                                                                            <tr key={expression.expressionId} style={{ border: "none" }}>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        <select className="form-control select2" id={"attribute" + expression.expressionId} defaultValue={expression.attribute} onChange={(event) => this.props.changeAttribute(event, attributesSorted)} >
                                                                                            <option value="">-- Select --</option>
                                                                                            {workflowAttributes && attributesSorted &&
                                                                                                attributesSorted.map(option => {
                                                                                                    if (option.attribute == expression.attribute) {
                                                                                                        return (
                                                                                                            <option key={expression.expressionId + option.id} value={option.attribute} selected="selected">
                                                                                                                {option.attributeName}
                                                                                                            </option>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <option key={expression.expressionId + option.id} value={option.attribute}>
                                                                                                                {option.attributeName}
                                                                                                            </option>
                                                                                                        )
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        <select className="form-control" id={"operator" + expression.expressionId} defaultValue={expression.operator}>
                                                                                            {
                                                                                                expression.type.toUpperCase() === 'DATE' ?
                                                                                                    dateConditionOptions.map(option => {
                                                                                                        return (
                                                                                                            <option key={option.key} value={option.key}>
                                                                                                                {option.title}
                                                                                                            </option>
                                                                                                        )
                                                                                                    }) :
                                                                                                    expression.type.toUpperCase() === 'INTEGER' || expression.type.toUpperCase() === 'DECIMAL' ?
                                                                                                        integerDecimalConditionOptions.map(option => {
                                                                                                            return (
                                                                                                                <option key={option.key} value={option.key}>
                                                                                                                    {option.title}
                                                                                                                </option>
                                                                                                            )
                                                                                                        }) :
                                                                                                        stringConditionOptions.map(option => {
                                                                                                            return (
                                                                                                                <option key={option.key} value={option.key}>
                                                                                                                    {option.title}
                                                                                                                </option>
                                                                                                            )
                                                                                                        })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        {
                                                                                            expression.type.toUpperCase() === 'DATE' ?
                                                                                                <DatePicker id={"expression" + expression.expressionId} selected={expression.value} dateFormat="yyyy-MM-dd" onChange={(date) => this.props.handleDateChange(expression, date)} />
                                                                                                : <input type="text" className="form-control tags" id={"expression" + expression.expressionId} defaultValue={expression.value}></input>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        <button type="button" className="btn" aria-label="Remove expression" onClick={e => {
                                                                                            e.preventDefault();
                                                                                            this.props.removeCaseAccessExpression(idx);
                                                                                        }}>
                                                                                            <span className="fa fa-times" />
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td colSpan={2}>
                                                                                    {
                                                                                        expression.condition !== undefined && expression.condition !== null && expression.condition !== "" ?
                                                                                            <button type="button" id={"condition" + expression.expressionId} className="btn btn-default condition-button" value={expression.condition} onClick={(event) => { this.changeCondition(event, caseAccessExpressions) }}>{expression.condition}</button> :
                                                                                            oldCondition !== "" ? <button type="button" id={"condition" + expression.expressionId} className="btn btn-default condition-button" value={oldCondition} onClick={(event) => { this.changeCondition(event, caseAccessExpressions) }}>{oldCondition}</button> :
                                                                                                <button type="button" id={"condition" + expression.expressionId} className="btn btn-default condition-button" value="AND" onClick={(event) => { this.changeCondition(event, caseAccessExpressions) }}>AND</button>
                                                                                    }
                                                                                    <div style={{ marginBottom: '10px' }}></div>
                                                                                    {this.changeExpressionForCurrentCondition("condition" + expression.expressionId, expression.condition)}
                                                                                </td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </table>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="enableConditions" role="tabpanel">
                                    <p className="info-text">{t("caseManagement.caseActions.tabMenu.enableText")}</p>
                                        <div id="descriptionRow">
                                            <label htmlFor="description">{t("caseManagement.caseActions.tabMenu.infoMessage")}</label>
                                            <textarea
                                                type="text"
                                                name="information"
                                                className="form-control"
                                                id="information"
                                                placeholder={t("caseManagement.caseActions.tabMenu.infoMessagePlaceholder")}
                                                defaultValue={information}
                                                onChange={this.props.changeInformation}
                                            ></textarea>
                                        </div>
                                        {this.updateTextArea(information)}
                                        <div className="row">
                                            <div className="col-lg-1">
                                                <button type="button" className="btn" aria-label="Add expression" onClick={() => this.props.addEnableCaseAccessExpression(attributesSorted)}>
                                                    <span className="fa fa-plus " />
                                                </button>
                                            </div>
                                            <div className="col-lg-11">
                                                <form>
                                                    <table className="expression-table" id="expressionTable">
                                                        {enableCaseAccessExpressions &&
                                                            enableCaseAccessExpressions.sort((a, b) => a.expressionId > b.expressionId ? 1 : -1).map((expression, idex) => {
                                                                if (enableCaseAccessExpressions.length == 1 || (enableCaseAccessExpressions.length > 1 && enableCaseAccessExpressions[enableCaseAccessExpressions.length - 1].expressionId == expression.expressionId)) {
                                                                    return (
                                                                        <tbody key={'tb' + expression.expressionId}>
                                                                            <tr key={expression.expressionId} style={{ border: "none" }}>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        <select className="form-control select2" id={"enableAttribute" + expression.expressionId} defaultValue={expression.attribute} onChange={(event) => this.props.enableChangeAttribute(event, attributesSorted)} >
                                                                                            <option value="">-- Select --</option>
                                                                                            {workflowAttributes && attributesSorted &&
                                                                                                attributesSorted.map(option => {
                                                                                                    if (option.attribute == expression.attribute) {
                                                                                                        return (
                                                                                                            <option key={expression.expressionId + option.id} value={option.attribute} selected>
                                                                                                                {option.attributeName}
                                                                                                            </option>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <option key={expression.expressionId + option.id} value={option.attribute}>
                                                                                                                {option.attributeName}
                                                                                                            </option>
                                                                                                        )
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        <select className="form-control" id={"enableOperator" + expression.expressionId} defaultValue={expression.operator} onChange={this.changeExpression}>
                                                                                            {
                                                                                                expression.type.toUpperCase() === 'DATE' ?
                                                                                                    dateConditionOptions.map(option => {
                                                                                                        return (
                                                                                                            <option key={option.key} value={option.key}>
                                                                                                                {option.title}
                                                                                                            </option>
                                                                                                        )
                                                                                                    }) :
                                                                                                    expression.type.toUpperCase() === 'INTEGER' || expression.type.toUpperCase() === 'DECIMAL' ?
                                                                                                        integerDecimalConditionOptions.map(option => {
                                                                                                            return (
                                                                                                                <option key={option.key} value={option.key}>
                                                                                                                    {option.title}
                                                                                                                </option>
                                                                                                            )
                                                                                                        }) :
                                                                                                        stringConditionOptions.map(option => {
                                                                                                            return (
                                                                                                                <option key={option.key} value={option.key}>
                                                                                                                    {option.title}
                                                                                                                </option>
                                                                                                            )
                                                                                                        })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        {
                                                                                            expression.type.toUpperCase() === 'DATE' ?
                                                                                                <DatePicker id={"enableExpression" + expression.expressionId} selected={expression.value} dateFormat="yyyy-MM-dd" onChange={(date) => this.props.enableHandleDateChange(expression, date)} /> : <input type="text" className="form-control" id={"enableExpression" + expression.expressionId} defaultValue={expression.value} onChange={this.changeExpression}></input>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        <button type="button" className="btn" aria-label="Remove expression" onClick={e => {
                                                                                            e.preventDefault();
                                                                                            this.props.removeEnableCaseAccessExpression(idex);
                                                                                        }}>
                                                                                            <span className="fa fa-times" />
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <tbody key={'tb' + expression.expressionId}>
                                                                            <tr key={expression.expressionId} style={{ border: "none" }}>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        <select className="form-control select2" id={"enableAttribute" + expression.expressionId} defaultValue={expression.attribute} onChange={(event) => this.props.enableChangeAttribute(event, attributesSorted)} >
                                                                                            <option value="">-- Select --</option>
                                                                                            {workflowAttributes && attributesSorted &&
                                                                                                attributesSorted.map(option => {
                                                                                                    if (option.attribute == expression.attribute) {
                                                                                                        return (
                                                                                                            <option key={expression.expressionId + option.id} value={option.attribute} selected="selected">
                                                                                                                {option.attributeName}
                                                                                                            </option>
                                                                                                        )
                                                                                                    } else {
                                                                                                        return (
                                                                                                            <option key={expression.expressionId + option.id} value={option.attribute}>
                                                                                                                {option.attributeName}
                                                                                                            </option>
                                                                                                        )
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        <select className="form-control" id={"enableOperator" + expression.expressionId} defaultValue={expression.operator}>
                                                                                            {
                                                                                                expression.type.toUpperCase() === 'DATE' ?
                                                                                                    dateConditionOptions.map(option => {
                                                                                                        return (
                                                                                                            <option key={option.key} value={option.key}>
                                                                                                                {option.title}
                                                                                                            </option>
                                                                                                        )
                                                                                                    }) :
                                                                                                    expression.type.toUpperCase() === 'INTEGER' || expression.type.toUpperCase() === 'DECIMAL' ?
                                                                                                        integerDecimalConditionOptions.map(option => {
                                                                                                            return (
                                                                                                                <option key={option.key} value={option.key}>
                                                                                                                    {option.title}
                                                                                                                </option>
                                                                                                            )
                                                                                                        }) :
                                                                                                        stringConditionOptions.map(option => {
                                                                                                            return (
                                                                                                                <option key={option.key} value={option.key}>
                                                                                                                    {option.title}
                                                                                                                </option>
                                                                                                            )
                                                                                                        })
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        {
                                                                                            expression.type.toUpperCase() === 'DATE' ?
                                                                                                <DatePicker id={"enableExpression" + expression.expressionId} selected={expression.value} dateFormat="yyyy-MM-dd" onChange={(date) => this.props.enableHandleDateChange(expression, date)} />
                                                                                                : <input type="text" className="form-control tags" id={"enableExpression" + expression.expressionId} defaultValue={expression.value}></input>
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                                        <button type="button" className="btn" aria-label="Remove expression" onClick={e => {
                                                                                            e.preventDefault();
                                                                                            this.props.removeEnableCaseAccessExpression(idex);
                                                                                        }}>
                                                                                            <span className="fa fa-times" />
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td colSpan={2}>
                                                                                    {
                                                                                        expression.condition !== undefined && expression.condition !== null && expression.condition !== "" ?
                                                                                            <button type="button" id={"enableCondition" + expression.expressionId} className="btn btn-default condition-button" value={expression.condition}
                                                                                                onClick={(event) => { this.changeCondition(event, enableCaseAccessExpressions, true) }}>{expression.condition}</button> :
                                                                                            enableOldCondition !== "" ? <button type="button" id={"enableCondition" + expression.expressionId} className="btn btn-default condition-button"
                                                                                                value={enableOldCondition} onClick={(event) => { this.changeCondition(event, enableCaseAccessExpressions, true) }}>{enableOldCondition}</button> :
                                                                                                <button type="button" id={"enableCondition" + expression.expressionId} className="btn btn-default condition-button" value="AND"
                                                                                                    onClick={(event) => { this.changeCondition(event, enableCaseAccessExpressions, true) }}>AND</button>
                                                                                    }
                                                                                    <div style={{ marginBottom: '10px' }}></div>
                                                                                    {this.changeExpressionForCurrentCondition("enableCondition" + expression.expressionId, expression.condition)}
                                                                                </td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </table>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    data-dismiss="modal"
                                    onClick={this.props.openSpecifyWindow}
                                >{t("common.close")}
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={this.props.handleSaveExpression}
                                >{t("common.save")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }else{
            return (
                <div className="modal fade" id="modal-customize-case-access" ref="modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title">{this.props.title}</h4>
                            </div>
                            <div className="modal-body">
    
                                <div className="row">
                                    <div className="col-lg-1">
                                        <button type="button" className="btn" aria-label="Add expression" onClick={()=>this.props.addCaseAccessExpression(attributesSorted)}>
                                            <span className="fa fa-plus " />
                                        </button>
                                    </div>
                                    <div className="col-lg-11">
                                        <form>
                                            <table className="expression-table" id="expressionTable">
                                                {caseAccessExpressions &&
                                                    caseAccessExpressions.sort((a, b) => a.expressionId > b.expressionId ? 1 : -1).map((expression, idx) => {
                                                    if(caseAccessExpressions.length==1 || (caseAccessExpressions.length>1 && caseAccessExpressions[caseAccessExpressions.length-1].expressionId==expression.expressionId)){
                                                        return (
                                                            <tbody key={'tb'+expression.expressionId}>
                                                                <tr key={expression.expressionId} style={{border:"none"}}>
                                                                    <td>
                                                                        <div className="form-group" style={{marginLeft:5+'px'}}>                                                                      
                                                                            <select className="form-control select2" id={"attribute" + expression.expressionId} defaultValue={expression.attribute} onChange={(event) => this.props.changeAttribute(event, attributesSorted)} >
                                                                                <option value="">-- Select --</option>
                                                                                {workflowAttributes && attributesSorted &&
                                                                                    attributesSorted.map(option => {
                                                                                        if (option.attribute == expression.attribute) {
                                                                                            return (
                                                                                                <option key={expression.expressionId + option.id} value={option.attribute} selected>
                                                                                                    {option.attributeName}
                                                                                                </option>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <option key={expression.expressionId + option.id} value={option.attribute}>
                                                                                                    {option.attributeName}
                                                                                                </option>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                            <select className="form-control" id={"operator" + expression.expressionId} defaultValue={expression.operator} onChange={this.changeExpression}>
                                                                                {
                                                                                    expression.type.toUpperCase() === 'DATE' ?
                                                                                        dateConditionOptions.map(option => {
                                                                                            return (
                                                                                                <option key={option.key} value={option.key}>
                                                                                                    {option.title}
                                                                                                </option>
                                                                                            )
                                                                                        }) :
                                                                                        expression.type.toUpperCase() === 'INTEGER' || expression.type.toUpperCase() === 'DECIMAL' ?
                                                                                            integerDecimalConditionOptions.map(option => {
                                                                                                return (
                                                                                                    <option key={option.key} value={option.key}>
                                                                                                        {option.title}
                                                                                                    </option>
                                                                                                )
                                                                                            }) :
                                                                                            stringConditionOptions.map(option => {
                                                                                                return (
                                                                                                    <option key={option.key} value={option.key}>
                                                                                                        {option.title}
                                                                                                    </option>
                                                                                                )
                                                                                            })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                            {
                                                                                expression.type.toUpperCase() === 'DATE' ?
                                                                                    <DatePicker id={"expression" + expression.expressionId} selected={expression.value} dateFormat="yyyy-MM-dd" onChange={(date) => this.props.handleDateChange(expression, date)} /> : <input type="text" className="form-control" id={"expression" + expression.expressionId} defaultValue={expression.value} onChange={this.changeExpression}></input>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group" style={{marginLeft:5+'px'}}>
                                                                            <button type="button" className="btn" aria-label="Remove expression" onClick={e => {
                                                                                e.preventDefault();
                                                                                this.props.removeCaseAccessExpression(idx);
                                                                            }}>
                                                                                <span className="fa fa-times" />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    } else {
                                                        return (
                                                            <tbody key={'tb'+expression.expressionId}>
                                                                <tr key={expression.expressionId} style={{border:"none"}}>
                                                                    <td>
                                                                        <div className="form-group" style={{marginLeft:5+'px'}}>
                                                                            <select className="form-control select2" id={"attribute" + expression.expressionId} defaultValue={expression.attribute} onChange={(event) => this.props.changeAttribute(event, attributesSorted)} >
                                                                                <option value="">-- Select --</option>
                                                                                {workflowAttributes && attributesSorted &&
                                                                                    attributesSorted.map(option => {
                                                                                        if (option.attribute == expression.attribute) {
                                                                                            return (
                                                                                                <option key={expression.expressionId + option.id} value={option.attribute} selected="selected">
                                                                                                    {option.attributeName}
                                                                                                </option>
                                                                                            )
                                                                                        } else {
                                                                                            return (
                                                                                                <option key={expression.expressionId + option.id} value={option.attribute}>
                                                                                                    {option.attributeName}
                                                                                                </option>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                            <select className="form-control" id={"operator" + expression.expressionId} defaultValue={expression.operator}>
                                                                                {
                                                                                    expression.type.toUpperCase() === 'DATE' ?
                                                                                        dateConditionOptions.map(option => {
                                                                                            return (
                                                                                                <option key={option.key} value={option.key}>
                                                                                                    {option.title}
                                                                                                </option>
                                                                                            )
                                                                                        }) :
                                                                                        expression.type.toUpperCase() === 'INTEGER' || expression.type.toUpperCase() === 'DECIMAL' ?
                                                                                            integerDecimalConditionOptions.map(option => {
                                                                                                return (
                                                                                                    <option key={option.key} value={option.key}>
                                                                                                        {option.title}
                                                                                                    </option>
                                                                                                )
                                                                                            }) :
                                                                                            stringConditionOptions.map(option => {
                                                                                                return (
                                                                                                    <option key={option.key} value={option.key}>
                                                                                                        {option.title}
                                                                                                    </option>
                                                                                                )
                                                                                            })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group" style={{ marginLeft: 5 + 'px' }}>
                                                                            {
                                                                                expression.type.toUpperCase() === 'DATE' ?
                                                                                    <DatePicker id={"expression" + expression.expressionId} selected={expression.value} dateFormat="yyyy-MM-dd" onChange={(date) => this.props.handleDateChange(expression, date)} />
                                                                                    : <input type="text" className="form-control tags" id={"expression" + expression.expressionId} defaultValue={expression.value}></input>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group" style={{marginLeft:5+'px'}}>
                                                                            <button type="button" className="btn" aria-label="Remove expression" onClick={e => {
                                                                                e.preventDefault();
                                                                                this.props.removeCaseAccessExpression(idx);
                                                                            }}>
                                                                                <span className="fa fa-times" />
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td colSpan={2}>
                                                                        {
                                                                            expression.condition !== undefined && expression.condition !== null && expression.condition !== "" ?
                                                                                <button type="button" id={"condition" + expression.expressionId} className="btn btn-default condition-button" value={expression.condition} onClick={(event) => { this.changeCondition(event, caseAccessExpressions) }}>{expression.condition}</button> :
                                                                                oldCondition !== "" ? <button type="button" id={"condition" + expression.expressionId} className="btn btn-default condition-button" value={oldCondition} onClick={(event) => { this.changeCondition(event, caseAccessExpressions) }}>{oldCondition}</button> :
                                                                                    <button type="button" id={"condition" + expression.expressionId} className="btn btn-default condition-button" value="AND" onClick={(event) => { this.changeCondition(event, caseAccessExpressions) }}>AND</button>
                                                                        }
                                                                        <div style={{marginBottom:'10px'}}></div>
                                                                        {this.changeExpressionForCurrentCondition("condition"+expression.expressionId, expression.condition)}
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        )
                                                    }
                                                })
                                                }
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    data-dismiss="modal"
                                    onClick={this.props.openSpecifyWindow}
                                >{t("common.close")}
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-default"
                                    onClick={this.props.handleSaveExpression}
                                >{t("common.save")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default CaseExpression