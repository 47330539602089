class CaseAction {
    constructor(
        id= "",
        title = "",
        tooltip = "",
        icon = "",
        description = "",
        type = "",
        predefinedActions = "",
        isCommentSection = false,
        isMandatoryComment = false,
        isCaseHistoryEntry = false,
        isCaseHistoryLogPdf = false,
        caseHistoryEntryTitle = "",
        confirmActionButtonLabel = "",
        cancelActionButtonLabel = "",
        commentHintText = "",
        isTransitionState = false,
        caseTransitionState = "",
        selectedWorkflowId = null,
        successMessage = ""
    ) {
        this.id = id;
        this.name = predefinedActions;
        this.title = title;
        this.tooltip = tooltip;
        this.icon = icon;
        this.description = description;
        this.type = type;
        this.predefinedActions = predefinedActions;
        this.isCommentSection = isCommentSection==undefined || isCommentSection=="off" || isCommentSection==false?"false":"true";
        this.isMandatoryComment = isMandatoryComment==undefined || isMandatoryComment=="off" || isMandatoryComment==false?"false":"true";
        this.isCaseHistoryEntry = isCaseHistoryEntry==undefined || isCaseHistoryEntry=="off" || isCaseHistoryEntry==false?"false":"true";
        this.isCaseHistoryLogPdf = isCaseHistoryLogPdf==undefined || isCaseHistoryLogPdf=="off" || isCaseHistoryLogPdf==false?"false":"true";
        this.caseHistoryEntryTitle = caseHistoryEntryTitle;
        this.confirmActionButtonLabel = confirmActionButtonLabel;
        this.cancelActionButtonLabel = cancelActionButtonLabel;
        this.commentHintText = commentHintText;
        this.isTransitionState = isTransitionState==undefined || isTransitionState=="off" || isTransitionState==false?"false":"true";
        this.caseTransitionState = caseTransitionState;
        this.selectedWorkflowId = selectedWorkflowId;
        this.successMessage = successMessage;
    }
}

export default CaseAction;