import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import CommonTable from "../components/shared/CommonTable";
import CaseExpression from "../components/shared/CaseExpression";
import UserAPI from "../api/UserAPI";
import UserGroupAPI from "../api/UserGroupAPI";
import WorkflowAPI from "../api/WorkflowAPI";
import UserGroup from "../dto/UserGroup";
import _, { parseInt } from "lodash";
import UserGroupDetails from "../components/userGroup/UserGroupDetails";
import UserGroupAddModal from "../components/userGroup/UserGroupAddModal";
import UserGroupActionPanel from "../components/userGroup/UserGroupActionPanel";
import UserRoleAddModal from "../components/userRole/UserRoleAddModal";
import UserRole from "../dto/UserRole";
import UserRoleActionPanel from "../components/userRole/UserRoleActionPanel";
import {UserRoleDetails} from "../components/userRole/UserRoleDetails";
import StateActionAPI from "../api/StateActionAPI";
import UserRoleAPI from "../api/UserRoleAPI";
const $ = window.$;

/*
*  Future task development
*/
class SettingsUserGroupAndRolePage extends Component {
    ref = React.createRef();

    state = {
        userGroup : new UserGroup(),
        stateData : null,
        t : this.props.t,
        columns: [
            { title: this.props.t("portalSettings.userGroups.column.title"), field: "groupTitle" },
            { title: this.props.t("portalSettings.userGroups.column.description"), field: "groupDescription" }
        ],
        data: [],
        options: {
            pagination: "local",
            paginationSize: 10,
            langs: {
                all: {
                    pagination: {
                        first: this.props.t("common.table.pagination.first"),
                        first_title: this.props.t("common.table.pagination.first_title"),
                        last: this.props.t("common.table.pagination.last"),
                        last_title: this.props.t("common.table.pagination.last_title"),
                        prev: this.props.t("common.table.pagination.prev"),
                        prev_title: this.props.t("common.table.pagination.prev_title"),
                        next: this.props.t("common.table.pagination.next"),
                        next_title: this.props.t("common.table.pagination.next_title")
                    }
                }
            }
        },
        isEdit: false,
        workflows: null,
        selectWorkflow: "",
        caseAccessExpressions: [],
        workflowAttributes: [],
        userData: [],
        errors: {},
        roleColumns: [
            { title: this.props.t("portalSettings.userRoles.column.title"), field: "name" },
            { title: this.props.t("portalSettings.userRoles.column.description"), field: "roleDescription" }
        ],
        roleData: [],
        userRole : new UserRole(),
        isRoleEdit: false,
        caseActions: [],
        roleStateData : null,
        autoSuggestedAttributeValues: [
            {
                workflowName: ["Tipsl?sning (CCM)", "Whistle - Demo", "Demo_wf", "CCM connection"],
                stateName: ["Submitted", "Rejected", "Approved", "Under_work"],
                applicantName: ["Anonymous"]
            }
        ],
        oldCondition: "",
        selectedValue: "",
        selectedType: ""
    };

    updateUserGroupTable() {
        UserGroupAPI.all().then(data => {
          this.setState({ data: data.data });
        });
    }

    loadWorkflows = () => {
        WorkflowAPI.getWorkflows()
          .then(workflows => {
            this.setState({
              workflows: workflows
            });
          })
          .catch(error => {
            throw error;
          });
    };
      
    loadUser = () => {
        UserAPI.allSummarizedUsers().then(data => {
            let userList = data.data
            if (userList.length != 0) {
                _.forEach(userList, function (user) {
                    if (user.caseExpressions.length != 0) {
                        _.forEach(user.caseExpressions, function (obj) {
                            if (obj.type.toUpperCase() === 'DATE') {
                                obj.value = new Date(obj.value);
                            }
                        });
                    }
                });
            }
            this.setState({ userData: data.data });
        });
    }

    componentDidMount() {
        this.updateUserGroupTable();
        this.loadWorkflows();
        this.loadUser();
        this.updateUserRoleTable();
        this.loadCaseActions();
    }

    onRowClick = stateData => {
        if (stateData.workflow) {
            this.updateWorkflowAttributes(stateData.workflow.workflowId);
        } 
        let caseAccessExpressions = stateData.caseExpressions;
        if (caseAccessExpressions !== null && caseAccessExpressions.length != 0) {
            _.forEach(caseAccessExpressions, function (obj) {
                if (obj.type.toUpperCase() === 'DATE') {
                    obj.value = new Date(obj.value);
                }
            }
            );
        }
        let users = stateData.portalUsers.map(portalUser => {
            return portalUser.id;
        });
        
        $('#portal-user').val(users).trigger('change');
        
        this.setState({
            selectWorkflow: stateData.workflow.workflowId,
            userGroup: stateData,
            stateData: stateData,
            caseAccessExpressions,
            isEdit: true
        });
    };

    setRef(ref) {
        if (ref) {
            this.ref = ref;
            this.ref.table.setLocale("all");
        }
    }

    updateWorkflowAttributes(workflowId){
        WorkflowAPI.getWorkflowAttributes(workflowId).then(data => {
          this.setState({
            workflowAttributes: data
          })
        })
    };

    handleWorkflowChange = event => {
        let { name, value } = event.target;
        const { workflows } = this.state;
    
        if (name === "workflow") {
            let selectedWf = _.find(workflows, { name: value });
    
            this.setState({
                selectWorkflow: selectedWf.workflowId,
                [event.target.name]: value
            });
    
            this.updateWorkflowAttributes(selectedWf.workflowId);
    
        } else {
            this.setState({
                [event.target.name]: value
            });
        }
    };

    generateAttributeName = (attribute) => {
        if(attribute.includes("metadata") || attribute.includes("auditInfo")){
            var valueArray = attribute.split(".");
            if(valueArray[1]!=undefined && valueArray[1]!=null){
                return valueArray[1];
            } else {
                return attribute;
            }
        } else {
            return attribute;
        }
    }

    generateSortedAttributeList =(workflowAttributesTemp)=> {
        let workflowAttributesSorted = workflowAttributesTemp.map(option=> {
          let attribName = this.generateAttributeName(option.attribute);
          if (
            attribName.includes("sessionRef") ||
            attribName.includes("workflowRef") ||
            attribName.includes("worker") ||
            attribName.includes("Attachments") ||
            attribName.includes("Assign Worker") ||
            attribName.includes("sessionRef") ||
            attribName.includes("worker") ||
            attribName.includes("workflowRef") ||
            attribName.includes("workflowId") ||
            attribName.includes("workflowName") ||
            attribName.includes("workflowVersion") ||
            attribName.includes("baseURL") ||
            attribName.includes("continuationURL") ||
            attribName.includes("stateName") ||
            attribName.includes("stateType") ||
            attribName.includes("caseState")) {
            return {
              id: option.id,
              attribute: option.attribute,
              attributeName: `${attribName} (default)`,
              type: option.type
          }
          } else {
            return {
              id: option.id,
              attribute: option.attribute,
              attributeName: attribName,
              type: option.type
            }
          }
            })
        workflowAttributesSorted.sort((a,b) => a.attributeName.localeCompare(b.attributeName))
        return workflowAttributesSorted;
    }

    addCaseAccessExpression = (optionList) => {
        const { caseAccessExpressions, selectWorkflow } = this.state;
        let isCondition = "";
        if (caseAccessExpressions.length > 1) {
            isCondition = $("#condition" + caseAccessExpressions[0].expressionId).val();
        }
        _.forEach(caseAccessExpressions, function (obj) {
            if (isCondition !== "") {
                if (obj.condition !== "" && obj.condition !== isCondition) {
                    obj.condition = isCondition;
                }
            }
            let optionValue = $('#attribute' + obj.expressionId).val();
            let option = _.find(optionList, { attribute: optionValue });
            if (option !== undefined && option !== null && option !== "") {
                obj.type = option.type
            }
        }
        );
        let newExpression = {
          id: null,
          expressionId : 1,
          attribute: "",
          operator: "eq",
          value: "",
          workflowId: selectWorkflow,
          condition: "",
          type: ""
        };
    
        var latestExpression = caseAccessExpressions[caseAccessExpressions.length-1];
    
        if(latestExpression!=undefined){
          newExpression.expressionId = latestExpression.expressionId+1
        }
    
        caseAccessExpressions.push(newExpression);
        this.setState({
          caseAccessExpressions: caseAccessExpressions,
          oldCondition: isCondition,
          selectedValue: ""
        });
    };
    
    removeCaseAccessExpression = idx => {
        const { caseAccessExpressions } = this.state;
        caseAccessExpressions.splice(idx, 1);
        let isCondition="";
        if(caseAccessExpressions.length>1){
            isCondition=$("#condition"+caseAccessExpressions[0].expressionId).val();
        }
        if (isCondition !== "") {
            _.forEach(caseAccessExpressions, function (obj) {
                if (obj.condition !== "" && obj.condition !== isCondition) {
                    obj.condition = isCondition;
                }
            }
            );
        }
        this.setState({
          caseAccessExpressions: caseAccessExpressions,
          oldCondition: isCondition
        });
    };    
    
    handleSaveExpression = () => {
        const { caseAccessExpressions, selectWorkflow, workflowAttributes } = this.state;

        let newExData = [];
        caseAccessExpressions.forEach(item => {
            let value = "";
            if (item.type.toUpperCase() === "DATE") {
                value = item.value;
            } else {
                value = $('#expression' + item.expressionId).val();
            }
            let savedAttribute = $('#attribute' + item.expressionId).val();
            let workflowAttribute = _.find(workflowAttributes, { attribute: savedAttribute });
            if (workflowAttribute !== undefined && workflowAttribute !== null && workflowAttribute !== "") {
                item.type = workflowAttribute.type;
            }
            let record = {
                id: item.id,
                expressionId: item.expressionId,
                attribute: $('#attribute' + item.expressionId).val(),
                operator: $('#operator' + item.expressionId).val(),
                value: value,
                workflowId: selectWorkflow,
                condition: $('#condition' + item.expressionId).val(),
                type: item.type
            };
            newExData.push(record);
        })
    
        this.setState({
          caseAccessExpressions: newExData
        });
    
        $('#modal-customize-case-access').modal('hide');
        $('#modal-user-group').css('overflow-y', 'auto');
    }
    
    handleUserChange = event => {
        let { name, value } = event.target;
        this.setState({
            selectedUserId: value
        });
    }

    populateUserSelect = () => {
        $(function () {
          $("#portal-user").select2();
        })
    };

    isFormValid = () => {
        let {
            groupTitle
        } = this.state.userGroup;
        const { t, selectWorkflow } = this.state;
    
        const errors = {};
        if (!groupTitle)
            errors.groupTitle = t("portalSettings.userGroups.validation.GROUP_TITLE_REQUIRED");


        if (selectWorkflow === "")
            errors.workflow = t("portalSettings.userGroups.validation.WORKFLOW_REQUIRED");
    
        this.setState({
          errors: errors
        });
        return Object.keys(errors).length === 0;
    };
    
    handleSave = event => {
        let {
            id,
            groupTitle,
            groupDescription
        } = this.state.userGroup;
    
        event.preventDefault();
        if (!this.isFormValid()) return;
    
        let userGroupObj = new UserGroup(
            id,
            groupTitle,
            groupDescription
        );
    
        let { selectWorkflow, workflows, userData, caseAccessExpressions, isEdit, t } = this.state;
        
        let workflow = _.find(workflows, { workflowId: selectWorkflow });
        var selectedUsers = $(".portal-user").val();
        var portalUsers = [];
        $.each(selectedUsers, function (index, value) {
            portalUsers.push(_.find(userData, { id: parseInt(value)}));
        })
    
        userGroupObj.setworkflow(workflow);
        userGroupObj.setCaseExpressions(caseAccessExpressions);
        userGroupObj.setPortalUsers(portalUsers);
    
        if (isEdit) {
            UserGroupAPI.updateUserGroup(userGroupObj).then(() => {
                $.notification("success", t("common.notification.success"), t("portalSettings.userGroups.validation.UPDATE_SUCCESS"));
                this.updateUserGroupTable();
            }).catch(error => {
                this.errorHandling(error);
            });
        } else {
            UserGroupAPI.save(userGroupObj).then(() => {
                $.notification("success", t("common.notification.success"), t("portalSettings.userGroups.validation.SAVED_SUCCESS"));
                this.updateUserGroupTable();
            }).catch(error => {
                this.errorHandling(error);
            });
        }

        this.closeModal();
    };

    deleteUserGroup = id => {
        const { t } = this.state;

        UserGroupAPI.deleteUserGroup(id).then(() => {
            $.notification("success", t("common.notification.success"), t("portalSettings.userGroups.validation.DELETE_SUCCESS"));
            this.updateUserGroupTable();
        }).catch(error => {
            this.errorHandling(error);
        });
    };

    errorHandling = error => {
        const { t } = this.state;

        if (error.response) {
            $.notification("error", t("common.notification.error"), t("portalSettings.userGroups.validation."+error.response.data));
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log('Error', error.message);
        }
    }

    roleErrorHandling = error => {
        const { t } = this.state;
        if (error.response) {
            $.notification("error", t("common.notification.error"), t("portalSettings.userRoles.validation.DELETE_FAILED"));
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log('Error', error.message);
        }
    }

    closeModal = () => {
        $(function () {
            $('#modal-user-group').modal('hide');
        });
    }
    
    handleChange = event => {
        let { name, value } = event.target;
        if (event.target.options) {
          value = [...event.target.selectedOptions].map(option => option.value);
        }

        this.setState({
            userGroup: {
                ...this.state.userGroup,
                [event.target.name]: value
            }
        });
    };
    
    handleCancel = () => {
        this.setState({ redirect: true, userGroup: new UserGroup() });
    };

    reverseOnRowClick = () => {
        this.setState({
            userGroup : new UserGroup(),
            stateData : null,
            selectWorkflow: "",
            caseAccessExpressions: [],
            isEdit: false
        });
        $('#portal-user').val('');
    };

    /* Role Related Functions */
    handleRoleChange = event => {
        let { name, value } = event.target;
        if (event.target.options) {
            value = [...event.target.selectedOptions].map(option => option.value);
        }

        this.setState({
            userRole: {
                ...this.state.userRole,
                [event.target.name]: value
            }
        });
    };

    handleActionChange = event => {
        let { name, value } = event.target;
        this.setState({
            selectedRoleIds: value
        });
    }

    populateRoleSelects = () => {
        $(function () {
            $("#case-actions").select2();
            $("#admin-sections").select2();
        })
    };

    loadCaseActions(){
        StateActionAPI.all().then(data => {
            this.setState({caseActions: data.data})
        })
    }

    generateTitle = (type, key, title) => {
        const {t} = this.state;
        var translation = t(type+"."+key);
        if(translation.includes(type)){
            return title;
        } else {
            return translation;
        }
    }

    handleRoleSave = event => {
        let {
            id,
            name,
            roleDescription
        } = this.state.userRole;

        event.preventDefault();
        if (!this.isRoleFormValid()) return;

        let userRoleObj = new UserRole(
            id,
            name,
            roleDescription
        );

        let { isRoleEdit, t } = this.state;

        var selectedActions = $(".case-actions").val();
        var selectedAdminSections = $(".admin-sections").val();

        userRoleObj.setCaseActions(selectedActions);
        userRoleObj.setAdminSections(selectedAdminSections);

        if (isRoleEdit) {
            UserRoleAPI.updateUserRole(userRoleObj).then(() => {
                $.notification("success", t("common.notification.success"), t("portalSettings.userRoles.validation.UPDATE_SUCCESS"));
                this.updateUserRoleTable();
            }).catch(error => {
                this.errorHandling(error);
            });
        } else {
            UserRoleAPI.save(userRoleObj).then(() => {
                $.notification("success", t("common.notification.success"), t("portalSettings.userRoles.validation.SAVED_SUCCESS"));
                this.updateUserRoleTable();
            }).catch(error => {
                this.errorHandling(error);
            });
        }

        this.closeRoleModal();
    };

    isRoleFormValid = () => {
        let {
            name
        } = this.state.userRole;
        const { t } = this.state;
        const errors = {};
        if (!name)
            errors.name = t("portalSettings.userRoles.validation.ROLE_TITLE_REQUIRED");

        this.setState({
            errors: errors
        });
        return Object.keys(errors).length === 0;
    };

    closeRoleModal = () => {
        $(function () {
            $('#modal-user-role').modal('hide');
        });
    }

    updateUserRoleTable() {
        UserRoleAPI.all().then(data => {
            this.setState({ roleData: data.data });
        });
    }

    onRoleRowClick = roleData => {
        let userRoleObj = new UserRole(
            roleData.id,
            roleData.name,
            roleData.roleDescription
        );

        userRoleObj.setCaseActions(roleData.caseActions);
        userRoleObj.setAdminSections(roleData.adminSections);
        $('#name').val(roleData.name);
        $('#roleDescription').val(roleData.roleDescription);
        $('#case-actions').val(roleData.caseActions).trigger('change');
        $('#admin-sections').val(roleData.adminSections).trigger('change');

        this.setState({
            roleStateData: userRoleObj,
            userRole: userRoleObj,
            isRoleEdit: true
        });
    };

    reverseOnRoleRowClick = () => {
        this.setState({
            userRole : new UserRole(),
            roleStateData : null,
            isRoleEdit: false
        });
        $('#name').val('');
        $('#roleDescription').val('');
        $('#case-actions').val('');
        $('#admin-sections').val('');
    };

    deleteUserRole = id => {
        const { t } = this.state;

        UserRoleAPI.deleteUserRole(id).then(() => {
            $.notification("success", t("common.notification.success"), t("portalSettings.userRoles.validation.DELETE_SUCCESS"));
            this.updateUserRoleTable();
        }).catch(error => {
            this.roleErrorHandling(error);
        });
    };

    handleDateChange = (selectedExpression, val) => {
        const { caseAccessExpressions } = this.state;
        let expression = _.find(caseAccessExpressions, {
          expressionId: selectedExpression.expressionId
        });
        expression.value = val;
        this.setState({
          caseAccessExpressions: caseAccessExpressions
        });
      } 

      changeAttribute = (event, optionList) => {
        const { autoSuggestedAttributeValues, caseAccessExpressions } = this.state;
        _.forEach(caseAccessExpressions, function (obj) {
          let attribute = $('#attribute' + obj.expressionId).val();
          let selected = _.find(optionList, { attribute: attribute });
          if (selected !== undefined && selected !== null && selected !== "") {
            obj.type = selected.type;
            if (selected.type.toUpperCase() === 'DATE') {
              if (typeof obj.value === "string") {
                obj.value = new Date();
              }
            } else {
              obj.value = "";
            }
          }
        }
        );
        const selectedValue = event.target.value;
        let selected = _.find(optionList, { attribute: selectedValue });
        if (selected !== undefined && selected !== null && selected !== "") {
          this.setState({
            selectedType: selected.type
          });
        }
        var id = event.target.id;
        id = id.replace('attribute', '');
        
        $( function() {
    
          function autocompleteExpression(inp, arr) {
            /*the autocomplete function takes two arguments,
            the text field element and an array of possible autocompleted values:*/
            var currentFocus;
            /*execute a function when someone writes in the text field:*/
            inp.addEventListener("input", function(e) {
              var a, b, i, val = this.value;
              /*close any already open lists of autocompleted values*/
              closeAllLists();
              if (!val) { return false;}
              currentFocus = -1;
              /*create a DIV element that will contain the items (values):*/
              a = document.createElement("DIV");
              a.setAttribute("id", this.id + "autocomplete-list");
              a.setAttribute("class", "autocomplete-items");
              /*append the DIV element as a child of the autocomplete container:*/
              this.parentNode.appendChild(a);
              /*for each item in the array...*/
              for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                  /*create a DIV element for each matching element:*/
                  b = document.createElement("DIV");
                  /*make the matching letters bold:*/
                  b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                  b.innerHTML += arr[i].substr(val.length);
                  /*insert a input field that will hold the current array item's value:*/
                  b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                  /*execute a function when someone clicks on the item value (DIV element):*/
                  b.addEventListener("click", function(e) {
                    /*insert the value for the autocomplete text field:*/
                    inp.value = this.getElementsByTagName("input")[0].value;
                    /*close the list of autocompleted values,
                    (or any other open lists of autocompleted values:*/
                    closeAllLists();
                  });
                  a.appendChild(b);
                }
              }
            });
            /*execute a function presses a key on the keyboard:*/
            inp.addEventListener("keydown", function(e) {
              var x = document.getElementById(this.id + "autocomplete-list");
              if (x) x = x.getElementsByTagName("div");
              if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
              } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
              } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                  /*and simulate a click on the "autocompleteExpressionactive" item:*/
                  if (x) x[currentFocus].click();
                }
              }
            });
            function addActive(x) {
              /*a function to classify an item as "active":*/
              if (!x) return false;
              /*start by removing the "active" class on all items:*/
              removeActive(x);
              if (currentFocus >= x.length) currentFocus = 0;
              if (currentFocus < 0) currentFocus = (x.length - 1);
              /*add class "autocomplete-active":*/
              x[currentFocus].classList.add("autocomplete-active");
            }
            function removeActive(x) {
              /*a function to remove the "active" class from all autocomplete items:*/
              for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
              }
            }
            function closeAllLists(elmnt) {
              /*close all autocomplete lists in the document,
              except the one passed as an argument:*/
              var x = document.getElementsByClassName("autocomplete-items");
              for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                  x[i].parentNode.removeChild(x[i]);
                }
              }
            }
            /*execute a function when someone clicks in the document:*/
            document.addEventListener("click", function (e) {
              closeAllLists(e.target);
            });
          }
             if(autoSuggestedAttributeValues[0][selectedValue]!=undefined){
            autocompleteExpression(document.getElementById("expression"+id), autoSuggestedAttributeValues[0][selectedValue]);
           }
    
        });
      }
    

    render() {
        const { t } = this.props;
        const { stateData, columns, data, isEdit, workflows, workflowAttributes, selectWorkflow,  caseAccessExpressions, userData, errors,
        roleColumns, roleData, isRoleEdit, caseActions, userRole, roleStateData,oldCondition} = this.state;
        var attributesSorted = this.generateSortedAttributeList(workflowAttributes);
        const caseExpressionParams = { t, caseAccessExpressions, workflowAttributes, attributesSorted, oldCondition};
        const userGroupParams = {isEdit, stateData, errors, workflows, userData, selectWorkflow, t}
        const userRoleParams = {isRoleEdit, userRole, errors, workflows, caseActions, selectWorkflow, t, roleStateData}
        return (
            <Fragment> 
                <div className="content-wrapper">
                    <section className="content-header">
                        <h1>
                            {t("portalSettings.userGroups.title")} <small />
                        </h1>
                    </section>

                    <section className="content">
                        <CommonTable rowClick={this.onRowClick} columns={columns} data={data} t={t} />                      

                        <UserGroupAddModal params={userGroupParams}
                            handleChange={this.handleChange}
                            handleWorkflowChange={this.handleWorkflowChange}
                            handleUserChange={this.handleUserChange}
                            populateUserSelect={this.populateUserSelect}
                            handleSave={this.handleSave}
                        />

                        <CaseExpression params={caseExpressionParams}
                            addCaseAccessExpression={this.addCaseAccessExpression}
                            removeCaseAccessExpression={this.removeCaseAccessExpression}
                            handleSaveExpression={this.handleSaveExpression}
                            handleDateChange={this.handleDateChange}
                            changeAttribute={this.changeAttribute}
                            title={t("portalSettings.userGroups.caseAccess.title")}
                        />

                        <div className="row">
                            <div className="col-xs-8">
                                {stateData && (
                                    <UserGroupDetails userGroup={stateData}/>
                                )}
                            </div>

                            <div className="col-xs-4 right">
                                <UserGroupActionPanel params={userGroupParams}
                                    deleteUserRole={this.deleteUserGroup}
                                    deleteUserGroup={this.deleteUserGroup}  
                                    reverseOnRowClick={this.reverseOnRowClick}
                                />
                            </div>
                        </div>
                    </section>

                    <section className="content-header">
                        <h1>
                            {t("portalSettings.userRoles.title")} <small />
                        </h1>
                    </section>

                    <section className="content">
                        <CommonTable rowClick={this.onRoleRowClick} columns={roleColumns} data={roleData} t={t} />

                        <UserRoleAddModal params={userRoleParams}
                                           handleChange={this.handleRoleChange}
                                           handleActionChange={this.handleActionChange}
                                           addActionRecord={this.addActionRecord}
                                           handleAdminSectionChange={this.handleAdminSectionChange}
                                           addAdminSectionRecord={this.addAdminSectionRecord}
                                           handleSave={this.handleRoleSave}
                                           populateRoleSelects={this.populateRoleSelects}
                                           generateTitle={this.generateTitle}
                        />


                        <div className="row">
                            <div className="col-xs-8">
                                {roleStateData && (
                                    <UserRoleDetails params={userRoleParams}
                                                     roleStateData={roleStateData}/>
                                )}
                            </div>

                            <div className="col-xs-4 right">
                                <UserRoleActionPanel params={userRoleParams}
                                                      deleteUserRole={this.deleteUserRole}
                                                      reverseOnRowClick={this.reverseOnRoleRowClick}
                                />
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment>
        );
    }
}
export default withTranslation("common")(SettingsUserGroupAndRolePage);