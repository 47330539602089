import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import WorkflowAPI from "../api/WorkflowAPI";
import CommonTable from "../components/shared/CommonTable";
import CaseExpression from "../components/shared/CaseExpression";
import Workflow from "../dto/Workflow";
import StateAPI from "../api/StateAPI";
import ExerciseAPI from "../api/ExerciseAPI";
import WorkFlowMenuAPI from "../api/WorkFlowMenuAPI";
import CaseState from "../dto/CaseState";
import { CommonContext } from "../context/CommonContext";
import ColumnConfigModal from "../components/workflow/ColumnConfigModal";
import CaseStateConfigModal from "../components/workflow/CaseStateConfigModal";
import CaseInfoConfigModal from "../components/workflow/CaseInfoConfigModal";
import DeleteModal from "../components/shared/DeleteModal";
import WorkflowModal from "../components/workflow/WorkflowModal";
import CaseRulesConfigModal from "../components/workflow/CaseRulesConfigModal";
import CaseHighlightingConfigModal from "../components/workflow/CaseHighlightingConfigModal";
const $ = window.$;

class SettingsWorkflowPage extends Component {
    ref = React.createRef();
    static contextType = CommonContext;

    state = {
        workflow: new Workflow(),
        workflowData : null,
        t : this.props.t,
        columns: [],
        data: [],
        records: [],
        caseStates: [],
        selectState: null,
        isEdit: false,
        exercises: [],
        exerciseRecords: [],
        selectExercise: null,
        errors: {},
        initialRecords: [],
        initialExercises: [],

        columnRecords: [],
        isColumnEdit: false,
        selectedColumn:null,
        initialColumns: [],

        workflowAttributes: [],

        caseInfoRecords: [],
        initialCaseInfo: [],
        isCaseInfoEdit: false,
        selectedCaseInfo: null,

        caseAccessExpressions: [],
        autoSuggestedAttributeValues: [
            {
                workflowName:["Tipsl?sning (CCM)","Whistle - Demo","Demo_wf", "CCM connection"],
                stateName:["Submitted","Rejected","Approved","Under_work"],
                applicantName:["Anonymous"]
            }
        ],
        enableDeletionRules:"N",
        caseDeletionRules: [],
        initialRuleRecords: [],
        showRulesModel: false,
        oldCondition:"",
        selectedValue:"",
        selectedType:"",
        attributeId: null,
        selectedAttributeName: "",
        showHighlightingModel: false,
        caseHighlightConfigs: [],
        initialCaseHighlightConfigs: [],
        caseHighlightConfig: null
    };

    static contextType = CommonContext

    onRowClick = workflowData => {
        let states = workflowData.states;
        let newData = [];

        workflowData.name = workflowData.actualName;

        states.forEach(item =>{
            let record = {
                id: item.id,
                name: item.name,
                description: item.description,
                buttonStyle: item.buttonStyle,
                actions: item.actions,
                order: item.order
            };
            newData.push(record);
        })

        let exercise = workflowData.exercise;
        let newExData = [];
        let attributeId = ""
        exercise.forEach(item =>{
            let record = {
                id: item.id,
                code: item.code,
                description: item.description,
                attributeId: item.attributeId
            };
            newExData.push(record);
            attributeId = item.attributeId
        })

        workflowData.attributeId = attributeId;

        this.setState({
            workflowData: workflowData,
            workflow: workflowData,
            isEdit: true,
            records: newData,
            exerciseRecords: newExData,
            initialRecords: newData,
            initialExercises: newExData,
            enableDeletionRules: workflowData.enableDeletionRules,
            showRulesModel: true,
            showHighlightingModel: true
        });

        this.updateWorkflowColumns(workflowData.id);
        this.updateWorkflowAttributes(workflowData.workflowId, workflowData.attributeId);
        this.updateWorkflowCaseInfoConfig(workflowData.id);
        this.updateWorkflowCaseDeletionRules(workflowData.id);
        this.updateWorkflowCaseHighlightingRules(workflowData.id);
        this.context.setWorkflowId(workflowData.id);
    };

    reverseOnRowClick = () => {
            this.setState({
            workflowData: null,
            workflow:  new Workflow(),
            isEdit: false,
            records: [],
            exerciseRecords: [],
            initialRecords: [],
            initialExercises: []
        });
        this.componentDidMount();
    };

    componentDidMount() {
        this.updateWorkflowTable();
        this.updateCaseStates();
        this.updateExercises();
    }

    updateWorkflowTable() {
        let { columns, t} = this.state;

        function getColumns (column,t) {
            var translation = t("portalSettings.workflows.fields."+column.field);
            if(!translation.includes("portalSettings.workflows.fields")){
                column.title = translation;
            }
        }

        function updateRow(data, t){
            if(data.name=="portalSettings.workflows.new_workflow.name") {
                data.isNewWorkflow = true;
                data.actualName = t(data.name);
                data.name = "<span class='time-label'><span class='bg-green'>" + t(data.name) + "</span></span>";
                data.description = t(data.description);
            } else {
                data.isNewWorkflow = false;
                data.actualName = data.name;
            }
        }

        WorkflowAPI.all().then(data => {
            _.forEach(data.columns, function (column) {
                getColumns(column, t)
            });

            columns = [...data.columns];

            _.forEach(data.data, function (data) {
                updateRow(data, t)
            });

            this.setState({
                columns: columns,
                data: data.data,
                workflow: new Workflow(),
                workflowData : null,
                records: [],
                selectState: null,
                isEdit: false,
                exerciseRecords: [],
                selectExercise: null,
                errors: {},
                initialRecords: [],
                initialExercises: []
            });
        });
    }

    updateCaseStates(){
        StateAPI.all().then(data => {
            this.setState({caseStates: data.data})
        })
    }

    updateExercises(){
        ExerciseAPI.all().then(data => {
            this.setState({exercises: data.data})
        })
    }

    updateWorkflowAttributes(workflowId, attributeId){
        WorkflowAPI.getWorkflowAttributes(workflowId).then(data => {
            var selectedAttributeName = this.changeSelectedAttribute(data, attributeId);
            this.setState({
                workflowAttributes: data,
                selectedAttributeName: selectedAttributeName
            })
        })
    }

    updateWorkflowColumns(id){
        WorkflowAPI.getWorkflowColumns(id).then(data => {
            this.setState({
                initialColumns: data,
                columnRecords: data
            })
        })
    }

    updateWorkflowCaseInfoConfig(id){
        WorkflowAPI.getCaseInfoConfig(id).then(data => {
            this.setState({
                initialCaseInfo: data,
                caseInfoRecords: data
            })
        })
    }

    updateWorkflowCaseDeletionRules(id){
        WorkflowAPI.getCaseDeletionRules(id).then(data => {
            this.setState({
                initialRuleRecords: data,
                caseDeletionRules: data
            })
        })
    }

    updateWorkflowCaseHighlightingRules(id){
        WorkflowAPI.getCaseHighlightingConfig(id).then(data => {
            this.setState({
                initialCaseHighlightConfigs: data,
                caseHighlightConfigs: data
            })
        })
    }

    handleChange = event => {
        let { name, value } = event.target;
        if (event.target.options) {
            value = [...event.target.selectedOptions].map(option => option.value);
        }
        if (name === "status") {
            value = event.target.checked ? "ACTIVE" : "INACTIVE";
        } else if(name === "securePublishing"){
            value = event.target.checked ? "Y" : "N";
        } else if(name === "enableDeletionRules"){
            value = event.target.checked ? "Y" : "N";
        } else if(name === "segmentationWorkflow"){
            value = event.target.checked ? "Y" : "N";
        }
        this.setState({
            workflow: {
                ...this.state.workflow,
                [event.target.name]: value
            },
            [event.target.name]: value
        });
    };

    handleWorkflowChange = event => {
        let { name, value } = event.target;
        const { caseStates } = this.state;
        let selectedState = _.find(caseStates, { id: parseInt(value) });
        this.setState({
            selectState: selectedState.id
        });
    }

    handleExcerciseChange = event => {
        let { name, value } = event.target;
        const { exercises } = this.state;
        let selectExercise = _.find(exercises, { code: value });
        this.setState({
            selectExercise: selectExercise.id
        });
    }

    addRecord = () => {
        const {
            caseStates,
            selectState,
            records
        } = this.state;

        var selectedState;
        var newData;

        if (selectState != null) {
            selectedState = _.find(caseStates, { id: selectState });

            let record = {
                id: selectedState.id,
                name: selectedState.name,
                description: selectedState.description,
                buttonStyle: selectedState.buttonStyle,
                actions: selectedState.actions,
                order:records.length+1
            };

            if (!_.find(records, { id: selectedState.id })) {
                newData = [...records, record];
                this.setState({
                    records: newData
                });
            }
        }
    }

    deleteRecord = idx => {
        var records = this.state.records;
        var newData = [];

        records.forEach(item =>{
            let record = {
                id: item.id,
                name: item.name,
                description: item.description,
                buttonStyle: item.buttonStyle,
                actions: item.actions,
                order:item.order
            };
            newData.push(record);
        })

        newData.splice(idx, 1);
        newData = this.resetColumnOrder(newData);
        this.setState({
            records: newData
        });
    };

    handleSave = event => {
        const { t } = this.state;

        let {
            workflowId,
            name,
            description,
            icon,
            securePublishing,
            caseDeletionRules,
            actionTitle,
            segmentationWorkflow,
            enableDeletionRules,
            caseHighlightConfigs
        } = this.state.workflow;

        event.preventDefault();

        let workflowObj = new Workflow(
            "",
            workflowId,
            name,
            description,
            icon,
            securePublishing,
            enableDeletionRules,
            actionTitle,
            [],
            [],
            [],
            [],
            caseDeletionRules,
            segmentationWorkflow,
            caseHighlightConfigs
        );

        if (!this.isFormValid(workflowObj)) return;

        if(this.state.isEdit) {
            if(workflowObj.id==null || workflowObj.id=="") {
                workflowObj.id = this.state.workflowData.id;
            }

            if(workflowObj.states==null || workflowObj.states.length==0){
                workflowObj.states = this.resetColumnOrder(this.state.records);
            }

            if(workflowObj.exercises==null || workflowObj.exercises.length==0){
                workflowObj.exercise = this.state.exerciseRecords;
            }

            WorkflowAPI.update(workflowObj).then(() => {
                $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
                this.updateWorkflowTable();
            }).catch(error => {
                this.errorHandling(error);
            });
        } else {
            workflowObj.states = this.resetColumnOrder(this.state.records);
            workflowObj.exercise = this.state.exerciseRecords;

            WorkflowAPI.save(workflowObj).then(() => {
                $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
                this.updateWorkflowTable();
            }).catch(error => {
                this.errorHandling(error);
            });
        }

        this.closeModal();
        WorkFlowMenuAPI.reinitialize().then(menuData => {
            this.props.menuHandler(menuData);

        }).catch(error => {});

    }

    errorHandling = error => {
        const { t } = this.state;

        if (error.response) {
            $.notification("error", t("common.notification.error"), t("portalSettings.workflows.validationError."+error.response.data));
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log('Error', error.message);
        }
    }

    closeModal = () => {
        $(function () {
            $('#modal-workflow').modal('hide');
        });
    }

    openModal = () => {
        if(this.state.errors!=null && this.state.errors.length!=0){
            this.setState({
                errors: []
            });
        }

        if(this.state.isEdit){
            this.setState({
                records: this.state.initialRecords,
                exerciseRecords: this.state.initialExercises
            });
        } else {
            this.setState({
                records: [],
                exerciseRecords: []
            });
        }

        $(function () {
            $('#modal-workflow').find('form').trigger('reset');
            $('#modal-workflow').modal('show');
        });
    }

    deleteWorkflow = id => {
        const { t } = this.state;

        WorkflowAPI.delete(id).then(() => {
            $.notification("success", t("common.notification.success"), t("common.validations.RECORD_DELETED_SUCCESS"));
            this.updateWorkflowTable();
        }).catch(error => {            
             $.notification("error", t("common.notification.error"), t("portalSettings.workflows.validationError.WORKFLOW_DELETE_ERROR"));
        });
    };

    isFormValid = workflowObject => {
        const { t, records } = this.state;

        const errors = {};
        if (!workflowObject.workflowId)
            errors.workflowId = t(
                "portalSettings.workflows.validationError.WORKFLOW_ID_REQUIRED"
            );

        if (!workflowObject.name)
            errors.name = t(
                "portalSettings.workflows.validationError.NAME_REQUIRED"
            );

        if (!workflowObject.description)
            errors.description = t(
                "portalSettings.workflows.validationError.DESCRIPTION_REQUIRED"
            );

        if (!workflowObject.icon)
            errors.icon = t(
                "portalSettings.workflows.validationError.ICON_REQUIRED"
            );

        this.setState({
            errors: errors
        });
        return Object.keys(errors).length === 0;
    }

    generateTitle = (type, key, title) => {
        const {t} = this.state;
        var translation = t(type+"."+key);
        if(translation.includes(type)){
            return title;
        } else {
            return translation;
        }
    }

    addColumnRecord = () => {
        const {
            columnRecords,
            isColumnEdit,
            selectedColumn,
            t
        } = this.state;

        let record = {
            id: null,
            title : $('#title').val(),
            field : $('#field').val(),
            align : $('#align').val(),
            width : $('#width').val(),
            order : columnRecords.length+1,
            workflowId : this.state.workflowData.id
        }
        var newData = [];

        if(isColumnEdit){
            columnRecords.forEach(item =>{
                let add = null;
                if(item.id!=selectedColumn){
                    add = {
                        id: item.id,
                        title: item.title,
                        field: item.field,
                        align: item.align,
                        width: item.width,
                        order: item.order,
                        workflowId : item.workflowId
                    };
                } else {
                    record.id = item.id;
                    record.order = item.order;
                    add =  record;
                }

                newData.push(add);
            })
            this.setState({
                columnRecords: newData,
                isColumnEdit: false
            });
        } else {
            if($('#title').val()){
                if (!_.find(columnRecords, { title: $('#title').val(), field: $('#field').val() })) {
                    newData = [...columnRecords, record];
                    this.setState({
                        columnRecords: newData,
                        isColumnEdit: false
                    });
                }
            }
        }

        $("#columnSave").html(t("common.add"));
        $('#modal-columns').find('form').trigger('reset');
    }

    editColumnRecord = (idx, record) => {
        const {t} = this.state;

        this.setState({
            isColumnEdit: true,
            selectedColumn: record.id
        });

        $('#title').val(record.title);
        $('#field').val(record.field);
        $('#align').val(record.align);
        $('#width').val(record.width);
        $("#columnSave").html(t("common.save"));
    }

    deleteColumnRecord = idx => {
        var columnRecords  = this.state.columnRecords;
        var newData = [];

        columnRecords.forEach(item =>{
            let record = {
                id: item.id,
                title: item.title,
                field: item.field,
                align: item.align,
                width: item.width,
                order: item.order,
                workflowId : item.workflowId
            };
            newData.push(record);
        })
        newData.splice(idx, 1);
        newData = this.resetColumnOrder(newData);
        this.setState({
            columnRecords: newData
        });
    }

    resetColumnOrder = list => {
        let order = 1;
        list.forEach(item =>{
            item.order = order;
            order++;
        })

        return list;
    }

    resetColumnForm = () => {
        const {isColumnEdit, t} = this.state;
        if(isColumnEdit){
            $("#columnSave").html(t("common.add"));
            this.setState({
                isColumnEdit: false,
                selectedColumn: null
            });
        }
        $('#modal-columns').find('form').trigger('reset');
    }

    handleColumnSave = event => {
        const { t } = this.state;
        let {
            columnRecords
        } = this.state

        event.preventDefault();

        let workflowObj = new Workflow();
        workflowObj.id = this.state.workflowData.id;
        workflowObj.columns = this.resetColumnOrder(columnRecords);

        WorkflowAPI.updateColumns(workflowObj).then(() => {
            $("#columnSave").html(t("common.add"));
            $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
            this.updateWorkflowTable();
        }).catch(error => {
            this.errorHandling(error);
        });

    }

    handleSelectAll = event => {
        if($('input[name=select-all]:checked')) {
            $("input[name=column]").prop('checked', true);
            $("input[name=deselect-all]").prop('checked', false);
        }
    }

    handleDeselectAll = event => {
        if($('input[name=deselect-all]:checked')) {
            $("input[name=column]").prop('checked', false);
            $("input[name=column]").prop('indeterminate', false);
            $("input[name=select-all]").prop('checked', false);
        }
    }

    checkboxListner = event => {
        if($('input[name=column]:checked')){
            $("input[name=deselect-all]").prop('checked', false);
        }
    }

    addExtraColumn = (id) => {
        $("input[name=column][value="+id+"]").prop('indeterminate', true);
    }

    handleStateColumn = event => {
        const { t } = this.state;

        var workflowId = this.context.workflowId;

        if($('input[name=column]:checked').length === 0){$.notification("error", t("common.notification.error"), t("common.validations.STATE_OPTION_DESELECTION_ERROR"));return}

        if(!$('#caseStateColumn').val()){return}
        if($('#caseStateColumn').val()=="none"){return}

        event.preventDefault();
        var selectedColumns = [];
        $('input[name=column]:checked').map(function() {
            let record = {
                workflowId : workflowId,
                caseStateId : $('#caseStateColumn').val(),
                columnId: $(this).val(),
                type:"default"
            }
            selectedColumns.push(record);
        });

        $('input[name=column]:indeterminate').map(function() {
            let record = {
                workflowId : workflowId,
                caseStateId : $('#caseStateColumn').val(),
                columnId: $(this).val(),
                type:"extra"
            }
            selectedColumns.push(record);
        });

        let caseStateObj = new CaseState();
        caseStateObj.id = $('#caseStateColumn').val();
        caseStateObj.columns = selectedColumns;

        StateAPI.updateColumns(caseStateObj).then(data => {
            $('#modal-state').find('form').trigger('reset');
            $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
        })
        $("input[name=column]").prop('indeterminate', false);
    }

    handleCaseStateColumnChange = event => {
        let workflowId = this.context.workflowId
        let { name, value } = event.target;
        if (event.target.options) {
            value = [...event.target.selectedOptions].map(option => option.value);
        }

        $("input[name=column]").prop('checked', false);
        $("input[name=column]").prop('indeterminate', false);
        StateAPI.getCaseStateColumns(workflowId, value).then(data => {
            if(data){
                _.forEach(data, function(item) {
                    item.type === "default" ?
                        $("input[name=column][value="+item.columnId+"]").prop('checked', true) :
                        $("input[name=column][value="+item.columnId+"]").prop('indeterminate', true)
                });
            }
        })
    }

    openWorkflowColumnModal = () => {
        this.setState({
            columnRecords: this.state.initialColumns
        });

        $(function () {
            $('#modal-columns').find('form').trigger('reset');
            $('#modal-columns').modal('show');
        });
    }

    openCaseStateColumnModal = () => {
        $(function () {
            $('#modal-state').find('form').trigger('reset');
            $("input[name=column]").prop('indeterminate', false);
            $('#modal-state').modal('show');
        });
    }

    openConfigCaseInfoModal = () => {
        this.setState({
            caseInfoRecords: this.state.initialCaseInfo,
            caseAccessExpressions : []
        });

        $(function () {
            $('#modal-case-info').find('form').trigger('reset');
            $('#modal-case-info').modal('show');
            $('#modal-case-info').css('overflow-y', 'auto');
        });        
    }

    toggleCaseDeleteRuleModel = (showRulesModel) => {
        let clonedCaseDeletionRules = JSON.parse(JSON.stringify(this.state.initialRuleRecords));
        this.setState({
            caseDeletionRules: clonedCaseDeletionRules,
            showRulesModel: showRulesModel
        });

        if(this.state.showRulesModel){
            $('#modal-case-rules').find('form').trigger('reset');
            $('#modal-case-rules').modal('show');
            $('#modal-case-rules').css('overflow-y', 'auto');
        } 
    }

    toggleCaseHighlightingModel = (showHighlightingModel) => {
        let clonedCaseHighlightConfigs = JSON.parse(JSON.stringify(this.state.initialCaseHighlightConfigs));
        _.forEach(clonedCaseHighlightConfigs, function(highlightConfig){
            highlightConfig.tempId = highlightConfig.id;
        });
        this.setState({
            caseHighlightConfigs: clonedCaseHighlightConfigs,
            showHighlightingModel: showHighlightingModel
        });

        if(this.state.showHighlightingModel){
            $('#modal-case-highlighting').find('form').trigger('reset');
            $('#modal-case-highlighting').modal('show');
            $('#modal-case-highlighting').css('overflow-y', 'auto');
        } 
    }

    addCaseInfoRecord = () => {
        const {
            caseInfoRecords,
            isCaseInfoEdit,
            selectedCaseInfo,
            t,
            caseAccessExpressions
        } = this.state;

        let record = {
            id: null,
            title : $('#caseInfoTitle').val(),
            field : $('#caseInfoField').val(),
            order : caseInfoRecords.length+1,
            workflowId : this.state.workflowData.id,
            caseExpressions : caseAccessExpressions
        }
        var newData = [];

        if(isCaseInfoEdit){
            caseInfoRecords.forEach(item =>{
                let add = null;
                if(item.id!=selectedCaseInfo){
                    add = {
                        id: item.id,
                        title: item.title,
                        field: item.field,
                        order: item.order,
                        workflowId : item.workflowId,
                        caseExpressions : item.caseExpressions
                    };
                } else {
                    record.id = item.id;
                    record.order = item.order;
                    add =  record;
                }

                newData.push(add);
            })
            this.setState({
                caseInfoRecords: newData,
                isCaseInfoEdit: false,
                caseAccessExpressions : []
            });
        } else {
            if($('#caseInfoTitle').val()){
                if (!_.find(caseInfoRecords, { title: $('#caseInfoTitle').val(), field: $('#caseInfoField').val() })) {
                    newData = [...caseInfoRecords, record];
                    this.setState({
                        caseInfoRecords: newData,
                        caseAccessExpressions : []
                    });
                }
            }
        }

        $("#caseInfoSave").html(t("common.add"));
        $('#modal-case-info').find('form').trigger('reset');
    }

    resetCaseInfoForm = () => {
        const {isCaseInfoEdit, t} = this.state;
        if(isCaseInfoEdit){
            $("#caseInfoSave").html(t("common.add"));
            this.setState({
                isCaseInfoEdit: false,
                selectedCaseInfo: null,
                caseAccessExpressions : []
            });
        }
        $('#modal-case-info').find('form').trigger('reset');
    }

    moveUpCaseInfoRecord = (type, record) => {
        const {
            caseInfoRecords,
            columnRecords,
            records
        } = this.state;

        var  currentIndex = record.order;
        var  previousIndex = record.order-1;
        if(currentIndex==1){ return false;}

        if(type=="columns"){
            columnRecords.forEach(item =>{

                if(item.order==currentIndex){
                    item.order = currentIndex-1
                } else if(item.order==previousIndex) {
                    item.order = previousIndex+1
                }
            })

            this.setState({
                columnRecords: columnRecords
            });
        } else if(type=="case_states"){
            records.forEach(item =>{

                if(item.order==currentIndex){
                    item.order = currentIndex-1
                } else if(item.order==previousIndex) {
                    item.order = previousIndex+1
                }
            })

            this.setState({
                records: records
            });
        } else {
            caseInfoRecords.forEach(item =>{

                if(item.order==currentIndex){
                    item.order = currentIndex-1
                } else if(item.order==previousIndex) {
                    item.order = previousIndex+1
                }
            })

            this.setState({
                caseInfoRecords: caseInfoRecords
            });
        }
    }

    moveDownCaseInfoRecord = (type, record) => {
        const {
            caseInfoRecords,
            columnRecords,
            records
        } = this.state;

        var  currentIndex = record.order;
        var  nextIndex = record.order+1;

        if(type=="columns"){
            if(currentIndex==columnRecords.length){ return false;}

            columnRecords.forEach(item =>{
                if(item.order==currentIndex){
                    item.order = currentIndex+1
                } else if(item.order==nextIndex) {
                    item.order = nextIndex-1
                }
            })

            this.setState({
                columnRecords: columnRecords
            });
        } else if(type=="case_states"){
            if(currentIndex==records.length){ return false;}

            records.forEach(item =>{
                if(item.order==currentIndex){
                    item.order = currentIndex+1
                } else if(item.order==nextIndex) {
                    item.order = nextIndex-1
                }
            })

            this.setState({
                records: records
            });
        } else {
            if(currentIndex==caseInfoRecords.length){ return false;}

            caseInfoRecords.forEach(item =>{

                if(item.order==currentIndex){
                    item.order = currentIndex+1
                } else if(item.order==nextIndex) {
                    item.order = nextIndex-1
                }
            })

            this.setState({
                caseInfoRecords: caseInfoRecords
            });
        }
    }

    deleteCaseInfoRecord = idx => {
        var caseInfoRecords  = this.state.caseInfoRecords;
        var newData = [];
        var columnOrder = 1;

        caseInfoRecords.splice(idx, 1);

        caseInfoRecords.forEach(item =>{
            let record = {
                id: item.id,
                title: item.title,
                field: item.field,
                order: columnOrder,
                workflowId : item.workflowId,
                caseExpressions : item.caseExpressions
            };
            newData.push(record);
            columnOrder++;
        })

        this.setState({
            caseInfoRecords: newData
        });
    }

    handleCaseInfoSave = event => {
        const { t } = this.state;
        let {
            caseInfoRecords
        } = this.state

        event.preventDefault();

        let workflowObj = new Workflow();
        workflowObj.id = this.state.workflowData.id;
        workflowObj.caseInfoConfig = caseInfoRecords;

        WorkflowAPI.updateCaseInfoConfig(workflowObj).then(() => {
            $("#columnSave").html(t("common.add"));
            $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
            this.updateWorkflowTable();
        }).catch(error => {
            this.errorHandling(error);
        });

    }

    editCaseInfoRecord = (idx, record) => {
        const {t} = this.state;
        if(record.caseExpressions==null){
            record.caseExpressions = []
        }
        if (record.caseExpressions.length != 0) {
            _.forEach(record.caseExpressions, function (obj) {
                if (obj.type.toUpperCase() === 'DATE') {
                    obj.value = new Date(obj.value);
                }
            });
        }
        this.setState({
            isCaseInfoEdit: true,
            selectedCaseInfo: record.id,
            caseAccessExpressions: record.caseExpressions
        });


        $('#caseInfoTitle').val(record.title);
        $('#caseInfoField').val(record.field);
        $("#caseInfoSave").html(t("common.save"));
    }

    openCustomizeCaseAccessWindow = () => {
        $(function () {
            $('#modal-case-info').modal('hide');
            $('#modal-customize-case-access').modal('show');
            $('#modal-customize-case-access').css('overflow-y', 'auto');
        });
    }

    closeCustomizeCaseAccessWindow = () => {
        $(function () {
            $('#modal-customize-case-access').modal('hide');
            $('#modal-case-info').modal('show');
            $('#modal-case-info').css('overflow-y', 'auto');
        });
    }

addCaseAccessExpression = (optionList) => {
    const { caseAccessExpressions } = this.state;
    let isCondition = "";
    if (caseAccessExpressions.length > 1) {
        isCondition = $("#condition" + caseAccessExpressions[0].expressionId).val();
    }
    _.forEach(caseAccessExpressions, function (obj) {
        if (isCondition !== "") {
            if (obj.condition !== "" && obj.condition !== isCondition) {
                obj.condition = isCondition;
            }
        }
        let optionValue = $('#attribute' + obj.expressionId).val();
        let option = _.find(optionList, { attribute: optionValue });
        if (option !== undefined && option !== null && option !== "") {
            obj.type = option.type
        }
    }
    );
        let newExpression = {
            id: null,
            expressionId : 1,
            attribute: "",
            operator: "eq",
            value: "",
            workflowId: this.state.workflowData.id,
            condition: "",
            type: ""
        };

        var latestExpression = caseAccessExpressions[caseAccessExpressions.length-1];

        if(latestExpression!=undefined){
            newExpression.expressionId = latestExpression.expressionId+1
        }

        caseAccessExpressions.push(newExpression);
        this.setState({
            caseAccessExpressions: caseAccessExpressions,
            oldCondition: isCondition,
            selectedValue: ""
        });
    };

    handleDateChange = (selectedExpression, val) => {
        const { caseAccessExpressions } = this.state;
        let expression = _.find(caseAccessExpressions, {
            expressionId: selectedExpression.expressionId
        });
        expression.value = val;
        this.setState({
            caseAccessExpressions: caseAccessExpressions
        });
    }

    changeAttribute = (event, optionList) => {
        const { autoSuggestedAttributeValues, caseAccessExpressions } = this.state;
        _.forEach(caseAccessExpressions, function (obj) {
            let attribute = $('#attribute' + obj.expressionId).val();
            let selected = _.find(optionList, { attribute: attribute });
            if (selected !== undefined && selected !== null && selected !== "") {
                obj.type = selected.type;
                if (selected.type.toUpperCase() === 'DATE') {
                    if (typeof obj.value === "string") {
                        obj.value = new Date();
                    }
                } else {
                    obj.value = "";
                }
            }
        }
        );
        const selectedValue = event.target.value;
        let selected = _.find(optionList, { attribute: selectedValue });
        if (selected !== undefined && selected !== null && selected !== "") {
            this.setState({
                selectedType: selected.type
            });
        }
        var id = event.target.id;
        id = id.replace('attribute', '');

        $( function() {

            function autocompleteExpression(inp, arr) {
                /*the autocomplete function takes two arguments,
                the text field element and an array of possible autocompleted values:*/
                var currentFocus;
                /*execute a function when someone writes in the text field:*/
                inp.addEventListener("input", function(e) {
                    var a, b, i, val = this.value;
                    /*close any already open lists of autocompleted values*/
                    closeAllLists();
                    if (!val) { return false;}
                    currentFocus = -1;
                    /*create a DIV element that will contain the items (values):*/
                    a = document.createElement("DIV");
                    a.setAttribute("id", this.id + "autocomplete-list");
                    a.setAttribute("class", "autocomplete-items");
                    /*append the DIV element as a child of the autocomplete container:*/
                    this.parentNode.appendChild(a);
                    /*for each item in the array...*/
                    for (i = 0; i < arr.length; i++) {
                        /*check if the item starts with the same letters as the text field value:*/
                        if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                            /*create a DIV element for each matching element:*/
                            b = document.createElement("DIV");
                            /*make the matching letters bold:*/
                            b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                            b.innerHTML += arr[i].substr(val.length);
                            /*insert a input field that will hold the current array item's value:*/
                            b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                            /*execute a function when someone clicks on the item value (DIV element):*/
                            b.addEventListener("click", function(e) {
                                /*insert the value for the autocomplete text field:*/
                                inp.value = this.getElementsByTagName("input")[0].value;
                                /*close the list of autocompleted values,
                                (or any other open lists of autocompleted values:*/
                                closeAllLists();
                            });
                            a.appendChild(b);
                        }
                    }
                });
                /*execute a function presses a key on the keyboard:*/
                inp.addEventListener("keydown", function(e) {
                    var x = document.getElementById(this.id + "autocomplete-list");
                    if (x) x = x.getElementsByTagName("div");
                    if (e.keyCode == 40) {
                        /*If the arrow DOWN key is pressed,
                        increase the currentFocus variable:*/
                        currentFocus++;
                        /*and and make the current item more visible:*/
                        addActive(x);
                    } else if (e.keyCode == 38) { //up
                        /*If the arrow UP key is pressed,
                        decrease the currentFocus variable:*/
                        currentFocus--;
                        /*and and make the current item more visible:*/
                        addActive(x);
                    } else if (e.keyCode == 13) {
                        /*If the ENTER key is pressed, prevent the form from being submitted,*/
                        e.preventDefault();
                        if (currentFocus > -1) {
                            /*and simulate a click on the "autocompleteExpressionactive" item:*/
                            if (x) x[currentFocus].click();
                        }
                    }
                });
                function addActive(x) {
                    /*a function to classify an item as "active":*/
                    if (!x) return false;
                    /*start by removing the "active" class on all items:*/
                    removeActive(x);
                    if (currentFocus >= x.length) currentFocus = 0;
                    if (currentFocus < 0) currentFocus = (x.length - 1);
                    /*add class "autocomplete-active":*/
                    x[currentFocus].classList.add("autocomplete-active");
                }
                function removeActive(x) {
                    /*a function to remove the "active" class from all autocomplete items:*/
                    for (var i = 0; i < x.length; i++) {
                        x[i].classList.remove("autocomplete-active");
                    }
                }
                function closeAllLists(elmnt) {
                    /*close all autocomplete lists in the document,
                    except the one passed as an argument:*/
                    var x = document.getElementsByClassName("autocomplete-items");
                    for (var i = 0; i < x.length; i++) {
                        if (elmnt != x[i] && elmnt != inp) {
                            x[i].parentNode.removeChild(x[i]);
                        }
                    }
                }
                /*execute a function when someone clicks in the document:*/
                document.addEventListener("click", function (e) {
                    closeAllLists(e.target);
                });
            }

            if(autoSuggestedAttributeValues[0][selectedValue]!=undefined){
                autocompleteExpression(document.getElementById("expression"+id), autoSuggestedAttributeValues[0][selectedValue]);
            }

        });
    }

    handleSaveExpression = () => {
        const { caseAccessExpressions, selectWorkflow ,workflowAttributes} = this.state;
        let newExData = [];
        caseAccessExpressions.forEach(item =>{
            let value="";
            if(item.type.toUpperCase()==="DATE"){
                value=item.value;
            }else{
                value=$('#expression'+item.expressionId).val();
            }
            let savedAttribute = $('#attribute' + item.expressionId).val();
            let workflowAttribute = _.find(workflowAttributes, { attribute: savedAttribute });
            if (workflowAttribute !== undefined && workflowAttribute !== null && workflowAttribute !== "") {
                item.type = workflowAttribute.type;
            }
            let record = {
                id: item.id,
                expressionId: item.expressionId,
                attribute: $('#attribute'+item.expressionId).val(),
                operator: $('#operator'+item.expressionId).val(),
                value: value,
                workflowId: this.state.workflowData.id,
                condition:$('#condition'+item.expressionId).val(),
                type:item.type
            };
            newExData.push(record);
        })

        this.setState({
            caseAccessExpressions: newExData
        });

        $('#modal-customize-case-access').modal('hide');
        $('#modal-case-info').modal('show');
        $('#modal-case-info').css('overflow-y', 'auto');
    }

    changeExpression(id, expressionId) {

    };

    removeCaseAccessExpression = idx => {
        const { caseAccessExpressions } = this.state;
        caseAccessExpressions.splice(idx, 1);
        let isCondition="";
        if(caseAccessExpressions.length>1){
            isCondition=$("#condition"+caseAccessExpressions[0].expressionId).val();
        }
        if (isCondition !== "") {
            _.forEach(caseAccessExpressions, function (obj) {
                if (obj.condition !== "" && obj.condition !== isCondition) {
                    obj.condition = isCondition;
                }
            }
            );
        }
        this.setState({
            caseAccessExpressions: caseAccessExpressions,
            oldCondition: isCondition
        });
    };

    addSegmentationRecord = () => {
        const {
            attributeId,
            exerciseRecords,
            workflow,
            exercises
        } = this.state;

        var newData;

        if (attributeId != null) {
            let existingSegmentationId = _.find(exercises, { code: workflow.subflow});

            let record = {
                id: (existingSegmentationId!=undefined && existingSegmentationId!=null)?existingSegmentationId.id:null,
                code: workflow.subflow,
                description: workflow.subflow,
                attributeId: attributeId
            };

            if (!_.find(exerciseRecords, { code: record.code })) {
                newData = [...exerciseRecords, record];
                this.setState({
                    exerciseRecords: newData
                });
            }
        }
    }
    deleteExerciseRecord = idx => {
        var exerciseRecords  = this.state.exerciseRecords;
        var newData = [];

        exerciseRecords.forEach(item =>{
            let record = {
                id: item.id,
                code: item.code,
                description: item.description
            };
            newData.push(record);
        })
        newData.splice(idx, 1);
        this.setState({
            exerciseRecords: newData
        });
    };
    generateAttributeName = (attribute) => {
        if(attribute.includes("metadata") || attribute.includes("auditInfo")){
            var valueArray = attribute.split(".");
            if(valueArray[1]!=undefined && valueArray[1]!=null){
                return valueArray[1];
            } else {
                return attribute;
            }
        } else {
            return attribute;
        }
    }

    generateSortedAttributeList =(workflowAttributesTemp)=> {
        let workflowAttributesSorted = workflowAttributesTemp.map(option=> {
            let attribName = this.generateAttributeName(option.attribute);
            if (
                attribName.includes("sessionRef") ||
                attribName.includes("workflowRef") ||
                attribName.includes("worker") ||
                attribName.includes("Attachments") ||
                attribName.includes("Assign Worker") ||
                attribName.includes("sessionRef") ||
                attribName.includes("worker") ||
                attribName.includes("workflowRef") ||
                attribName.includes("workflowId") ||
                attribName.includes("workflowName") ||
                attribName.includes("workflowVersion") ||
                attribName.includes("baseURL") ||
                attribName.includes("continuationURL") ||
                attribName.includes("stateName") ||
                attribName.includes("stateType") ||
                attribName.includes("caseState")) {
                return {
                    id: option.id,
                    attribute: option.attribute,
                    attributeName: `${attribName} (default)`,
                    type: option.type
                }
            } else {
                return {
                    id: option.id,
                    attribute: option.attribute,
                    attributeName: attribName,
                    type: option.type
                }
            }
            })
        workflowAttributesSorted.sort((a,b) => a.attributeName.localeCompare(b.attributeName))
        return workflowAttributesSorted;
    }

    addCaseDeleteRulesRows = () => {
        const {caseDeletionRules} = this.state;

        let caseDeletionRule={
            workFlowId: this.state.workflowData.id,
            metaData: '',
            method: 'eq',
            value: '',
            days: 0
        } 

        caseDeletionRules.push(caseDeletionRule);

        this.setState({
            caseDeletionRules
        });
    }

    deleteCaseDeleteRulesRows = (index) => {
        const {caseDeletionRules} = this.state;
        caseDeletionRules.splice(index, 1);
        this.setState({
            caseDeletionRules
        });
    }
 
    handleCaseDeleteRuleChange = (index, evnt) => {
        const { name, value } = evnt.target;
        const {caseDeletionRules} = this.state;
        caseDeletionRules[index][name] = value;
        this.setState({
            caseDeletionRules
        });
    }

    handleCaseDeleteRuleSave = () => {
        const { t, caseDeletionRules, enableDeletionRules } = this.state;
        let { actionTitle } = this.state.workflow;

        let workflowObj = new Workflow();
        workflowObj.id = this.state.workflowData.id;
        workflowObj.enableDeletionRules = enableDeletionRules;
        workflowObj.actionTitle = actionTitle;
        workflowObj.caseDeletionRules = caseDeletionRules;

        WorkflowAPI.updateCaseDeletionRules(workflowObj).then(() => {
            $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
            this.updateWorkflowTable();
        }).catch(error => {
            this.errorHandling(error);
        });

    }

    openCaseHighlightConfigModal = () => {
        const {caseHighlightConfigs} = this.state;
        let caseHighlightConfig={
            workFlowId: this.state.workflowData.id,
            title: '',
            style: 'Critical',
            message: '',
            highlightConditions: [],
            tempId: 0
        } 

        let lastConfig = _.last(caseHighlightConfigs);

        if(lastConfig){
            caseHighlightConfig.tempId = lastConfig.tempId + 1;
        }

        this.setState({
            caseHighlightConfig
        });

        $(function () {
            $('#caseHighlightingAddModal').modal('show');
        });
    }

    addCaseHighlightConfigRow = () => {
        let {caseHighlightConfigs, caseHighlightConfig} = this.state;

        caseHighlightConfigs = caseHighlightConfigs.filter(config => config.tempId != caseHighlightConfig.tempId);
        
        caseHighlightConfig = JSON.parse(JSON.stringify(caseHighlightConfig));

        caseHighlightConfigs.push(caseHighlightConfig);

        this.setState({
            caseHighlightConfigs
        });
        
    }

    deleteCaseHighlightConfigRow = (index) => {
        const {caseHighlightConfigs} = this.state;
        caseHighlightConfigs.splice(index, 1);
        this.setState({
            caseHighlightConfigs
        });
    }
 
    handleCaseHighlightConfigChange = (evnt) => {
        const { name, value } = evnt.target;
        const {caseHighlightConfig} = this.state;
        caseHighlightConfig[name] = value;
        this.setState({
            caseHighlightConfig
        });
    }

    addCaseHighlightConditionRow = () => {
        const {caseHighlightConfig} = this.state;
        let caseHighlightCondition = {
            metaData: '',
            method: 'eq',
            value: '',
            condition: '',
            position: 0,
            type: "STRING"
        }

        let lastCondition = _.last(caseHighlightConfig.highlightConditions);

        if(lastCondition){
            lastCondition.condition = "AND";
            caseHighlightCondition.position = lastCondition.position + 1;
        }

        caseHighlightConfig.highlightConditions.push(caseHighlightCondition);
        this.setState({
            caseHighlightConfig
        });
    }

    deleteCaseHighlightConditionRow = (index) => {
        const {caseHighlightConfig} = this.state;
        caseHighlightConfig.highlightConditions.splice(index, 1);

        let lastCondition = _.last(caseHighlightConfig.highlightConditions);

        if(lastCondition){
            lastCondition.condition = "";
        }

        this.setState({
            caseHighlightConfig
        });
    }

    editCaseHighlightConfigRow = (index) => {
        const {caseHighlightConfigs} = this.state;
        let caseHighlightConfig = JSON.parse(JSON.stringify(caseHighlightConfigs[index]));

        this.setState({
            caseHighlightConfig
        });

        $(function () {
            $('#caseHighlightingAddModal').modal('show');
        });
    }

    resetCaseHighlightConfig = () => {
        let caseHighlightConfig = null;
        this.setState({
            caseHighlightConfig
        });
    }

    toggleCondition = (index) => {
        const {caseHighlightConfig} = this.state;
        _.forEach(caseHighlightConfig.highlightConditions, function(highlightCondition){
            highlightCondition.condition = highlightCondition.condition === "AND" ? "OR" : "AND";
        });

        let lastCondition = _.last(caseHighlightConfig.highlightConditions);

        if(lastCondition){
            lastCondition.condition = "";
        }

        this.setState({
            caseHighlightConfig
        });
    }

    handleCaseHighlightConditionChange = (index, e) => {
        const { name, value, selectedOptions } = e.target;
        const {caseHighlightConfig} = this.state;
        caseHighlightConfig.highlightConditions[index][name] = value;

        if(selectedOptions && selectedOptions[0].dataset.valuetype){
            caseHighlightConfig.highlightConditions[index]["type"] = selectedOptions[0].dataset.valuetype;
        }

        this.setState({
            caseHighlightConfig
        });
    }

    handleCaseHighlightConfigSave = () => {
        const { t, caseHighlightConfigs } = this.state;

        let workflowObj = new Workflow();
        workflowObj.id = this.state.workflowData.id;
        workflowObj.caseHighlightConfigs = caseHighlightConfigs;

        WorkflowAPI.updateCaseHighlightConfig(workflowObj).then(() => {
            $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
            this.updateWorkflowTable();
        }).catch(error => {
            this.errorHandling(error);
        });

    }

    handleSegmentedAttributeChange = event => {
        let { name, value } = event.target;
        const { workflowAttributes } = this.state;
        let selectAttribute = _.find(workflowAttributes, { attribute: value });
        this.setState({
            attributeId: selectAttribute.id,
            exerciseRecords: []
        });
    }

    handleSegmentationChange = event => {
        let { name, value } = event.target;
        if (event.target.options) {
            value = [...event.target.selectedOptions].map(option => option.value);
        }

        if(name === "segmentationWorkflow"){
            value = event.target.checked ? "Y" : "N";
            this.setState({
                workflow: {
                    ...this.state.workflow,
                    [event.target.name]: value
                },
                workflowData: {
                    ...this.state.workflowData,
                    [event.target.name]: value
                },
                exerciseRecords: []
            });
        }
    };

    changeSelectedAttribute(workflowAttributes, attributeId) {
        var workflowAttribute = _.find(workflowAttributes, { id: attributeId });

        return (workflowAttribute==undefined || workflowAttribute==null)?"":workflowAttribute.attribute;
    }
    render() {
        const { t } = this.props;
        const { workflowData, columns, data, options, records, isEdit, caseStates,
            exercises, exerciseRecords, errors, columnRecords, isColumnEdit, initialColumns, workflowAttributes,
            caseInfoRecords, caseAccessExpressions, caseDeletionRules, enableDeletionRules, showRulesModel, oldCondition, 
            selectedValue, selectedAttributeName, caseHighlightConfigs, showHighlightingModel, caseHighlightConfig } = this.state;

        const attributesSorted = this.generateSortedAttributeList(workflowAttributes)
        const workflowParams = { t, errors, isEdit, workflowData, records, caseStates, exercises, exerciseRecords, workflowAttributes, selectedAttributeName }
        const columnParams = { t, errors, workflowAttributes, attributesSorted, columnRecords }
        const statesParams = { t, caseStates, initialColumns }
        const caseInfoParams = { t, errors, workflowAttributes, attributesSorted, caseInfoRecords }
        const caseDeletionRuleParams = { t, errors, workflowAttributes, attributesSorted, isEdit, workflowData, caseDeletionRules, enableDeletionRules, showRulesModel }
        const deleteModalParams = {t}
        const caseExpressionParams = { t, caseAccessExpressions, workflowAttributes, attributesSorted,oldCondition,selectedValue};
        const caseHighlightingParams = { t, attributesSorted, caseHighlightConfigs, showHighlightingModel, caseHighlightConfig }

        return (
            <Fragment>
                <div className="content-wrapper">
                    <section className="content-header">
                        <h1>
                            {t("portalSettings.workflows.title")} <small />
                        </h1>
                    </section>

                    <section className="content">
                        <CommonTable rowClick={this.onRowClick} columns={columns} data={data} t={t} />

                        <WorkflowModal
                            params={workflowParams}
                            generateTitle={this.generateTitle}
                            addRecord={this.addRecord}
                            addSegmentationRecord={this.addSegmentationRecord}
                            moveUpCaseInfoRecord={this.moveUpCaseInfoRecord}
                            moveDownCaseInfoRecord={this.moveDownCaseInfoRecord}
                            handleWorkflowChange={this.handleWorkflowChange}
                            handleExcerciseChange={this.handleExcerciseChange}
                            deleteRecord={this.deleteRecord}
                            deleteExerciseRecord={this.deleteExerciseRecord}
                            handleChange={this.handleChange}
                            handleSave={this.handleSave}
                            generateSortedAttributeList={this.generateSortedAttributeList}
                            handleSegmentationChange={this.handleSegmentationChange}
                            handleSegmentedAttributeChange={this.handleSegmentedAttributeChange}
                        />

                        <div className="row">

                            <div className="col-xs-8">
                                {workflowData && workflowData.workflowId && (
                                    <div className="box">
                                        <div className="box-header">
                                            <p className="lead">
                                                {t("portalSettings.workflows.details.title")}
                                            </p>
                                        </div>
                                        <div className="box-body">
                                            <div className="table-responsive">
                                                <table className="table table-striped">
                                                    <tbody>
                                                        <tr key="workflowId">
                                                            <th> {t("portalSettings.workflows.details.table.workflowId")}</th>
                                                            <td>{workflowData.workflowId}</td>
                                                        </tr>
                                                        <tr key="name">
                                                            <th> {t("portalSettings.workflows.details.table.name")}</th>
                                                            <td>{workflowData.name}</td>
                                                        </tr>
                                                        <tr key="description">
                                                            <th> {t("portalSettings.workflows.details.table.description")}</th>
                                                            <td>{workflowData.description}</td>
                                                        </tr>
                                                        <tr key="icon">
                                                            <th> {t("portalSettings.workflows.details.table.icon")}</th>
                                                            <td>{workflowData.icon}</td>
                                                        </tr>
                                                        <tr key="states">
                                                            <th> {t("portalSettings.workflows.details.table.states")}</th>
                                                            <td>
                                                                <ul>
                                                                    {  workflowData.states && workflowData.states.map(option => <li key={option.id}> {option.name} </li>)}
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>


                            <div className="col-xs-4 right">
                                <div className="box">
                                    <div className="box-header">
                                        <div className="box-title">
                                            <p className="lead" style={{marginBottom:0}}>{t("portalSettings.workflows.actions.title")}</p>
                                        </div>
                                    </div>
                                    <div className="box-body">
                                        {!isEdit && (
                                        <a
                                            href="#/"
                                            className="btn btn-app"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.openModal();
                                            }}
                                        >
                                              <span
                                                  className="fa fa-plus"
                                                  data-toggle="tooltip"
                                                  title="Add"
                                              />
                                            {t("common.add")}
                                        </a>
                                        )}
                                        {workflowData && workflowData.workflowId && (
                                            <a
                                                href="#/"
                                                className="btn btn-app"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.reverseOnRowClick();
                                                    this.openModal();
                                                }}
                                            >
                                                <i
                                                    className="fa fa-plus"
                                                    data-toggle="tooltip"
                                                    title="Add"
                                                />
                                                {t("common.add")}
                                            </a>
                                        )}
                                        {workflowData && workflowData.workflowId && (
                                            <a
                                                href="#/"
                                                className="btn btn-app"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.openModal();
                                                }}
                                            >
                                                <i
                                                    className="fa fa-edit"
                                                    data-toggle="tooltip"
                                                    title="Edit"
                                                />
                                                {t("common.edit")}
                                            </a>
                                        )}

                                        {workflowData && workflowData.workflowId && (
                                            <a
                                                href="#/"
                                                className="btn btn-app"
                                                data-toggle="modal"
                                                data-target="#workflowDeleteAlert"
                                            >
                                              <span
                                                  className="fa fa-remove"
                                                  data-toggle="tooltip"
                                                  title={t("common.delete")}
                                              />
                                                {t("common.delete")}
                                            </a>
                                        )}

                                        {workflowData && workflowData.workflowId && (
                                            <a
                                                href="#/"
                                                className="btn btn-app"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.openWorkflowColumnModal();
                                                }}
                                            >
                                              <span
                                                  className="fa fa-columns"
                                                  data-toggle="tooltip"
                                                  title="Columns"
                                              />
                                                {t("portalSettings.workflows.actions.columns.button")}
                                            </a>
                                        )}

                                        {workflowData && workflowData.workflowId && (
                                            <a
                                                href="#/"
                                                className="btn btn-app"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.openCaseStateColumnModal();
                                                }}
                                            >
                                              <span
                                                  className="fa fa-exchange"
                                                  data-toggle="tooltip"
                                                  title="States"
                                              />
                                                {t("portalSettings.workflows.actions.states.button")}
                                            </a>
                                        )}

                                        {workflowData && workflowData.workflowId && (
                                            <a
                                                href="#/"
                                                className="btn btn-app"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.openConfigCaseInfoModal();
                                                }}
                                            >
                                              <span
                                                  className="fa fa-address-card-o"
                                                  data-toggle="tooltip"
                                                  title="Case Info Panel"
                                              />
                                                {t("portalSettings.workflows.actions.caseInfoPanel.button")}
                                            </a>
                                        )}

                                        {workflowData && (
                                            <a
                                                href="#/"
                                                className="btn btn-app"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.toggleCaseDeleteRuleModel(true);
                                                }}
                                            >
                                              <span
                                                  className="fa fa-clock-o"
                                                  data-toggle="tooltip"
                                                  title="Rules"
                                              />
                                                {t("portalSettings.workflows.actions.rules.button")}
                                            </a>
                                        )}

                                        {workflowData && (
                                            <a
                                                href="#/"
                                                className="btn btn-app"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    this.toggleCaseHighlightingModel(true);
                                                }}
                                            >
                                              <span
                                                  className="fa fa-lightbulb-o"
                                                  data-toggle="tooltip"
                                                  title="Highlighting"
                                              />
                                                {t("portalSettings.workflows.actions.highlight.button")}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DeleteModal
                            type="workflow"
                            id="workflowDeleteAlert"
                            params={deleteModalParams}
                            workflowDataId={workflowData?.id}
                            deleteFunction={this.deleteWorkflow}
                            title="portalSettings.workflows.delete.title"
                            message="portalSettings.workflows.delete.message"
                        />

                        <ColumnConfigModal
                            params={columnParams}
                            generateAttributeName={this.generateAttributeName}
                            addColumnRecord={this.addColumnRecord}
                            resetColumnForm={this.resetColumnForm}
                            moveUpCaseInfoRecord={this.moveUpCaseInfoRecord}
                            moveDownCaseInfoRecord={this.moveDownCaseInfoRecord}
                            editColumnRecord={this.editColumnRecord}
                            deleteColumnRecord={this.deleteColumnRecord}
                            handleColumnSave={this.handleColumnSave}
                        />

                        <CaseStateConfigModal
                            params={statesParams}
                            generateTitle={this.generateTitle}
                            handleCaseStateColumnChange={this.handleCaseStateColumnChange}
                            handleSelectAll={this.handleSelectAll}
                            handleDeselectAll={this.handleDeselectAll}
                            checkboxListner={this.checkboxListner}
                            handleStateColumn={this.handleStateColumn}
                            addExtraColumn={this.addExtraColumn}
                        />

                        <CaseInfoConfigModal
                            params={caseInfoParams}
                            generateAttributeName={this.generateAttributeName}
                            openCustomizeCaseAccessWindow={this.openCustomizeCaseAccessWindow}
                            addCaseInfoRecord={this.addCaseInfoRecord}
                            resetCaseInfoForm={this.resetCaseInfoForm}
                            moveUpCaseInfoRecord={this.moveUpCaseInfoRecord}
                            moveDownCaseInfoRecord={this.moveDownCaseInfoRecord}
                            editCaseInfoRecord={this.editCaseInfoRecord}
                            deleteCaseInfoRecord={this.deleteCaseInfoRecord}
                            handleCaseInfoSave={this.handleCaseInfoSave}
                        />

                        <CaseExpression params={caseExpressionParams}
                            addCaseAccessExpression={this.addCaseAccessExpression}
                            removeCaseAccessExpression={this.removeCaseAccessExpression}
                            handleSaveExpression={this.handleSaveExpression}
                            changeAttribute={this.changeAttribute}
                            openSpecifyWindow={this.openConfigCaseInfoModal}    
                            handleDateChange={this.handleDateChange}
                            title={t("portalSettings.workflows.actions.caseInfoPanel.caseAccessLevels.windowTitle")}
                        />

                        <CaseRulesConfigModal
                            params={caseDeletionRuleParams}
                            addCaseDeleteRulesRows={this.addCaseDeleteRulesRows}
                            deleteCaseDeleteRulesRows={this.deleteCaseDeleteRulesRows}
                            handleCaseDeleteRuleChange={this.handleCaseDeleteRuleChange}
                            handleChange={this.handleChange}
                            handleCaseDeleteRuleSave={this.handleCaseDeleteRuleSave}
                        />

                        <CaseHighlightingConfigModal
                            params={caseHighlightingParams}
                            addCaseHighlightConfigRow={this.addCaseHighlightConfigRow}
                            deleteCaseHighlightConfigRow={this.deleteCaseHighlightConfigRow}
                            editCaseHighlightConfigRow={this.editCaseHighlightConfigRow}
                            handleCaseHighlightConfigChange={this.handleCaseHighlightConfigChange}
                            handleChange={this.handleChange}
                            handleCaseHighlightConfigSave={this.handleCaseHighlightConfigSave}
                            openCaseHighlightConfigModal={this.openCaseHighlightConfigModal}
                            addCaseHighlightConditionRow={this.addCaseHighlightConditionRow}
                            deleteCaseHighlightConditionRow={this.deleteCaseHighlightConditionRow}
                            handleCaseHighlightConditionChange={this.handleCaseHighlightConditionChange}
                            resetCaseHighlightConfig={this.resetCaseHighlightConfig}
                            toggleCondition={this.toggleCondition}
                        />
                    </section>
                </div>
            </Fragment>
        );
    }
}

export default withTranslation("common")(SettingsWorkflowPage);