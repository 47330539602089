import React, { Component, Fragment } from "react";
import CaseTable from "../shared/CaseTable";
import CaseDetails from "../shared/CaseDetails";
import CaseActionPanel from "../shared/CaseActionPanel";
import CaseHistoryPanel from "../shared/CaseHistoryPanel";
import CaseLinkPanel from "../shared/CaseLinkPanel";
import CaseAPI from "../../api/CaseAPI";
import WebSocket from "../../api/WebSocket";
import CaseDetailsAPI from "../../api/CaseDetailsAPI";
import CaseAttachmentsAPI from "../../api/CaseAttachmentsAPI";
import CaseHistoryAPI from "../../api/CaseHistoryAPI";
import WorkflowAPI from "../../api/WorkflowAPI";
import AuthUtill from "../../utils/AuthUtil";
import { withTranslation } from "react-i18next";
import StateAPI from "../../api/StateAPI";
import _ from "lodash";
import {scroller} from "react-scroll";
import { CommonContext } from "../../context/CommonContext";
import CaseActionAPI from "../../api/CaseActionAPI";
import AuthUtil from "../../utils/AuthUtil";
import SockJsClient from 'react-stomp';
const $ = window.$;

const SOCKET_URL = process.env.REACT_APP_CMP_API_URL +'/cng-websocket';

class CaseState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.params,
      columns: [],
      data: [],
      caseData: null,
      actions: [],
      action: null,
      loading: false,
      t: this.props.t,
      caseHistoryData: [],
      caseLinks: null,
      caseInfoPanelConfig: [],
      helpText:"",
      user: null,
      isPortalAdmin: false
    };
  }

  static contextType = CommonContext

  componentDidMount() {
    const { workflowId, exerciseId, caseState, actions, caseStateTitle, caseStateId } = this.props.params;
    this.updateCaseTable(workflowId, exerciseId, caseState, actions, caseStateTitle);
    WebSocket.register([
      { route: "/topic/case", callback: this.refreshPageLockOnCaseLock }
    ]);
  }

  componentWillReceiveProps(nextProps) {
    const { workflowId, exerciseId, caseState, actions, caseStateTitle } = nextProps.params;
    this.updateCaseTable(workflowId, exerciseId, caseState, actions, caseStateTitle);
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip("hide");
  }

  updateCaseTable(workflowId, exerciseId, caseState, actions, caseStateTitle) {
    CaseAPI.all(workflowId, exerciseId, caseState, this.props.t).then(AllCaseData => {
      this.setState({
        columns: AllCaseData.columns,
        data: AllCaseData.data,
        caseState: caseState,
        workflowId,
        exerciseId,
        caseData: null,
        actions: actions,
        caseHistoryData: [],
        caseLinks: null,
        caseStateTitle: caseStateTitle
      });
      this.updatePage();
      this.filterColumns();
    });
  }

  updatePage = () => {
    if (this.props.params.uniqueId != undefined && this.props.params.uniqueId != null) {
      var caseData = { "uniqueId": '' };
      caseData.uniqueId = this.props.params.uniqueId
      caseData.workflowId = this.props.params.workflowId
      this.onRowClick(caseData);
    }
  }

  onRowClick = caseData => {

    scroller.scrollTo('case-info', {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart'
    });

    const { caseStateId, actions } = this.props.params
    CaseDetailsAPI.get(caseData.uniqueId)
      .then(caseData => {
        if(caseStateId!=undefined){
          caseData.caseStateId = caseStateId;
        }
        this.setState({ caseData });
      })
      .catch(error => {
        console.log("Request failed", error);
        this.refreshPage();
      });
    this.updateCaseHistoryTable(caseData.uniqueId);
    this.getAllLinkedCaseData(caseData.uniqueId);
    this.getCaseInfoPanelConfig(caseData.workflowId, caseData.uniqueId);
    this.getCaseActionInfoConfig(caseStateId, caseData.uniqueId);
    this.getCaseStateHelpText(caseStateId);
    this.setColumns(caseData.columns);
    this.getLoggedInUser(caseData.workflowId);
  };

  getCaseStateHelpText = caseStateId => {
    StateAPI.getCaseStateHelpText(caseStateId)
      .then(textValue => this.setState({ helpText: textValue }))
  }

  setColumns = columns => {
    this.setState({
      columns: columns
    })
  }

  updateCaseHistoryTable = uniqueId => {
    CaseHistoryAPI.get(uniqueId)
        .then(caseHistory => {
          this.setState({
            caseHistoryData: caseHistory
          });
        })
        .catch(error => {
          console.log("Request failed", error);
          this.refreshPage();
        });
  }

  getAllLinkedCaseData = uniqueId => {
    this.setState({ caseLinks : null });
    CaseAPI.getAllLinkedCases(uniqueId).then(AllCaseData => {
      if (AllCaseData.length != 0) {
        this.setState({
          caseLinks: AllCaseData
        });
      }
    }).catch(error => {
      console.log("Request failed", error);
      this.refreshPage();
    });
  }

  getCaseInfoPanelConfig = (workflowId, caseUniqueId) => {
    WorkflowAPI.getCaseInfoConfigByWorkflowId(workflowId, caseUniqueId)
        .then(caseInfoConfig => {
          this.setState({
            caseInfoPanelConfig: caseInfoConfig
          });
        })
        .catch(error => {
          console.log("Request failed", error);
          this.refreshPage();
        });
  }

  getCaseActionInfoConfig = (caseStateId, caseUniqueId) => {
    StateAPI.getCaseActionInfoConfig(caseStateId, caseUniqueId)
      .then(data => this.setState({ actions: data }))
      .catch(error => {
        console.log("Request failed", error);
      });
  }

  isOldEntry = (caseDetail) => {
    var conditionalDate = new Date(2022, 10, 2, 6, 0, 0, 0);
    var submittedDate = new Date(caseDetail.auditInfo.createdDate);

    return submittedDate < conditionalDate;
  }

  onActionClick = (actionType, caseDetail, sendParams) => {
    const { t } = this.state
    var continuationURLProdEdited = caseDetail.continuationURL;

    if(this.isOldEntry(caseDetail)) {
        var externalCNGBaseURL = process.env.REACT_APP_CNG_EXTERNAL_BASE_URL;
        var DELIMITER = "workflowContinuation.action";
        var parts = caseDetail.continuationURL.split(DELIMITER);
        parts[0] = externalCNGBaseURL;
        continuationURLProdEdited = parts.join(DELIMITER);
    }

    console.log(">>>>>>>>>>>>>>>>>>>>>>" + continuationURLProdEdited);

    AuthUtill.getLoggedInUser().then(loggedInUser => {
      if(sendParams.type=='1') {
        switch (actionType) {
          case "LOCK":
            caseDetail.locked = true;
            this.updateCase(caseDetail, loggedInUser.uniqueId, sendParams);
            break;
          case "UNLOCK":
            caseDetail.locked = false;
            this.updateCase(caseDetail, loggedInUser.uniqueId, sendParams);
            this.refreshPage();
            break;
          case "VIEW_PDF":
            CaseAttachmentsAPI.getAttachmentByUrl(caseDetail.mainPdf.filePath, caseDetail.mainPdf.fileName);
            break;
          case "SEND_BACK":
            this.updateCaseUpdateAttributes(continuationURLProdEdited,loggedInUser,sendParams,caseDetail,actionType);
            if (sendParams.isCaseHistoryEntry) {
              this.addCaseHistory(caseDetail, sendParams.caseHistoryEntryTitle, sendParams.comment);
            }
            break;
          case "REJECT":
            this.updateCaseUpdateAttributes(continuationURLProdEdited,loggedInUser,sendParams,caseDetail,actionType);
            if (sendParams.isCaseHistoryEntry) {
              this.addCaseHistory(caseDetail, sendParams.caseHistoryEntryTitle, sendParams.comment);
            }
            break;
          case "APPROVED":
            this.updateCaseUpdateAttributes(continuationURLProdEdited,loggedInUser,sendParams,caseDetail,actionType);
            if (sendParams.isCaseHistoryEntry) {
              this.addCaseHistory(caseDetail, sendParams.caseHistoryEntryTitle, sendParams.comment);
            }
            break;
          case "DELETE":
            this.updateCaseUpdateAttributes(continuationURLProdEdited,loggedInUser,sendParams,caseDetail,actionType);
            break;
          case "ASSIGN":
            this.updateCaseUpdateAttributes(continuationURLProdEdited,loggedInUser,sendParams,caseDetail,actionType);
            //this.refreshPage();
            break;
          case "LINK":
            this.updateCaseLinks(caseDetail, sendParams.records, loggedInUser.uniqueId, sendParams.successMessage);
            if (sendParams.isCaseHistoryEntry) {
              this.addCaseHistory(caseDetail, sendParams.caseHistoryEntryTitle, sendParams.comment);
            }
            //this.addCaseHistory(caseDetail, (sendParams.stateTransition+' to '+ sendParams.assignWorkerId), sendParams.comment);
            break;
          case "SET_GRADATION":
            this.updateCaseUpdateAttributes(continuationURLProdEdited,loggedInUser,sendParams,caseDetail,actionType);
            //this.updateCaseLinks(caseDetail, sendParams.records, loggedInUserId);
            //this.addCaseHistory(caseDetail, (sendParams.stateTransition+' to '+ sendParams.assignWorkerId), sendParams.comment);
            this.refreshPage();
            break;
          case "ADD_INFO":
            this.updateCaseUpdateAttributes(continuationURLProdEdited,loggedInUser,sendParams,caseDetail,actionType);
            //this.updateCaseLinks(caseDetail, sendParams.records, loggedInUserId);
            //this.addCaseHistory(caseDetail, (sendParams.stateTransition+' to '+ sendParams.assignWorkerId), sendParams.comment);
            this.refreshPage();
            break;
          case "ADD_COMMENT":
            if (sendParams.isCaseHistoryEntry) {
              this.addComment(caseDetail, sendParams.caseHistoryEntryTitle, sendParams.comment,true, sendParams.successMessage);
            }
            this.refreshPage();
            break;
          case "COMPLETE":
            this.updateCaseUpdateAttributes(continuationURLProdEdited,loggedInUser,sendParams,caseDetail,actionType);
            this.refreshPage();
            break;
          case "RESTORE":
            this.updateCaseUpdateAttributes(continuationURLProdEdited,loggedInUser,sendParams,caseDetail,actionType);
            if (sendParams.isCaseHistoryEntry) {
              this.addCaseHistory(caseDetail, sendParams.caseHistoryEntryTitle, sendParams.comment);
            }
            this.refreshPage();
            break;
          default:
            return;
        }
      } else {
        this.updateCaseUpdateAttributes(continuationURLProdEdited,loggedInUser,sendParams,caseDetail,actionType);
        if (sendParams.type=='3' && sendParams.isCaseHistoryEntry) {
          this.addCaseHistory(caseDetail, sendParams.caseHistoryEntryTitle, sendParams.comment);
        }
        this.refreshPage();
      }
    });
  };

  onAttachmentClick = (filePath, fileName) => {
    CaseAttachmentsAPI.getAttachmentByUrl(filePath, fileName);
  }

  backgroundCall = (url, action, uniqueId, successMessage) => {
    let self = this;
    const { t } = this.state;
    this.setState({ loading: true });
    fetch(url, {
      mode: "no-cors"
    }).then(function (response) {
      if(action !== 'DELETE'){
        $.notification("success", t("common.notification.success"), successMessage);
        self.setState({ caseData: null, loading: false });
        self.refreshPage();
      } else {
        self.deleteCase(uniqueId);
      }
    }).catch(error => {
      $.notification("error", t("common.notification.error"), error);
    });
  };

  backgroundCallForAssignWorker = (url, action, caseDetail, sendParams, loggedInUserId, successMessage) => {
    let self = this;
    const { t } = this.state;
    if (sendParams.assignWorkerId != null && sendParams.assignWorkerId != "") {
      this.setState({ loading: true });
      AuthUtill.getLoggedInUser().then(user => {
        const caseWorkerDto = {
          caseId: caseDetail.uniqueId,
          caseWorker: sendParams.assignWorkerId,
          userId: loggedInUserId,
          comment: sendParams.comment
        };

        CaseAPI.saveCaseWorker(caseWorkerDto).then(() => {
          fetch(url, {
            mode: "no-cors"
          }).then(function (response) {
            $.notification("success", t("common.notification.success"), successMessage);
            if (sendParams.isCaseHistoryEntry) {
              self.addCaseHistory(caseDetail, sendParams.caseHistoryEntryTitle + ' ' + sendParams.assignWorkerId, sendParams.comment);
            }
          }).catch(error => {
            $.notification("error", t("common.notification.error"), error);
          });
          self.setState({ caseData: null, loading: false });
          self.refreshPage();
        }).catch(error => { });
      })

    } else {
      $.notification("error", t("common.notification.error"), t("caseManagement.actionPanel.case_assign.workerValidation"));
    }
  };

  refreshPage = () => {
    const { workflowId, exerciseId, caseState, actions, caseStateTitle } = this.state;
    this.updateCaseTable(workflowId, exerciseId, caseState, actions, caseStateTitle);
    this.context.handleSideMenuUpdate(true);
  };


  onConnected = () => {
    console.log("Connected!")
  }

  onMessageReceived = (msg) => {
    if(msg == this.state.user.uniqueId) {
      setTimeout(() => {
        this.context.handleSideMenuUpdate(true);
      }, 2000);
    }
  }
  

  onDisconnect = () => {
    console.log("Disconnected!");
  }

  refreshPageLockOnCaseLock = () => {
    if (this.state.caseData) {
      this.onRowClick(this.state.caseData);
    } else {
      const { workflowId, exerciseId, caseState, actions, caseStateTitle } = this.state;
      this.updateCaseTable(workflowId, exerciseId, caseState, actions, caseStateTitle);
    }
  };

  showAlert = action => {
    this.setState({ action: action });
    $("#ActionAlert")
      .appendTo("body")
      .modal("show");
  };

  addCaseHistory = (caseDetail, action, comment,isCommentNotifi) => {
    AuthUtill.getLoggedInUser().then(user => {
      const { t } = this.state;
      const caseHistoryDto = {
        caseEntryId: caseDetail.uniqueId,
        userName: user.firstName + ' ' + user.lastName,
        actionPerformed: action,
        comment: comment
      };
      CaseHistoryAPI.save(caseHistoryDto).then(() => {
        if (comment!=="" && isCommentNotifi) {
          $.notification("success", t("common.notification.success"),t("caseManagement.actionPanel.comment.validations.RECORD_SAVED_SUCCESS"));  
        }
        this.refreshPage();
      }).catch(error => { 
        if (comment!=="" && isCommentNotifi) {
          $.notification("error", t("common.notification.error"), t("caseManagement.actionPanel.comment.validations.RECORD_NOT_SAVED"));
        }        
      });
    })
  };

  addComment = (caseDetail, action, comment,isCommentNotifi, successMessage) => {
    AuthUtill.getLoggedInUser().then(user => {
      const { t } = this.state;
      const caseHistoryDto = {
        caseEntryId: caseDetail.uniqueId,
        userName: user.firstName + ' ' + user.lastName,
        actionPerformed: action,
        comment: comment
      };
      CaseHistoryAPI.save(caseHistoryDto).then(() => {
        if (comment!=="" && isCommentNotifi) {
          $.notification("success", t("common.notification.success"),successMessage);
        }
        this.refreshPage();
      }).catch(error => {
        if (comment!=="" && isCommentNotifi) {
          $.notification("error", t("common.notification.error"), t("caseManagement.actionPanel.comment.validations.RECORD_NOT_SAVED"));
        }
      });
    })
  };

  updateCaseWorkerApi = (caseDetail, caseWorker, comment, userId) => {
    AuthUtill.getLoggedInUser().then(user => {
      const { t } = this.state;
      const caseWorkerDto = {
        caseId: caseDetail.uniqueId,
        caseWorker: caseWorker,
        userId: userId,
        comment: comment
      };

      CaseAPI.saveCaseWorker(caseWorkerDto).then(() => {
        $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
        return true;
      }).catch(error => { });
    })
  };

  updateCase = (caseDetail, userId, sendParams) => {
    const caseLockDto = {
      caseId: caseDetail.uniqueId,
      userId: userId,
      locked: caseDetail.locked,
      comment: sendParams.comment,
      caseLockAccessUsrList: sendParams.accessUserList
    };
    const { t } = this.state;
    let action = caseDetail.locked
        ? t("caseManagement.actionConfirmation.locked")
        : t("caseManagement.actionConfirmation.unlocked");

    CaseActionAPI.lockUnlockUpdater(caseLockDto).then(data => {
      $.notification("info", t("common.notification.info"), sendParams.successMessage);
      if (sendParams.isCaseHistoryEntry) {
        this.addCaseHistory(caseDetail, sendParams.caseHistoryEntryTitle, sendParams.comment);
      }
    }).catch(error => {
      console.error(error);
    });
  };

  updateCaseWorker = (caseDetail, caseWorker, comment, userId) => {
    const { t } = this.state;
    const caseWorkerDto = {
      caseId: caseDetail.uniqueId,
      caseWorker: caseWorker,
      userId: userId,
      comment: comment
    };

    WebSocket.sendCaseWorkerUpdateRequest(caseWorkerDto);
    $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
  };

  updateCaseLinks = (caseDetail, records, userId, successMessage) => {
    const { t } = this.state;
    const caseLinkDto = {
      caseId: caseDetail.uniqueId,
      linkedCases: records,
      userId: userId
    };    
    CaseAPI.updateCaseLinks(caseLinkDto).then(res => {
      $.notification("success", t("common.notification.success"), successMessage);
      this.refreshPage();
    });
  }

  deleteCase = uniqueId => {
    const { t } = this.state;
    CaseAPI.deleteCase(uniqueId).then(res => {
      $.notification("success", t("common.notification.success"), t("common.validations.RECORD_DELETED_SUCCESS"));
      this.setState({ loading: false });
      this.refreshPage();
    });
  };

  deleteCaseLink = (idx, caseId, relatedCaseId, caseLinks) => {
    const { t } = this.state;
    CaseAPI.deleteCaseLink(caseId, relatedCaseId).then(res => {
      $.notification("success", t("common.notification.success"), t("common.validations.LINKED_CASE_REMOVED_SUCCESS"));
      this.getAllLinkedCaseData(caseId);
    }).catch(error => {
      console.log("Request failed", error);
    });
  }

  updateCaseUpdateAttributes = (continuationURLProdEdited,loggedInUser,sendParams,caseDetail,actionType) => {
    if(this.isSecurePublishing(caseDetail)){
      const caseUpdateAttributeData = {
        caseEntryId: caseDetail.uniqueId,
        action: sendParams.stateTransition,
        assn: loggedInUser.uniqueId,
        comment: sendParams.comment,
        workerEmail: sendParams.email,
        entryTitle: (sendParams.isCaseHistoryEntry ? sendParams.caseHistoryEntryTitle : ''),
        aEmail: loggedInUser.email,
        workerName: (sendParams.assignWorkerId ? sendParams.assignWorkerId : ''),
        aName: loggedInUser.firstName + ' ' + loggedInUser.lastName
      };
      let response = CaseAPI.saveCaseUpdateAttribute(caseUpdateAttributeData);
      response.then( res =>{
        if ("ASSIGN" === actionType){
          this.backgroundCallForAssignWorker(
            `${continuationURLProdEdited}&actionKey=${res.data}`,this.getActtionMessage(actionType),
            caseDetail, sendParams, loggedInUser.uniqueId, sendParams.successMessage
          );
        } else if (sendParams.type=='2'){
          window.open(`${continuationURLProdEdited}&actionKey=${res.data}`)
        } else {
          this.backgroundCall(
            `${continuationURLProdEdited}&actionKey=${res.data}`, actionType, caseDetail.uniqueId, sendParams.successMessage
          );
        }
      }).catch(error => {
        console.log("Request failed", error);
      });
      
    } else {
      if ("ASSIGN" === actionType){
        this.backgroundCallForAssignWorker(
          `${continuationURLProdEdited}&action=${sendParams.stateTransition}&assn=${loggedInUser.uniqueId}&comment=${sendParams.comment}&workerEmail=${sendParams.email}`,
          this.getActtionMessage(actionType),
          caseDetail, sendParams, loggedInUser.uniqueId, sendParams.successMessage
        );
      } else if (sendParams.type=='2'){
        window.open(`${continuationURLProdEdited}&action=${sendParams.stateTransition}&assn=${loggedInUser.uniqueId}&comment=${sendParams.comment}`)
      } else {
        this.backgroundCall(
          `${continuationURLProdEdited}&action=${sendParams.stateTransition}&assn=${loggedInUser.uniqueId}&comment=${sendParams.comment}&workerEmail=${sendParams.email}`,
          actionType, caseDetail.uniqueId, sendParams.successMessage
        );
      }
    }

  }

  isSecurePublishing = (caseDetail) => {
    const { securePublishing } = this.props.params;
    if("Y" === securePublishing){
      var conditionalDate = new Date(2022, 10, 2, 6, 0, 0, 0);
      var submittedDate = new Date(caseDetail.auditInfo.createdDate);

      return submittedDate > conditionalDate;
    }
    
    return false;
  }

  getActtionMessage = (actionType) => {
    const { t } = this.state;
    switch (actionType) {
      case "SEND_BACK":
        return t("caseManagement.actionConfirmation.sentBack");
      case "ASSIGN":
        return t("caseManagement.actionConfirmation.assign");
      case "REJECT":
        return t("caseManagement.actionConfirmation.rejected");
      case "APPROVED":
        return t("caseManagement.actionConfirmation.approved");
      case "RESTORE":
        return t("caseManagement.caseHistoryPanel.table.actions.Restore");
      default:
        return;
    }
  }

  filterColumns = async () => {
    const { caseStateId, workflowIndex } = this.props.params;
    let localColumns = [];

    WorkflowAPI.getWorkflowColumnsWithType(workflowIndex, caseStateId).then(data => {
      _.forEach(data, function (item) {
        if (item.type === "default") {
          let record = {
            field: item.field,
            width: item.width,
            title: item.title,
            align: item.align,
            order: item.order,
            sorter: item?.sorter
          }
          localColumns.push(record)
        }
      })
      this.setState({columns:localColumns, wfColumns: data});
    })
  }

  getLoggedInUser = (workflowId) => {
    return AuthUtil.getLoggedInUser().then(user => {
      this.setState({
        user: user,
        isPortalAdmin: AuthUtil.hasRole(user, ["PORTAL_ADMIN"], workflowId)
      });
    });
  };

  render() {
    const {
      columns,
      data,
      caseState,
      caseData,
      actions,
      action,
      loading,
      workflowId,
      workflowIndex,
      wfColumns,
      caseHistoryData,
      caseLinks,
      caseInfoPanelConfig,
      caseStateTitle,
      exerciseId,
      filteredActions,
      helpText,
      user,
      isPortalAdmin
    } = this.state;
    const { caseStateId } = this.props.params;
    let params = {
      columns : (columns) ? columns.sort((a, b) => a.order > b.order ? 1 : -1): [],
      data,
      caseState,
      workflowId,
      workflowIndex,
      wfColumns,
      caseStateTitle,
      exerciseId,
      caseStateId
    };
    if (caseData !== null) {
      caseData.actions = actions;
      caseData.caseStateHelpText = helpText;
      caseData.linkedCases = caseLinks;
    }

    let userData = {
      user,
      isPortalAdmin
    };

    const { t } = this.props;
    return (
      <Fragment>
        <section className="content">
          <CaseTable state={params} rowClick={this.onRowClick} />
          <table className="table" name="case-info">
            <tbody>
              <tr>
                <td style={{ paddingLeft: 10, width: '70%' }}>
                  {caseData && (
                      <div className="row">
                        <div className="col-xs-12">
                        <CaseDetails
                            userData={userData}
                            caseData={caseData}
                            config={caseInfoPanelConfig}
                            attachmentClick={this.onAttachmentClick} />
                        </div>
                      </div>
                  )}

                  <div className="row">
                    <div className="col-xs-12">
                      {caseData && caseHistoryData && caseHistoryData.length != 0 && (
                          <CaseHistoryPanel
                              userData={userData}
                              caseData={caseData}
                              caseHistoryData={caseHistoryData}
                              t={t}
                              loading={loading}
                              attachmentClick={this.onAttachmentClick}/>
                      )}
                    </div>
                  </div>
                </td>

                <td>
                  {caseData && (
                      <div className="row">
                        <div className="col-xs-12">
                          <CaseActionPanel
                              caseData={caseData}
                              actionClick={this.onActionClick}
                              loading={loading}
                          />

                          <div className="modal fade" id="ActionAlert">
                            <div className="modal-dialog">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <p>{t("caseManagement.caseState.application")} {action} </p>
                                </div>
                                <div className="modal-footer">
                                  <button
                                      type="button"
                                      className="btn btn-default col-md-2 pull-right"
                                      data-dismiss="modal"
                                  >
                                    Ok
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  )}

                  <div className="row">
                    <div className="col-xs-12">
                      {caseLinks && (
                          <CaseLinkPanel
                              caseLinks={caseLinks}
                              t={t}
                              loading={loading}
                              caseData={this.props.params}
                              refCase={caseData}
                              actionClick={this.deleteCaseLink}
                          />
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <div>
          <SockJsClient 
            url={SOCKET_URL}
            topics={['/topic/message']}
            onConnect={this.onConnected}
            onDisconnect={this.onDisconnect}
            onMessage={msg => this.onMessageReceived(msg)}
            debug={false}
          />
        </div>
      </Fragment>
    );
  }
}
export default withTranslation("common")(CaseState);
