import React, {Component, Fragment} from "react";
import {withTranslation} from "react-i18next";
import CommonTable from "../components/shared/CommonTable";
import StateAPI from "../api/StateAPI";
import StateActionAPI from "../api/StateActionAPI";
import WorkFlowMenuAPI from "../api/WorkFlowMenuAPI";
import CaseState from "../dto/CaseState";
import _ from "lodash";
import DeleteModal from "../components/shared/DeleteModal";
import CommonActionButton from "../components/shared/CommonActionButton";
import CaseStateModal from "../components/caseState/CaseStateModal";
import StateDetails from "../components/caseState/StateDetails";
const $ = window.$;
const lang = process.env.REACT_APP_LANG;

class SettingsCaseStatePage extends Component {
    ref = React.createRef();

    state = {
        caseState: new CaseState(),
        t : this.props.t,
        stateData : null,
        columns: [],
        data: [],
        isEdit: false,
        selectAction: null,
        caseActions: [],
        records: [],
        errors: {},
        handleRecords: []
    };

    onRowClick = stateData => {
        let actions = stateData.actions;
        let newData = [];

        actions.forEach(item =>{
            let record = {
                id: item.id,
                name: item.name,
                description: item.description,
                title : item.title,
                tooltip : item.tooltip,
                icon : item.icon,
                type : item.type,
                predefinedActions : item.predefinedActions,
                isCommentSection : item.isCommentSection,
                isMandatoryComment : item.isMandatoryComment,
                isCaseHistoryEntry : item.isCaseHistoryEntry,
                isCaseHistoryLogPdf : item.isCaseHistoryLogPdf,
                caseHistoryEntryTitle : item.caseHistoryEntryTitle,
                confirmActionButtonLabel : item.confirmActionButtonLabel,
                cancelActionButtonLabel : item.cancelActionButtonLabel,
                commentHintText : item.commentHintText,
                isTransitionState : item.isTransitionState,
                caseTransitionState : item.caseTransitionState,
                order : item.order,
                successMessage : item.successMessage,
                caseExpressions : item.caseExpressions,
                selectedWorkflowId : item.selectedWorkflowId
            };
            newData.push(record);
        })

        this.setState({
            stateData: stateData,
            caseState: stateData,
            isEdit: true,
            records: newData,
            handleRecords: []
        });
    };

    reverseOnRowClick=()=>{
        this.setState({
            stateData: null,
            caseState: new CaseState(),
            isEdit: false,
            records: [],
            handleRecords: []
        });
        this.componentDidMount();
    }

    componentDidMount() {
        this.updateStateTable();
        this.updateCaseStateActions();
    }

    updateStateTable() {
        let { columns, t } = this.state;

        function getColumns (column,t) {
            var translation = t("portalSettings.caseStates.fields."+column.field);
            if(!translation.includes("portalSettings.caseStates.fields")){
                column.title = translation;
            }
        }

        StateAPI.all().then(data => {
            _.forEach(data.columns, function (column) {
                getColumns(column, t)
            });

            columns = [...data.columns];

            this.setState({
                columns: columns,
                data: data.data,
                caseState: new CaseState(),
                stateData : null,
                isEdit: false,
                selectAction: null,
                records: [],
                errors: {},
                handleRecords: []
            });
        });
    }

    updateCaseStateActions(){
        StateActionAPI.all().then(data => {
            this.setState({caseActions: data.data})
        })
    }

    handleChange = event => {
        let { name, value } = event.target;
        if (event.target.options) {
            value = [...event.target.selectedOptions].map(option => option.value);
        }
        if (name === "status") {
            value = event.target.checked ? "ACTIVE" : "INACTIVE";
        }
        this.setState({
            caseState: {
                ...this.state.caseState,
                [event.target.name]: value
            }
        });

    };

    deleteCaseState = (id,name) => {
        const { t } = this.state;

        if(name=="All_Applications") {
            $.notification("error", t("common.notification.error"), t("portalSettings.caseStates.validationError.CASE_STATE_DEFAULT_DELETE"));
            return;
        }

        StateAPI.delete(id).then(() => {
           $.notification("success", t("common.notification.success"), t("common.validations.RECORD_DELETED_SUCCESS"));
           this.updateStateTable();
        }).catch(error => {
            this.errorHandling(error);
        });
    };

    handleSave = event => {
        const { t } = this.state;

        let {
            name,
            workflowStateName,
            description,
            buttonStyle,
            helpText
        } = this.state.caseState;

        event.preventDefault();

        let caseStateObj = new CaseState(
            "",
            name,
            workflowStateName,
            description,
            buttonStyle,
            helpText,
            [],
            []
        );

        if (!this.isFormValid(caseStateObj)) return;

        if(this.state.isEdit) {
            if(caseStateObj.id==null || caseStateObj.id=="") {
                caseStateObj.id = this.state.stateData.id;
            }

            if(caseStateObj.actions==null || caseStateObj.actions.length==0){
                caseStateObj.actions = this.resetActionOrder(this.state.handleRecords);
            }
            StateAPI.update(caseStateObj).then(() => {
                $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
                this.updateStateTable();
            }).catch(error => {
                this.errorHandling(error);
            });
        } else {
            caseStateObj.actions = this.resetActionOrder(this.state.handleRecords);

            StateAPI.save(caseStateObj).then(() => {
                $.notification("success", t("common.notification.success"), t("common.validations.RECORD_SAVED_SUCCESS"));
                this.updateStateTable();
            }).catch(error => {
                this.errorHandling(error);
            });
        }

        this.closeModal();
        WorkFlowMenuAPI.reinitialize().then(menuData => {
            this.props.menuHandler(menuData);

        }).catch(error => {});
    }

    errorHandling = error => {
        const { t } = this.state;

        if (error.response) {
            $.notification("error", t("common.notification.error"), t("portalSettings.caseStates.validationError."+error.response.data));
        } else if (error.request) {
            console.log(error.request);
        } else {
            console.log('Error', error.message);
        }
    }

    closeModal = () => {
        $(function () {
            $('#modal-case-state').modal('hide');
        });
    }

    openModal = () => {
        if(this.state.errors!=null && this.state.errors.length!=0){
            this.setState({
                errors: []
            });
        }

        if(this.state.isEdit){
            this.setState({
                handleRecords: this.state.records
            });
        } else {
            this.setState({
                handleRecords: []
            });
        }

        $(function () {
            $('#modal-case-state').find('form').trigger('reset');
            $('#modal-case-state').modal('show');
        });
    }

    isFormValid = caseObject => {
        const { t } = this.state;

        const errors = {};
        if (!caseObject.name)
            errors.name = t(
                "portalSettings.caseStates.validationError.NAME_REQUIRED"
            );

            if (!caseObject.workflowStateName)
            errors.workflowStateName = t(
                "portalSettings.caseStates.validationError.WORKFLOW_STATE_NAME_REQUIRED"
            );

        if (!caseObject.buttonStyle)
            errors.buttonStyle = t(
                "portalSettings.caseStates.validationError.BUTTON_STYLE_REQUIRED"
            );

        this.setState({
            errors: errors
        });
        return Object.keys(errors).length === 0;
    }

    handleCaseActionChange = event => {
        let { name, value } = event.target;
        const { caseActions } = this.state;
        let selectedAction = _.find(caseActions, { id: parseInt(value) });
        this.setState({
            selectAction: selectedAction.id
        });
    }

    addRecord = () => {
        const {
            caseActions,
            selectAction,
            records,
            handleRecords
        } = this.state;

        var selectedAction;
        var newData;

        if (selectAction != null) {
            selectedAction = _.find(caseActions, { id: selectAction });

            let record = {
                id: selectedAction.id,
                name: selectedAction.name,
                description: selectedAction.description,
                title : selectedAction.title,
                tooltip : selectedAction.tooltip,
                icon : selectedAction.icon,
                type : selectedAction.type,
                predefinedActions : selectedAction.predefinedActions,
                isCommentSection : selectedAction.isCommentSection,
                isMandatoryComment : selectedAction.isMandatoryComment,
                isCaseHistoryEntry : selectedAction.isCaseHistoryEntry,
                isCaseHistoryLogPdf : selectedAction.isCaseHistoryLogPdf,
                caseHistoryEntryTitle : selectedAction.caseHistoryEntryTitle,
                confirmActionButtonLabel : selectedAction.confirmActionButtonLabel,
                cancelActionButtonLabel : selectedAction.cancelActionButtonLabel,
                commentHintText : selectedAction.commentHintText,
                isTransitionState : selectedAction.isTransitionState,
                caseTransitionState : selectedAction.caseTransitionState,
                order : handleRecords.length+1,
                successMessage : selectedAction.successMessage,
                caseExpressions : selectedAction.caseExpressions,
                selectedWorkflowId : selectedAction.selectedWorkflowId
            };

            if (!_.find(handleRecords, { id: selectedAction.id })) {
                newData = [...handleRecords, record];
                this.setState({
                    handleRecords: newData
                });
            }
        }
    }

    deleteRecord = idx => {
        var handleRecords = this.state.handleRecords;
        var newData = [];
        handleRecords.forEach(item =>{
            let record = {
                id: item.id,
                name: item.name,
                description: item.description,
                title : item.title,
                tooltip : item.tooltip,
                icon : item.icon,
                type : item.type,
                predefinedActions : item.predefinedActions,
                isCommentSection : item.isCommentSection,
                isMandatoryComment : item.isMandatoryComment,
                isCaseHistoryEntry : item.isCaseHistoryEntry,
                isCaseHistoryLogPdf : item.isCaseHistoryLogPdf,
                caseHistoryEntryTitle : item.caseHistoryEntryTitle,
                confirmActionButtonLabel : item.confirmActionButtonLabel,
                cancelActionButtonLabel : item.cancelActionButtonLabel,
                commentHintText : item.commentHintText,
                isTransitionState : item.isTransitionState,
                caseTransitionState : item.caseTransitionState,
                successMessage : item.successMessage,
                order : item.order,
                caseExpressions : item.caseExpressions,
                selectedWorkflowId : item.selectedWorkflowId
            };
            newData.push(record);
        })

        newData.splice(idx, 1);
        newData = this.resetActionOrder(newData);
        this.setState({
            handleRecords: newData
        });
    };

    generateTitle = (type, key, title) => {
        const {t} = this.state;
        var translation = t(type+"."+key);
        if(translation.includes(type)){
            return title;
        } else {
            return translation;
        }
    }

    moveUpCaseActionRecord = (type, record) => {
        const {
            handleRecords
        } = this.state;

        var  currentIndex = record.order;
        var  previousIndex = record.order-1;
        if(currentIndex==1){ return false;}

        if(type=="case_actions"){
            handleRecords.forEach(item =>{
                if(item.order==currentIndex){
                    item.order = currentIndex-1
                } else if(item.order==previousIndex) {
                    item.order = previousIndex+1
                }
            })

            this.setState({
                handleRecords: handleRecords
            });
        }
    }

    moveDownCaseActionRecord = (type, record) => {
        const {
            handleRecords
        } = this.state;

        var  currentIndex = record.order;
        var  nextIndex = record.order+1;

        if(type=="case_actions"){
            if(currentIndex==handleRecords.length){ return false;}
            handleRecords.forEach(item =>{
                if(item.order==currentIndex){
                    item.order = currentIndex+1
                } else if(item.order==nextIndex) {
                    item.order = nextIndex-1
                }
            })

            this.setState({
                handleRecords: handleRecords
            });
        }
    }

    resetActionOrder = list => {
        let order = 1;
        list.forEach(item =>{
            item.order = order;
            order++;
        })

        return list;
    }

    render() {
        const { t } = this.props;
        const { stateData, columns, data, isEdit, records, caseActions, errors, handleRecords } = this.state;
        const deleteModalParams = { t }
        const stateDetailsParams = { t, stateData }
        const caseStateModalParams = { t, isEdit, errors, stateData, caseActions, handleRecords }
        return (
            <Fragment>
                <div className="content-wrapper">
                    <section className="content-header">
                        <h1>
                            {t("portalSettings.caseStates.title")} <small />
                        </h1>
                    </section>

                    <section className="content">
                        <CommonTable rowClick={this.onRowClick} columns={columns} data={data} t={t} />

                        <CaseStateModal
                            params={caseStateModalParams}
                            addRecord={this.addRecord}
                            deleteRecord={this.deleteRecord}
                            generateTitle={this.generateTitle}
                            handleChange={this.handleChange}
                            handleCaseActionChange={this.handleCaseActionChange}
                            handleSave={this.handleSave}
                            moveUpCaseActionRecord={this.moveUpCaseActionRecord}
                            moveDownCaseActionRecord={this.moveDownCaseActionRecord}
                        />

                        <div className="row">

                            <StateDetails
                                params={stateDetailsParams}
                            />

                            <div className="col-xs-4 right">
                                <div className="box">
                                    <div className="box-header">
                                        <div className="box-title">
                                            <p className="lead" style={{marginBottom:0}}>{t("portalSettings.caseStates.actions.title")}</p>
                                        </div>
                                    </div>
                                    <div className="box-body">
                                        {!isEdit && (
                                        <a
                                            href="#/"
                                            className="btn btn-app"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.openModal();
                                            }}
                                        >
                                              <span
                                                  className="fa fa-plus"
                                                  data-toggle="tooltip"
                                                  title="Add"
                                              />
                                            {t("common.add")}
                                        </a>
                                        )}
                                        {stateData &&
                                            <CommonActionButton
                                                params={this.props.t}
                                                reverseOnRowClick={this.reverseOnRowClick}
                                                openModal={this.openModal}
                                            />}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <DeleteModal
                            type="state"
                            id="caseStateDeleteAlert"
                            params={deleteModalParams}
                            stateDataId={stateData?.id}
                            stateDataName={stateData?.name}
                            deleteFunction={this.deleteCaseState}
                            message="portalSettings.caseStates.delete.message"
                        />

                    </section>
                </div>
            </Fragment>
        );
    }
}

export default withTranslation("common")(SettingsCaseStatePage);