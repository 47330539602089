import React, { Component } from "react";
import CaseAPI from "../api/CaseAPI";
import UserAPI from "../api/UserAPI";
import WorkFlowMenuAPI from "../api/WorkFlowMenuAPI";
import { NavLink as Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import StateCountUtil from "../utils/StateCountUtil";

class DashboardPage extends Component {
  state = {
    reload: false,
    menu: null,
    t: this.props.t
  };

  loadDashboardData = () => {
    CaseAPI.getDashboardData(sessionStorage.getItem("userId"))
      .then(data => {
        if (sessionStorage.getItem("loggedInUser") === null) {
          let pid = sessionStorage.getItem("userId");
          UserAPI.getProfile(pid).then(userDb => {
            let userDto = userDb;
            userDto.roles = userDb.roleList.map(role => role.name);
            userDto.exerciseIdList = userDb.exerciseIdList.map(id => id.name);
            sessionStorage.setItem("loggedInUser", JSON.stringify(userDto));
            this.setState({
              reload: true,
              stats: data
            });
          });
        } else {
          this.setState({
            reload: false,
            stats: data
          });
        }
      })
      .catch(error => console.error(error));
  };

  loadMyCasesData = () => {
    CaseAPI.getMyCaseData(sessionStorage.getItem("userId"))
    .then(data => {this.setState({ reload: false, myCaseStats: data})})
    .catch(error => console.error(error))
  }

  getStateCount = (stats, stateName, workflowId) => {
    return StateCountUtil.getStateCount(stats, stateName, workflowId);
  };

  loadMenuItems = () => {
    WorkFlowMenuAPI.updatedList().then(menueitem => {
      this.setState({ menu: menueitem });
    });
  };

  componentDidMount() {
    this.loadDashboardData();
    this.loadMenuItems();
    this.loadMyCasesData();
  }

  renderStatistics = () => {
    let { menu, stats, t } = this.state;

    if (menu) {
      let statistics = [];
      menu.forEach(item => {
        statistics.push(
            <h4 className="page-title" key={item.workflowId+'_'+item.id}>
              {item.name}
            </h4>
        );
        statistics.push(<div key={item.name} className="row">{this.renderStatisticElements(item, stats, t)}</div>);
      });
      return statistics;
    }
  };

  generateTitle = title => {
    const {t} = this.state;
    var translation = t("caseManagement.viewDetails.stateType."+title);
    if(translation.includes("caseManagement.viewDetails.stateType")){
      return title;
    } else {
      return translation;
    }
  }

  renderStatisticElements = (item, stats, t) => {
    let rowElements = [];
    rowElements.push(
        item.states.sort((a, b) => a.order > b.order ? 1 : -1).filter(aItem => aItem.workflowStateName !== "My_Cases").map((aItem, i) => (
            <div key={i} className="col-lg-2 col-xs-6">
              <Link
                  to={{
                    pathname: "/protected/cases/" + item.workflowId,
                    search: "?type=" + aItem.workflowStateName,
                    state: {
                      workflowId: item.workflowId,
                      workflowIndex: item.id,
                      workFlowName: item.name,
                      exerciseId: item.exerciseId,
                      caseState: aItem.workflowStateName,
                      actions: aItem.actions,
                      caseStateTitle:aItem.name,
                      caseStateId: aItem.id,
                      securePublishing : item.securePublishing
                    }
                  }}
              >
                <div className={"small-box " + aItem.buttonStyle}>
                  <div className="inner">
                    <h3>
                      {this.getStateCount(
                          stats,
                          aItem.workflowStateName,
                          item.workflowId,
                      )}
                    </h3>
                    <p>{aItem.name}</p>
                  </div>
                </div>
              </Link>
            </div>
        )),
        this.renderMyCaseContainer(item)
    );
    return rowElements;
  };

  renderMyCaseContainer = (item)=>{
    const {myCaseStats,t} = this.state
    let myCaseActions, myCaseTitle, buttonStyle, caseStateId, helpText;
    let myCaseStateAvailability = false;
    item.states.filter(aItem => {
      caseStateId = aItem.id;
      aItem.workflowStateName === "My_Cases" ? myCaseActions = aItem.actions : myCaseActions = [];
      aItem.workflowStateName === "My_Cases" ? myCaseTitle = aItem.name : myCaseTitle = t("portalSettings.caseStates.fields.myCases");
      aItem.workflowStateName === "My_Cases" ? buttonStyle = aItem.buttonStyle : buttonStyle = "bg bg-navy";
      aItem.workflowStateName === "My_Cases" ? myCaseStateAvailability = true : myCaseStateAvailability = false;
    })
    return (
      myCaseStateAvailability && <div className="col-lg-2 col-xs-6">
        <Link
           to={{
              pathname: "/protected/cases/" + item.workflowId,
              search: "?type=" + "My_Cases",
              state: {
                 workflowId: item.workflowId,
                 workflowIndex: item.id,
                 workFlowName: item.name,
                 exerciseId: item.exerciseId,
                 caseState: "My_Cases",
                 actions: myCaseActions,
                 caseStateTitle:myCaseTitle,
                 caseStateId: caseStateId,
                 securePublishing : item.securePublishing
              }
            }}
          >
        <div className={"small-box "+buttonStyle }>
          <div className="inner">
            <h3>{this.getStateCount(
              myCaseStats,
              "My_Cases",
              item.workflowId
            )}</h3>
            <p>{myCaseTitle}</p>
          </div>
        </div>
        </Link>
      </div>
    )
  }

  render() {
    let { reload, t } = this.state;

    // Need to reload inorder to get the side menue
    if (reload) {
      window.location.reload(true);
    }
    return (
      <div>
        <div className="content-wrapper">
          <section className="content-header">
            <h1>{t("dashboard.sectionHeader")}</h1>
          </section>
          <section className="content">{this.renderStatistics()}</section>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(DashboardPage);