import React, { Component } from "react";
import AuthUtil from "../utils/AuthUtil";
import { NavLink as Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CommonContext } from "../context/CommonContext";
import ConfigAPI from "../api/ConfigAPI";
import AuthAPI from "../api/AuthAPI";
import i18n from "i18next";
import Select from 'react-select';
const $ = window.$;
const lang = process.env.REACT_APP_LANG;

class HeaderSection extends Component {
  state = {
    user: this.props.user,
    t: this.props.t,
    locale: null,
    loginConfig:null,
    languages: [
      {
        value: 'en',
        label:  <span>
                <img className="lang-en-img" alt=""/>
          {" "} {this.props.t('common.changeLanguage.english')}
              </span>
      },
      {
        value: 'no',
        label:  <span>
                <img className="lang-no-img" alt=""/>
          {" "} {this.props.t('common.changeLanguage.norwegian')}
              </span>
      }
    ],
    selectedLang: lang === 'no' ? {
                            value: 'no',
                            label:  <span>
                                      <img className="lang-no-img" alt=""/>
                                      {" "} {this.props.t('common.changeLanguage.norwegian')}
                                    </span>
                         } :
                          {
                            value: 'en',
                            label:  <span>
                                      <img className="lang-en-img" alt=""/>
                                      {" "} {this.props.t('common.changeLanguage.english')}
                                    </span>
                          }
  };

  constructor(props) {
    super(props);
    this.auth = AuthUtil.getService();
  }

  setValues = (selectValues )=> this.setState({selectValues});

  componentWillReceiveProps(nextProps) {
    this.setState({ user: nextProps.user });
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
    ConfigAPI.getConfig().then(data => {
      this.setState({
        loginConfig: data.loginPage,
      });
    });
  }

  signOut = () => {
    AuthAPI.logout();
    this.auth.logout();
    this.auth.removeLocalStore();
  };

  langChangeHandler = (selectedLang) => {
    i18n.changeLanguage(selectedLang.value);
    let enLang = '';
    let noLang = '';
    let title = {};
    if (selectedLang.value === 'no') {
      enLang = 'Engelsk';
      noLang = 'Norsk';
      title = {
        value: 'no',
        label:  <span>
                  <img className="lang-no-img" alt=""/>
                  {" "} {noLang}
                </span>
      };
    } else {
      enLang = 'English'
      noLang = 'Norwegian';
      title = {
        value: 'en',
        label:  <span>
                  <img className="lang-en-img" alt=""/>
                  {" "} {enLang}
                </span>
      };
    }
    this.setState({languages: [
        {
          value: 'en',
          label:  <span>
                <img className="lang-en-img" alt=""/>
            {" "} {enLang}
              </span>
        },
        {
          value: 'no',
          label:  <span>
                <img className="lang-no-img" alt=""/>
            {" "} {noLang}
              </span>
        }
      ],
      selectedLang: title
    });
  };

  static contextType = CommonContext;

  render() {
    const { user, t, locale, loginConfig, languages, selectedLang } = this.state;
    document.title =this.context.title !==""?loginConfig && this.context.title+" | "+ t('loginPage.companyName') :loginConfig && loginConfig.title;
    return (
      <div>
        <header className="main-header">
          <a href="/" className="logo">
            {/* mini logo for sidebar mini 50x50 pixels */}
            <span className="logo-mini">
              <b>Por</b>tal
            </span>
            {/* logo for regular state and mobile devices */}
            <span className="logo-lg">
              <b>{ this.context.title }</b>
            </span>
          </a>
          {/* Header Navbar */}
          <nav className="navbar navbar-static-top" role="navigation">
            {/* Sidebar toggle button*/}
            <a
              href="#/"
              className="sidebar-toggle"
              data-toggle="push-menu"
              role="button"
            >
              <span className="sr-only">Toggle navigation</span>
            </a>
            {/* Navbar Right Menu */}
            <div className="navbar-custom-menu">
              <ul className="nav navbar-nav">
                <Select
                    className="lang-select"
                    value={selectedLang}
                    onChange={this.langChangeHandler}
                    options={languages}
                />
              </ul>
              <ul className="nav navbar-nav">
                {/* User Account Menu */}
                <li className="dropdown user user-menu">
                  {/* Menu Toggle Button */}
                  <Link to={"/protected/profile"}>
                    <span className="hidden-xs">
                      {user && ` ${user.firstName} ${user.lastName}`}
                    </span>
                  </Link>
                </li>
                {/* Control Sidebar Toggle Button */}

                <li className="dropdown user user-menu">
                  <a href="#/" data-toggle="modal" data-target="#signOutAlert">
                    <span
                      className="fa fa-sign-out"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title={t("headerSection.signOut")}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </header>

        <div className="modal fade" id="signOutAlert">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{t("headerSection.confirmation.message")}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary col-md-2 pull-right"
                  data-dismiss="modal"
                  onClick={e => {
                    e.preventDefault();
                    this.signOut();
                  }}
                  style={{ marginLeft: 10 }}
                >
                  {t("common.ok")}
                </button>

                <button
                  type="button"
                  className="btn btn-default col-md-2 pull-right"
                  data-dismiss="modal"
                >
                  {t("common.cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation("common")(HeaderSection);
