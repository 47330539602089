import React, { Component } from 'react'
const lang = process.env.REACT_APP_LANG;

export class CaseStateModal extends Component {
    render() {
        const { t, isEdit, errors, stateData, caseActions, handleRecords } = this.props.params;
        return (
            <div className="modal fade" id="modal-case-state" ref="modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span></button>
                            <h4 className="modal-title">{isEdit && (t("common.edit")) || (t("common.add"))} {t("portalSettings.caseStates.actions.add.title")}</h4>
                        </div>
                        <div className="modal-body">
                            {isEdit && lang === "en" ?
                                (<p className="info-text">Configure the case state. Provide a Title for the Overview Dashboard, connect the case state to the workflow, select a <a href="https://basscss.com/v7/docs/background-colors/" target="_blank">button style</a> and add case actions.</p>) :
                                (<p className="info-text">En saksstatus er et steg i en saksbehandlingsprosess eller en gruppering av saker. Gi den et Navn, koble den til navnet som statusen har i prosessen, velg en  <a href="https://basscss.com/v7/docs/background-colors/" target="_blank">knappefarge </a> og legg til handlinger.</p>)}
                            {isEdit && (<br />)}
                            <form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className={
                                            errors.name ? "form-group has-error" : "form-group"
                                        }>
                                            <label htmlFor="name">
                                                {t("portalSettings.caseStates.fields.name")}
                                            </label>
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                id="name"
                                                defaultValue={stateData && isEdit && (stateData.name)}
                                                onChange={this.props.handleChange}
                                                placeholder={t(
                                                    "portalSettings.caseStates.placeholder.name"
                                                )}
                                            />
                                            {errors.name && (
                                                <span className="help-block">{errors.name}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="description">
                                                {t("portalSettings.caseStates.fields.description")}
                                            </label>
                                            <input
                                                type="text"
                                                name="description"
                                                className="form-control"
                                                id="description"
                                                defaultValue={stateData && isEdit && (stateData.description)}
                                                onChange={this.props.handleChange}
                                                placeholder={t(
                                                    "portalSettings.caseStates.placeholder.description"
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className={
                                            errors.workflowStateName ? "form-group has-error" : "form-group"
                                        }>
                                            <label htmlFor="workflowStateName">
                                                {t("portalSettings.caseStates.fields.workflowStateName")}
                                            </label>
                                            <input
                                                type="text"
                                                name="workflowStateName"
                                                className="form-control"
                                                id="workflowStateName"
                                                defaultValue={stateData && isEdit && (stateData.workflowStateName)}
                                                onChange={this.props.handleChange}
                                                placeholder={t(
                                                    "portalSettings.caseStates.placeholder.workflowStateName"
                                                )}
                                            />
                                            {errors.workflowStateName && (
                                                <span className="help-block">{errors.workflowStateName}</span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className={
                                            errors.buttonStyle ? "form-group has-error" : "form-group"
                                        }>
                                            <label htmlFor="buttonStyle">
                                                {t("portalSettings.caseStates.fields.buttonStyle")}
                                            </label>
                                            <input
                                                type="text"
                                                name="buttonStyle"
                                                className="form-control"
                                                id="buttonStyle"
                                                defaultValue={stateData && isEdit && (stateData.buttonStyle)}
                                                onChange={this.props.handleChange}
                                                placeholder={t(
                                                    "portalSettings.caseStates.placeholder.buttonStyle"
                                                )}
                                            />
                                            {errors.buttonStyle && (
                                                <span className="help-block">{errors.buttonStyle}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="description">
                                        {t("portalSettings.caseStates.fields.helpText")}
                                    </label>
                                    <textarea
                                        type="text"
                                        name="helpText"
                                        className="form-control"
                                        id="helpText"
                                        defaultValue={stateData && isEdit && (stateData.helpText)}
                                        key={stateData && isEdit && (stateData.helpText)}
                                        onChange={this.props.handleChange}
                                        placeholder={t(
                                            "portalSettings.caseStates.placeholder.helpText"
                                        )}
                                    />
                                </div>

                                <div className="input-group" style={{ marginBottom: 15 }}>
                                    <div className="col-md-12" style={{ paddingLeft: 0 }}>
                                        <label>
                                            {t("portalSettings.caseStates.fields.actions.select")}
                                        </label>
                                        <select
                                            className="form-control"
                                            id="caseAction"
                                            name="caseAction"
                                            onChange={this.props.handleCaseActionChange}>
                                            <option key="0" value="none">{t("portalSettings.caseStates.fields.actions.option.select")}</option>
                                            {caseActions &&
                                                caseActions.map(option => {
                                                    return (
                                                        <option key={option.id} value={option.id}>
                                                            {this.props.generateTitle("portalSettings.caseStates.fields.actions.option", option.title, option.title)} : {option.selectedWorkflowName}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <span
                                        className="input-group-btn"
                                        style={{ paddingTop: 25 }}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-md btn-default"
                                            onClick={this.props.addRecord}
                                        >
                                            <span className="glyphicon glyphicon-plus"></span>
                                        </button>
                                    </span>
                                </div>

                                {handleRecords.length > 0 && (
                                    <div className="form-group" style={{ marginTop: 30 }} >
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>{t("portalSettings.caseStates.fields.actions.table.action")}</th>
                                                    <th>{t("portalSettings.caseStates.fields.actions.table.description")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {handleRecords.length > 0 &&
                                                    handleRecords.sort((a, b) => a.order > b.order ? 1 : -1).map((record, idx) => (
                                                        <tr key={record.name+record.order}>
                                                            <td>{this.props.generateTitle("portalSettings.caseStates.fields.actions.option", record.title, record.title)}</td>
                                                            <td>{record.name}</td>
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-default btn-xs"
                                                                    style={{ marginRight: 10 }}
                                                                    title="Move Up"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        this.props.moveUpCaseActionRecord("case_actions", record);
                                                                    }}
                                                                >
                                                                    <span><i className="fa fa-arrow-up"></i></span>
                                                                </button>

                                                                <button
                                                                    type="button"
                                                                    className="btn btn-default btn-xs"
                                                                    style={{ marginRight: 10 }}
                                                                    title="Move Down"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        this.props.moveDownCaseActionRecord("case_actions", record);
                                                                    }}
                                                                >
                                                                    <span><i className="fa fa-arrow-down"></i></span>
                                                                </button>

                                                                <button
                                                                    type="button"
                                                                    className="btn btn-default btn-xs"
                                                                    onClick={e => {
                                                                        e.preventDefault();
                                                                        this.props.deleteRecord(idx);
                                                                    }}
                                                                >
                                                                    <i className="fa fa-close"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default pull-left"
                                data-dismiss="modal">{t("common.close")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-default"
                                onClick={this.props.handleSave}
                            >{t("common.save")}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CaseStateModal