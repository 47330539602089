import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { NavLink as Link } from "react-router-dom";
import WorkFlowMenuAPI from "../../api/WorkFlowMenuAPI";
import AuthUtil from "../../utils/AuthUtil";
import { withTranslation } from "react-i18next";
import CaseAPI from "../../api/CaseAPI";
import _ from "lodash";
import StateCountUtil from "../../utils/StateCountUtil";
import { CommonContext } from "../../context/CommonContext";

class MainMenu extends Component {
  state = {
    user: this.props.user,
    menue: [],
    t: this.props.t
  };

  static contextType = CommonContext;

  getStateCount(stats, stateName, workflowId) {
    return StateCountUtil.getStateCount(stats, stateName, workflowId);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ user: nextProps.user });
  }



  componentWillMount() {
    WorkFlowMenuAPI.updatedList().then(menueitem => {
      this.setState({ menue: menueitem });
    });

    CaseAPI.getDashboardData(sessionStorage.getItem("userId")).then(data => {
      this.setState({ stats: data });
    });

    CaseAPI.getMyCaseData(sessionStorage.getItem("userId")).then(data => {
      this.setState({ myStats: data });
    })
  }

  componentDidUpdate() {
    if (this.context.sideMenuUpdate === true) {
      this.componentWillMount();
      this.context.handleSideMenuUpdate(false);
    }
  }

  getUrlParams = search => {
    let hashes = search.slice(search.indexOf("?") + 1).split("&");
    return hashes.reduce((params, hash) => {
      let [key, val] = hash.split("=");
      return Object.assign(params, { [key]: decodeURIComponent(val) });
    }, {});
  };

  getMenuDataByClick = () => {
    CaseAPI.getDashboardData(sessionStorage.getItem("userId")).then(data => {
      this.setState({ stats: data });
    });
  };

  generateTitle = title => {
    const { t } = this.state;
    var translation = t("caseManagement.viewDetails.stateType." + title);
    if (translation.includes("caseManagement.viewDetails.stateType")) {
      return title;
    } else {
      return translation;
    }
  }

  displayMyCases = (item) => {
    const { location } = this.props;
    const searchParams = this.getUrlParams(location.search);
    const { myStats,t } = this.state;

    let myCaseActions, myCaseTitle, caseStateId, helpText;
    let myCaseStateAvailability = false;
    item.states.filter(aItem => {
      caseStateId = aItem.id;
      aItem.workflowStateName === "My_Cases" ? myCaseActions = aItem.actions : myCaseActions = [];
      aItem.workflowStateName === "My_Cases" ? myCaseTitle = aItem.name : myCaseTitle = t("portalSettings.caseStates.fields.myCases");
      aItem.workflowStateName === "My_Cases" ? myCaseStateAvailability = true : myCaseStateAvailability = false;
    })
    return (
      myCaseStateAvailability && <li
        className={
          location.pathname.includes(item.workflowId) &&
            searchParams.type === "My_Cases"
            ? "active"
            : ""
        }
      >
        <Link
          to={{
            pathname: "/protected/cases/" + item.workflowId,
            search: "?type=" + "My_Cases",
            state: {
              workflowId: item.workflowId,
              workflowIndex: item.id,
              workFlowName: item.name,
              exerciseId: item.exerciseId,
              caseState: "My_Cases",
              actions: myCaseActions,
              caseStateTitle:myCaseTitle,
              caseStateId: caseStateId,
              securePublishing : item.securePublishing
            }
          }}
          onClick={() => this.getMenuDataByClick()}
        >
          <i className="fa fa-circle-o" />
          {myCaseTitle}
          <span className="pull-right-container">
            <span className="label label-warning pull-right custom-counter-label">
              {this.getStateCount(
                myStats,
                "My_Cases",
                item.workflowId
              )}
            </span>
          </span>
        </Link>
      </li>
    )
  }

  render() {
    const { user, menue, t, stats, myStats } = this.state;
    const { location } = this.props;
    const searchParams = this.getUrlParams(location.search);
    return (
      <Fragment>
        <ul className="sidebar-menu" data-widget="tree">
          <li className="header" />
          <li
            className={`${location.pathname === "/protected" ? "active" : ""}`}
          >
            <Link to="/protected">
              <i className="fa fa-dashboard" /> <span>{t("dashboard.sectionHeader")}</span>
            </Link>
          </li>
          {user &&
            menue.map(item => (
              <li
                key={item.workflowId + '_' + item.id}
                className={`treeview ${location.pathname === "/protected/cases/" + item.workflowId
                    ? "active"
                    : ""
                  }`}
              >
                <a href="#/">
                  <i className={item.icon} /> <span>{item.name}</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-left pull-right" />
                  </span>
                </a>
                <ul className="treeview-menu">
                  {item.states.sort((a, b) => a.order > b.order ? 1 : -1).filter(aItem => aItem.workflowStateName !== "My_Cases").map(aItem => (
                    <li
                      key={aItem.workflowStateName + '_' + aItem.id}
                      className={
                        location.pathname.includes(item.workflowId) &&
                          searchParams.type === aItem.workflowStateName
                          ? "active"
                          : ""
                      }
                    >
                      <Link
                        to={{
                          pathname: "/protected/cases/" + item.workflowId,
                          search: "?type=" + aItem.workflowStateName,
                          state: {
                            workflowId: item.workflowId,
                            workflowIndex: item.id,
                            workFlowName: item.name,
                            exerciseId: item.exerciseId,
                            caseState: aItem.workflowStateName,
                            actions: aItem.actions,
                            caseStateTitle:aItem.name,
                            caseStateId: aItem.id,
                            securePublishing : item.securePublishing
                          }
                        }}
                        onClick={() => this.getMenuDataByClick()}
                      >
                        <i className="fa fa-circle-o" />
                        {aItem.name}
                        <span className="pull-right-container">
                          <span className="label label-warning pull-right custom-counter-label">
                            {this.getStateCount(
                              stats,
                              aItem.workflowStateName,
                              item.workflowId
                            )}
                          </span>
                        </span>
                      </Link>
                    </li>
                  ))}
                  {this.displayMyCases(item)}
                </ul>
              </li>
            ))}
          <li
            className={`${location.pathname === "/protected/profile" ? "active" : ""
              }`}
          >
            <Link to="/protected/profile">
              <i className="fa fa-user" />{" "}
              <span>{t("mainMenu.userProfile")}</span>
            </Link>
          </li>
          {user && AuthUtil.hasSectionAccess(user, ["U_VIEW_USER","U_ADD_USER"]) && (
            <>
              <li
                className={`treeview ${location.pathname.includes("/protected/users")
                    ? "active menu-open"
                    : ""
                  }`}
              >
                <a href="#/">
                  <i className="fa fa-users" />
                  <span>{t("mainMenu.userManagement")}</span>
                  <span className="pull-right-container" />
                </a>
                <ul className="treeview-menu">
                  {AuthUtil.hasSectionAccess(user, ["U_VIEW_USER"]) && (
                      <li
                          className={`${searchParams.action === "view" ? "active" : ""
                          }`}
                      >
                        <Link
                            to={{
                              pathname: "/protected/users",
                              search: "?action=view",
                              state: { state: "view" }
                            }}
                        >
                          <i className="fa fa-circle-o" />
                          {t("mainMenu.viewUsers")}
                        </Link>
                      </li>
                  )}

                  {AuthUtil.hasSectionAccess(user, ["U_ADD_USER"]) && (
                    <li
                      className={`${searchParams.action === "add" ? "active" : ""
                        }`}
                    >
                      <Link
                        to={{
                          pathname: "/protected/users",
                          search: "?action=add",
                          state: { state: "add" }
                        }}
                      >
                        <i className="fa fa-circle-o" />
                        {t("mainMenu.addUsers")}
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            </>
          )}

          {user && AuthUtil.hasSectionAccess(user, ["PS_OVERVIEW", "PS_WORKFLOW","PS_CASE_STATE","PS_CASE_ACTION","PS_GROUP_ROLE","PS_SUPER_ADMIN"]) && (
            <>
              <li
                className={`treeview ${location.pathname.includes("/protected/settings")
                    ? "active menu-open"
                    : ""
                  }`}
              >
                <a href="#/">
                  <i className="fa fa-cogs" />
                  <span>{t("mainMenu.portalSettings")}</span>
                  <span className="pull-right-container" />
                </a>
                <ul className="treeview-menu">
                  {AuthUtil.hasSectionAccess(user, ["PS_OVERVIEW"]) && (
                    <li
                      className={`${(location.pathname.includes("/protected/settings/overview") &&
                          searchParams.action === "load") ? "active" : ""
                        }`}
                    >
                      <Link
                        to={{
                          pathname: "/protected/settings/overview",
                          search: "?action=load",
                          state: { state: "view" }
                        }}
                      >
                        <i className="fa fa-circle-o" />
                        {t("mainMenu.settingOverview")}
                      </Link>
                    </li>
                  )}

                  {AuthUtil.hasSectionAccess(user, ["PS_WORKFLOW"]) && (
                    <li
                      className={`${(location.pathname.includes("/protected/settings/workflows") &&
                          searchParams.action === "view") ? "active" : ""
                        }`}
                    >
                      <Link
                        to={{
                          pathname: "/protected/settings/workflows",
                          search: "?action=view",
                          state: { state: "view" }
                        }}
                      >
                        <i className="fa fa-circle-o" />
                        {t("mainMenu.workflows")}
                      </Link>
                    </li>
                  )}

                  {AuthUtil.hasSectionAccess(user, ["PS_CASE_STATE"]) && (
                    <li
                      className={`${(location.pathname.includes("/protected/settings/states") &&
                          searchParams.action === "view") ? "active" : ""
                        }`}
                    >
                      <Link
                        to={{
                          pathname: "/protected/settings/states",
                          search: "?action=view",
                          state: { state: "view" }
                        }}
                      >
                        <i className="fa fa-circle-o" />
                        {t("mainMenu.caseStates")}
                      </Link>
                    </li>
                  )}

                  {AuthUtil.hasSectionAccess(user, ["PS_CASE_ACTION"]) && (
                    <li
                      className={`${(location.pathname.includes("/protected/settings/stateActions") &&
                          searchParams.action === "view") ? "active" : ""
                        }`}
                    >
                      <Link
                        to={{
                          pathname: "/protected/settings/stateActions",
                          search: "?action=view",
                          state: { state: "view" }
                        }}
                      >
                        <i className="fa fa-circle-o" />
                        {t("mainMenu.caseStatesAction")}
                      </Link>
                    </li>
                  )}

                  {/*AuthUtil.hasRole(user, ["PORTAL_ADMIN"]) && (
                    <li
                      className={`${(location.pathname.includes("/protected/settings/roles") &&
                          searchParams.action === "view") ? "active" : ""
                        }`}
                    >
                      <Link
                        to={{
                          pathname: "/protected/settings/roles",
                          search: "?action=view",
                          state: { state: "view" }
                        }}
                      >
                        <i className="fa fa-circle-o" />
                        {t("mainMenu.userRoles")}
                      </Link>
                    </li>
                      )*/}
                  {AuthUtil.hasSectionAccess(user, ["PS_GROUP_ROLE"]) && (
                    <li
                      className={`${(location.pathname.includes("/protected/settings/userGroups") &&
                          searchParams.action === "view") ? "active" : ""
                        }`}
                    >
                      <Link
                        to={{
                          pathname: "/protected/settings/userGroups",
                          search: "?action=view",
                          state: { state: "view" }
                        }}
                      >
                        <i className="fa fa-circle-o" />
                        {t("mainMenu.userGroupsAndRoles")}
                      </Link>
                    </li>
                  )}

                  {AuthUtil.hasRole(user, ["PORTAL_ADMIN"]) && (
                      <li
                          className={`${(location.pathname.includes("/protected/settings/superAdminSettings") &&
                              searchParams.action === "view") ? "active" : ""
                          }`}
                      >
                        <Link
                            to={{
                              pathname: "/protected/settings/superAdminSettings",
                              search: "?action=view",
                              state: { state: "view" }
                            }}
                        >
                          <i className="fa fa-circle-o" />
                          {t("mainMenu.superAdminSettings")}
                        </Link>
                      </li>
                  )}
                </ul>
              </li>
            </>
          )}
        </ul>
      </Fragment>
    );
  }
}

export default withRouter(withTranslation("common")(MainMenu));
