import axios from "axios";
import { handleResponse, handleError } from "../services/serviceUtil";
import ApiService from "../auth/ApiService";
import _ from "lodash";

const apiEndpoint = process.env.REACT_APP_CMP_API_URL;
const resourceUrl = apiEndpoint + "/secured/user";

const UserAPI = {
  userData: {
    columns: [
      { title: "Personnr.", field: "uniqueId", width: 150, headerFilter: "input"},
      { title: "Fornavn", field: "firstName", headerFilter: "input"},
      { title: "Etternavn", field: "lastName", headerFilter: "input"},
      { title: "Telefonnr.", field: "contactNumber", width: 150, headerFilter: "input"},
      { title: "E-post", field: "email", width: 175, headerFilter: "input"},
      {
        title: "Aktiv",
        field: "active",
        align: "center",
        formatter: "tickCross",
        width: 75,
        formatterParams: {
          allowEmpty: false,
          allowTruthy: true,
          tickElement: "<i class='fa fa-check' style='color:#0ad6a3'></i>",
          crossElement: "<i class='fa fa-times' style='color:#ff474c'></i>"
        }
      }
    ],
    data: []
  },

  all: async function(t) {
    let data = await new ApiService().callApi(`${resourceUrl}/summary/all`, "get");
    this.userData.data = data.data;

    function getFunction(column) {

      if(column.headerFilter){
        var placeholder = t("userManagement.viewUsers.search.placeholder");
        column.headerFilterPlaceholder = placeholder;
      }
    }
    _.forEach(this.userData.columns, getFunction);
    return this.userData;
  },
  save: async function(user) {
    let data = await new ApiService().callApi(`${resourceUrl}`, "post", user);
    return data.data;
  },
  getUser: async function(uniqueId) {
    return new ApiService()
      .callApi(`${resourceUrl}/${uniqueId}`, "get")
      .then(handleResponse)
      .catch(handleError);
  },
  getProfile: async function(uniqueId) {
    return new ApiService()
      .callApi(`${resourceUrl}/profile`, "get")
      .then(handleResponse)
      .catch(handleError);
  },
  updateUser: function(user) {
    return new ApiService()
      .callApi(`${resourceUrl}`, "put", user)
      .then(handleResponse)
      .catch(handleError);
  },
  updateProfile: function(user) {
    return new ApiService()
      .callApi(`${resourceUrl}/profile`, "put", user)
      .then(handleResponse)
      .catch(handleError);
  },
  deleteUser: function(uniqueId) {
    return new ApiService()
      .callApi(`${resourceUrl}/${uniqueId}`, "delete")
      .then(handleResponse)
      .catch(handleError);
  },
  isFirstUser: function(uniqueId) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Basic cG9ydGFsdXNlcjpjbXBwYXNzd29yZA=="
    };

    return axios
      .get(`${apiEndpoint}/public/firstuser`, {
        params: {
          uniqueId: uniqueId
        },
        headers
      })
      .then(handleResponse)
      .catch(handleError);
  },
  allSummarizedUsers: async function(param) {
    let data;
    if (!param)
      data = await new ApiService().callApi(`${resourceUrl}/summary/all`, "get");
    else
      data = await new ApiService().callApi(`${resourceUrl}/summary/all?caseLock=${param}`, "get");

    this.userData.data = data.data;
    return this.userData;
   },
  getUsersByWorkflowId: async function(workflowId) {
    let data = await new ApiService().callApi(`${resourceUrl}/workflow/${workflowId}`, "get");
    this.userData.data = data.data;
    return this.userData;
  }
};

export default UserAPI;
