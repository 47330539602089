import ApiService from "../auth/ApiService";
import { handleResponse, handleError } from "../services/serviceUtil";
const $ = window.$;
const apiEndpoint = process.env.REACT_APP_CMP_API_URL;
const resourceUrl = apiEndpoint + "/secured/ui";

const DesignAPI = {
    getAllSkins: async function() {
        return await new ApiService()
            .callApi(resourceUrl+'/skins/all', "get")
            .then(handleResponse)
            .catch(handleError);
    },

    getCustomTitle: async function() {
        return await new ApiService().callApi(resourceUrl+ `/custom/name`, "get")
            .then(handleResponse)
            .catch(handleError);
    },

    uploadSkins: async function(file) {
        return new ApiService().callApi(process.env.REACT_APP_CMP_API_URL+'/secured/ui/skins/upload',
            "post", file, this.getFormDataHeaders())
            .then(handleResponse)
            .catch(handleError);
    },

    changeSkin : async function(file) {
        return new ApiService().callApi(process.env.REACT_APP_CMP_API_URL+'/secured/ui/skins/change',
            "post", file, this.getFormDataHeaders())
            .then(handleResponse)
            .catch(handleError);
    },

    removeSkin : async function(file) {
        return new ApiService().callApi(process.env.REACT_APP_CMP_API_URL+'/secured/ui/skins/remove',
            "post", file, this.getFormDataHeaders())
            .then(handleResponse)
            .catch(handleError);
    },

    updateCustomTitle: async function(name) {
        let data ={
            name:name
        }
        return new ApiService().callApi(resourceUrl+ "/custom/name", "post", data)
            .then(handleResponse)
            .catch(handleError);
    },

    getFormDataHeaders: () => {
        return  {
            "Content-Type": "multipart/form-data",
            Accept: "*/*"
        };
    }
};

export default DesignAPI;