import React, { Component } from 'react'
const $ = window.$;

export class CaseHighlightingConfigModal extends Component {

    closeConfigModal = () => {
        $(function () {
            $('#caseHighlightingAddModal').modal('hide');
            $(".modal-backdrop").last().remove();
        });
        this.props.resetCaseHighlightConfig();
    }

    render() {
        const { t, attributesSorted, showHighlightingModel, caseHighlightConfigs, caseHighlightConfig } = this.props.params;
        let conditionOptions=[
            {"key":"eq", "title": "equals"},
            {"key":"contains", "title": "contains"}
        ]; 

        const nonStringConditionOptions=[
            {"key":"eq", "title": "equals"},
            {"key":"neq", "title": "not equals"},
            {"key":"greaterthan", "title": "greater than"},
            {"key":"lessthan", "title": "less than"}
        ]; 

        const styles=[
            {"key":"Critical", "title": "Critical"},
            {"key":"Warning", "title": "Warning"},
            {"key":"Info", "title": "Info"}
        ];
        return (
            <>
            {showHighlightingModel &&
            <div className="modal fade" id="modal-case-highlighting" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">{t("portalSettings.workflows.actions.highlight.title")}</h4>
                        </div>
                        <div className="modal-body">
                            <p className="info-text">{t("portalSettings.workflows.actions.highlight.message")}</p>
                            {caseHighlightConfigs.length > 0 &&
                                <div className="row" id="highlight-headers">
                                    <div className="col-md-3">
                                        {t("portalSettings.workflows.actions.highlight.tableHeader.title")}
                                    </div>
                                    <div className="col-md-2">
                                        {t("portalSettings.workflows.actions.highlight.tableHeader.style")}
                                    </div>
                                    <div className="col-md-5">
                                        {t("portalSettings.workflows.actions.highlight.tableHeader.message")}
                                    </div>
                                    <div className="col-md-1">
                                        &nbsp;
                                    </div>
                                    <div className="col-md-1">
                                        &nbsp;
                                    </div>
                                </div>
                            }
                            {
                                caseHighlightConfigs.map((data, index) => {
                                    const {title, style, message}= data;
                                    return (
                                        <div className="row" id="highlight-row" key={index}>
                                            <div className="col-md-3">
                                                {title}
                                            </div>
                                            <div className="col-md-2">
                                                {style}
                                            </div>
                                            <div className="col-md-5">
                                                {message}
                                            </div>
                                            <div className="col-md-1" style={{marginLeft:-7+'px'}}>
                                                <button
                                                    type="button"
                                                    className="btn"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.props.editCaseHighlightConfigRow(index);
                                                    }}
                                                >
                                                    <span><i className="fa fa-edit"></i></span>
                                                </button>
                                            </div>
                                            <div className="col-md-1" style={{marginLeft:-7+'px'}}>
                                                <button
                                                    type="button"
                                                    className="btn"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        this.props.deleteCaseHighlightConfigRow(index);
                                                    }}
                                                >
                                                    <span><i className="fa fa-trash-o"></i></span>
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className='row' id="add-highlight">
                                <div className="col-md-12" style={{}}>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={e => {
                                            e.preventDefault();
                                            this.props.openCaseHighlightConfigModal();
                                        }}
                                    >
                                        {t("portalSettings.workflows.actions.highlight.addHighlight")}&nbsp;
                                        <span><i className="fa fa-plus-circle"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary col-md-2 pull-right"
                                style={{ marginLeft: 10 }}
                                data-dismiss="modal"
                                onClick={e => {
                                    e.preventDefault();
                                    this.props.handleCaseHighlightConfigSave();
                                }}
                            >
                                {t("common.save")}
                            </button>

                            <button
                                type="button"
                                className="btn btn-default col-md-2 pull-right"
                                data-dismiss="modal"
                            >
                                {t("common.close")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }
            {caseHighlightConfig && 
            <div className="modal fade" id="caseHighlightingAddModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                onClick={e => {
                                    e.preventDefault();
                                    this.closeConfigModal();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">{t("portalSettings.workflows.actions.highlight.title2")}</h4>
                        </div>
                        <div className="modal-body">
                            <h4 className="section-header">{t("portalSettings.workflows.actions.highlight.subTitle1")}</h4>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="title">
                                            {t("portalSettings.workflows.actions.highlight.fields.title")}
                                        </label>
                                        <input
                                            type="text"
                                            name="title"
                                            className="form-control"
                                            id="title"
                                            defaultValue={caseHighlightConfig.title}
                                            onChange={e => {this.props.handleCaseHighlightConfigChange(e) }}
                                            placeholder={t(
                                                "portalSettings.workflows.actions.highlight.placeholder.title"
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="style">
                                            {t("portalSettings.workflows.actions.highlight.fields.style")}
                                        </label>
                                        <select className="form-control"  defaultValue={caseHighlightConfig.style} onChange={(evnt)=>(this.props.handleCaseHighlightConfigChange(evnt))} 
                                            name="style">
                                            {styles.map(option => {
                                                return (
                                                    <option key={option.key} value={option.key}>
                                                        {option.title}
                                                    </option>
                                                )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label htmlFor="message">
                                            {t("portalSettings.workflows.actions.highlight.fields.message")}
                                        </label>
                                        <textarea
                                            name="message"
                                            className="form-control"
                                            id="message"
                                            defaultValue={caseHighlightConfig.message}
                                            onChange={e => {this.props.handleCaseHighlightConfigChange(e) }}
                                            placeholder={t(
                                                "portalSettings.workflows.actions.highlight.placeholder.message"
                                            )}
                                            rows={2}
                                            cols={40}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>    
                            <h4 className="section-header">{t("portalSettings.workflows.actions.highlight.subTitle2")}</h4>
                            <div className="row">
                                <div className="col-md-1" style={{marginTop:7+'px'}}>
                                    <button
                                        type="button"
                                        className="btn"
                                        onClick={e => {
                                            e.preventDefault();
                                            this.props.addCaseHighlightConditionRow();
                                        }}
                                    >
                                        <span><i className="fa fa-plus"></i></span>
                                    </button>
                                </div>
                                <div className="col-md-11">
                                    {caseHighlightConfig.highlightConditions.sort((a, b) => a.position > b.position ? 1 : -1).map((data, index) => {
                                        const {metaData, method, value, condition, type }= data;
                                        conditionOptions = type === "STRING" ? conditionOptions : nonStringConditionOptions;
                                        return(
                                            <div key={index}>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group" style={{marginLeft:-8+'px'}}>
                                                            <select className="form-control" value={metaData} onChange={(e)=>(this.props.handleCaseHighlightConditionChange(index, e))} name="metaData">
                                                                <option value="" key='0'>{t("portalSettings.workflows.actions.highlight.fields.option")}</option>
                                                                {attributesSorted &&
                                                                    attributesSorted.map(option => {
                                                                        return (
                                                                            <option key={option.id} value={option.attribute} data-valuetype={option.type}>
                                                                                {option.attributeName}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group" style={{marginLeft:-25+'px'}}>
                                                            <select className="form-control"  defaultValue={method} onChange={(evnt)=>(this.props.handleCaseHighlightConditionChange(index, evnt))} name="method">
                                                                {conditionOptions.map(option => {
                                                                    return (
                                                                        <option key={option.key} value={option.key}>
                                                                            {option.title}
                                                                        </option>
                                                                    )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group" style={{marginLeft:-25+'px'}}>
                                                            <input type="text" className="form-control" value={value} onChange={(evnt)=>(this.props.handleCaseHighlightConditionChange(index, evnt))} name="value"></input>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1" style={{marginLeft:-25+'px', marginTop:7+'px'}}>
                                                        <button
                                                            type="button"
                                                            className="btn"
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.props.deleteCaseHighlightConditionRow(index);
                                                            }}
                                                        >
                                                            <span><i className="fa fa-close"></i></span>
                                                        </button>
                                                    </div>
                                                </div>
                                                {condition !== "" &&
                                                <div className='row'>
                                                    <div className="col-md-11 text-center">
                                                        <button type="button" className="btn btn-default condition-button" style={{marginBottom:10+'px'}}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                this.props.toggleCondition(index);
                                                                }}
                                                            >
                                                            {condition}
                                                        </button>
                                                    </div>
                                                </div>
                                                }
                                            </div>    
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary col-md-2 pull-right"
                                onClick={e => {
                                    e.preventDefault();
                                    this.props.addCaseHighlightConfigRow();
                                    this.closeConfigModal();
                                }}
                                style={{ marginLeft: 10 }}
                            >
                                {t("common.save")}
                            </button>

                            <button
                                type="button"
                                className="btn btn-default col-md-2 pull-right"
                                onClick={e => {
                                    e.preventDefault();
                                    this.closeConfigModal();
                                }}
                            >
                                {t("common.close")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }
            </>
        )
    }
}

export default CaseHighlightingConfigModal