import ApiService from "../auth/ApiService";

const apiEndpoint = process.env.REACT_APP_CMP_API_URL;
const resourceUrl = apiEndpoint + "/secured/case-lock";

const CaseActionAPI = {
    lockUnlockUpdater: async function(caseLockDto) {
        let data = await new ApiService().callApi(`${resourceUrl}`, "post", caseLockDto);
        return data.data;
    }
};

export default CaseActionAPI;