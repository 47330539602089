import React, { Component } from "react";
import AuthUtil from "../utils/AuthUtil";
import ConfigAPI from "../api/ConfigAPI";
import LoginPageHeaderSection from "../sections/LoginPageHeaderSection";
import LoginPageFooterSection from "../sections/LoginPageFooterSection";
import properties from '../version.properties'
import './LoginPage.css'
import {withTranslation} from "react-i18next";
import i18n from "i18next";
import ToggleSwitch from './ToggleSwitch';

class LoginPage extends Component {
  state = {
    locale: null,
    loginConfig:null,
    t: this.props.t,
    isToggled: (process.env.REACT_APP_UI_LOCALES === "en") ? false : true
  };

  componentWillMount() {
    ConfigAPI.getLocale(process.env.REACT_APP_UI_LOCALES).then(data => {
      this.setState({
        locale: data.loginPage,
      });
    });

    ConfigAPI.getConfig().then(data => {
      let { t } = this.state;
      this.setState({
        loginConfig: data.loginPage,
      });      
    });
    this.selectLanguage(this.state.isToggled);
  }

  onLoginButtonClick(event) {
    event.preventDefault();
    AuthUtil.login();
  }

  getYear() {
    return new Date().getFullYear();
  }

  languageChangeHandler = (event, lang) => {
    i18n.changeLanguage(lang);
  }

  handleToggle = () => {
    this.setState(prevState => ({
      isToggled: !prevState.isToggled
    }));

    let langState = false;
    if (this.state.isToggled == true) {
      langState = false;
    } else {
      langState = true;
    }

    this.selectLanguage(langState);
  };

  selectLanguage(toggleState) {
    if (toggleState == true) {
      this.languageChangeHandler("", 'no');
    } else {
      this.languageChangeHandler("", 'en');
    }
  }

  render() {
    let { locale, loginConfig, t } = this.state;
    document.title =loginConfig && t('loginPage.companyName');
    return (
      <div className="login-container">
        <div className="login-image">
          <LoginPageHeaderSection />
          <div className="login-page-ccm-title-div">
            <div className="login-page-login-ccm-title">
              {t('loginPage.loginCCMTitle')}
              <div>
              <a
                href="https://www.usecompose.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="hidden-xs login-hidden-xs">
                  www.usecompose.com
                </span>
              </a>
            </div>

            </div>

          </div>
        </div>
      <div className="login-panel">
        {/* <LoginPageHeaderSection /> */}

        <div className='row'>
            <div className='lang-div col-xs-12'>
              <ToggleSwitch 
                  isToggled={this.state.isToggled}
                  onToggle={this.handleToggle} 
              />
            </div>
          </div>

        <div className="lockscreen-wrapper">
          <div className="lockscreen-logo login-lockscreen-logo">
            <img
              src={loginConfig && "img/" + loginConfig.logoUrl}
              className="login-company-logo"
              alt="company logo"
            />
            <br />

            <div className="login-title-div">
              {t('loginPage.title')}
            </div>
            <br />
            <div className="login-page-title login-page-title-text">
              {t('loginPage.loginTitleText')}
            </div>
          </div>
          <div className="lockscreen-item">
            <div className="login-row row">
              <div className="col-xs-1"></div>
              <div className="col-xs-12">
                <button
                  type="submit"
                  onClick={this.onLoginButtonClick}
                  className="btn btn-block btn-login"
                >
                  {t('loginPage.button')}
                </button>
                {}
              </div>
              <div className="col-xs-1"></div>
            </div>

              <div class="separator-container">
                <div class="separator"></div>
                <div class="separator-text">{t('loginPage.orText')}</div>
                <div class="separator"></div>
              </div>

              <div className="try-compose-text">
              {t('loginPage.tryComposeText')}  
              <a
                  href="https://usecompose.com/contact/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="">
                  {t('loginPage.tryComposeLink')} 
                  </span>
                </a> 
              </div>
          </div>

          <div className='row'>
            <div className="app-version-div">
              <h5 className="login-page-app-version">Version : {properties.appVersion}</h5>
            </div>
          </div>
        </div>

        <div className="login-footer-div"><LoginPageFooterSection locale={locale}/></div>
        
      </div>
      </div>
    );
  }
}

export default withTranslation("common")(LoginPage);
