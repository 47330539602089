import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import UserAPI from "../../api/UserAPI";
import RoleAPI from "../../api/RoleAPI";
import WorkflowAPI from "../../api/WorkflowAPI";
import PortalUser from "../../dto/PortalUser";
import { withTranslation } from "react-i18next";
import CaseExpression from "../../components/shared/CaseExpression";
import _ from "lodash";
import UserGroupAPI from "../../api/UserGroupAPI";
const $ = window.$;

class AddUser extends Component {
  state = {
    user: new PortalUser(),
    roles: [],
    exercises: [],
    errors: {},
    redirect: false,
    isEdit:false,
    action: this.props.params.state,
    t: this.props.t,
    records: [],
    workflows: null,
    role: null,
    editableRecordIndex:null,
    exerciseIdList: [],
    userGroupsList : [],
    selectWorkflow: "",
    workflowAttributes: [],
    caseAccessExpressions: [],
    autoSuggestedAttributeValues: [
        {
          workflowName:["Tipsl?sning (CCM)","Whistle - Demo","Demo_wf", "CCM connection"],
          stateName:["Submitted","Rejected","Approved","Under_work"],
          applicantName:["Anonymous"]
        }
    ],
     workflowDetailsVisibility:false,
    oldCondition:"",
    selectedValue:"",
    selectedType:"",
    isSegmented:null
  };

  componentDidMount() {
    if (this.state.action === "edit") {
      this.loadUser(this.props.params.userId);
    } else {
      this.setState({ user: new PortalUser() });
    }
    this.loadRoles();
    this.loadWorkflows();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.action !== nextProps.params.state) {
      this.setState({ user: new PortalUser() });
    }
    this.setState({ action: nextProps.params.state });
  }

  loadUser = uniqueId => {
    UserAPI.getUser(uniqueId)
      .then(user => {
        if (user.caseExpressions.length != 0) {
          _.forEach(user.caseExpressions, function (obj) {
            if (obj.type.toUpperCase() === 'DATE') {
              obj.value = new Date(obj.value);
            }
          });
        }
        let userDto = user;

        let finalRecordsList = [];
        _.forEach(user.workflowList, n => {
          let record = {};

          record["selectWorkflow"] = n["workflow"].name;
          record["role"] = n["role"].name;
          let exercises = [];
          let groups = [];

          n["exercises"].forEach(item => {
            exercises.push(item.code);
          });

          n["userGroups"].forEach(item => {
            groups.push(item.groupTitle);
          });

          record["exerciseIdList"] = exercises;
          record["userGroupList"] = groups;

          finalRecordsList.push(record);
        });

        this.setState({ user: userDto, records: finalRecordsList });
      })
      .catch(error => {
        throw error;
      });
  };

  loadUserGroups = (workflowId) => {
    UserGroupAPI.all().then(data => {
      let usergroups = data.data;
      usergroups = usergroups.filter(group => workflowId === group.workflow.workflowId);
      this.setState({ userGroupsList: usergroups });
    });
  }

  loadRoles = () => {
    RoleAPI.getRoles()
      .then(roles => {
        this.setState({
          roles: roles,
          role: roles[0].name
        });
      })
      .catch(error => {
        throw error;
      });
  };

  loadWorkflows = () => {
    WorkflowAPI.getWorkflows()
      .then(workflows => {
        this.setState({
          workflows: workflows,
          selectWorkflow: workflows[0].workflowId,
          exercises: workflows[0].exercise
        });
        this.loadUserGroups(workflows[0].workflowId);
      })
      .catch(error => {
        throw error;
      });
  };

  validateUniqueIdField(alias, value) {
    switch (alias) {
      case "pid":
        return value.length !== 11 || value.match(/^[0-9]+$/) === null;
      default:
        return value.length > 36;
    }
  }

  isFormValid = () => {
    let {
      uniqueId,
      firstName,
      lastName,
      email,
      contactNumber,
      status
    } = this.state.user;
    const { t, records } = this.state;

    const errors = {};
    if (!uniqueId)
      errors.uniqueId = t(
        "userManagement.addUser.validationError.UNIQUE_ID_REQUIRED"
      );

    if (
      uniqueId &&
      this.validateUniqueIdField(
        process.env.REACT_APP_UNIQUE_ID_ALIAS,
        uniqueId
      )
    )
      errors.uniqueId = t(
        "userManagement.addUser.validationError.UNIQUE_ID_INVALID"
      );
    if (!firstName)
      errors.firstName = t(
        "userManagement.addUser.validationError.FIRST_NAME_REQUIRED"
      );
    if (!lastName)
      errors.lastName = t(
        "userManagement.addUser.validationError.LAST_NAME_REQUIRED"
      );
    if (!email)
      errors.email = t("userManagement.addUser.validationError.EMAIL_REQUIRED");
    if (!/^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/.test(email)) {
      errors.email = t("userManagement.addUser.validationError.EMAIL_INVALID");
    }
    if (!contactNumber)
      errors.contactNumber = t(
        "userManagement.addUser.validationError.CONTACT_NO_REQUIRED"
      );
    if (!/^[0-9]+$/.test(contactNumber)) {
      errors.contactNumber = t(
        "userManagement.addUser.validationError.CONTACT_NO_INVALID"
      );
    }
    if (!status)
      errors.status = t(
        "userManagement.addUser.validationError.STATUS_REQUIRED"
      );
    if (records.length === 0)
      errors.workflow = t(
        "userManagement.addUser.validationError.WORKFLOW_REQUIRED"
      );

    this.setState({
      errors: errors
    });
    return Object.keys(errors).length === 0;
  };

  handleSave = event => {
    let {
      uniqueId,
      firstName,
      lastName,
      email,
      contactNumber,
      status
    } = this.state.user;

    event.preventDefault();
    if (!this.isFormValid()) return;

    let portalUserObj = new PortalUser(
      uniqueId,
      firstName,
      lastName,
      email,
      status,
      contactNumber
    );

    let { records, workflows, caseAccessExpressions, userGroupsList, t } = this.state;

    let updatedRecords = records.map(record => {
      if (record.userGroupList) {
        let updatedUserGroupList = record.userGroupList.map(groupTitle => {
          let matchedGroup = userGroupsList.find(group => group.groupTitle === groupTitle);
          if (matchedGroup) {
            return matchedGroup.id;
          }
          return null;
        });
        return {
          ...record,
          userGroupList: updatedUserGroupList
        };
      }
      return record;
    });

    records = updatedRecords;

    let finalWorkFlowList = [];
    _.forEach(records, n => {
      let workflowName = n["selectWorkflow"];
      let selectedWorkflow = _.find(workflows, { name: workflowName });

      let finalExerciseIdList = [];
      let finalUserGroupList = [];

      n["exerciseIdList"].forEach(item => {
        var exerciseIdArray = {};
        exerciseIdArray["code"] = item;
        finalExerciseIdList.push(exerciseIdArray);
      });

      n["userGroupList"] && n["userGroupList"].forEach(item => {
        var userGroupArray = {};
        userGroupArray["id"] = item;
        finalUserGroupList.push(userGroupArray);
      });

      let workflowObj = {
        workflow: { workflowId: selectedWorkflow.workflowId,  states: selectedWorkflow.states},
        role: { name: n["role"] },
        exercises: finalExerciseIdList,
        userGroups: finalUserGroupList
      };
      finalWorkFlowList.push(workflowObj);
    });

    portalUserObj.setworkflows(finalWorkFlowList);
    portalUserObj.setCaseExpressions(caseAccessExpressions);

    if (this.props.params.state === "edit") {
      UserAPI.updateUser(portalUserObj)
        .then(() => {
          $.notification("success", t("common.notification.success"), t("userManagement.notification.UPDATED"));
          this.setState({ redirect: true });
        })
        .catch(error => {console.log(error)});
    } else {
      UserAPI.save(portalUserObj)
        .then(() => {
          $.notification("success", t("common.notification.success"), t("userManagement.notification.CREATED"));
          this.setState({ redirect: true });
        })
        .catch(error => {});
    }
  };

  handleChange = event => {
    let { name, value } = event.target;
    if (event.target.options) {
      value = [...event.target.selectedOptions].map(option => option.value);
    }
    if (name === "status") {
      value = event.target.checked ? "ACTIVE" : "INACTIVE";
    }
    this.setState({
      user: {
        ...this.state.user,
        [event.target.name]: value
      }
    });
  };

  handleCancel = () => {
    this.setState({ redirect: true, user: new PortalUser() });
  };

  handleWorkflowChange = event => {
    let { name, value } = event.target;
    const { workflows, caseAccessExpressions, user } = this.state;

    if (name === "workflow") {
      let selectedWf = _.find(workflows, { name: value });
      this.loadUserGroups(selectedWf.workflowId);

      let selectedCaseAccessExpressions = []

      if(user.caseExpressions!=undefined && user.caseExpressions!=null){
        user.caseExpressions.forEach(item =>{
          if(item.workflowId==selectedWf.workflowId){
            selectedCaseAccessExpressions.push(item);

          }
        })
      }
      this.setState({
        exercises: selectedWf.exercise,
        selectWorkflow: selectedWf.workflowId,
        caseAccessExpressions: selectedCaseAccessExpressions,
        isSegmented: selectedWf.segmentationWorkflow
      });

      this.updateWorkflowAttributes(selectedWf.workflowId);

    } else if (name === "exerciseIdList") {
      value = [...event.target.selectedOptions].map(option => option.value);

      this.setState({
        exerciseIdList: value
      });
    }else if (name === "userGroupList") {
      value = [...event.target.selectedOptions].map(option => option.value);
      this.setState({
        userGroupList: value
      });
    } else {
      this.setState({
        [event.target.name]: value
      });
    }
  };

  addRecord = () => {
    const {
      role,
      exerciseIdList,
      records,
      workflows,
      selectWorkflow,
      userGroupList
    } = this.state;

    var selectedWorkflow;
    var newData;

    if (selectWorkflow != null && role != null) {
      selectedWorkflow = _.find(workflows, { workflowId: selectWorkflow });

      let record = {};
      if(selectedWorkflow.segmentationWorkflow==="Y") {
        record = {
          selectWorkflow: selectedWorkflow.name,
          role: role,
          exerciseIdList: exerciseIdList,
          userGroupList: userGroupList
        };
      } else {
        record = {
          selectWorkflow: selectedWorkflow.name,
          role: role,
          exerciseIdList: [],
          userGroupList: userGroupList
        };
      }

      if (!_.find(records, { selectWorkflow: selectedWorkflow.name })) {
        newData = [...records, record];
        this.setState({
          records: newData,
          role: role
        });
      } else {
        this.setState({
          exerciseIdList: [],
          userGroupList: []
        });
      }
    }
  };

  updateRecord = () => {
    const { workflows, selectWorkflow, role, exercises, isEdit, editableRecordIndex, records, exerciseIdList, userGroupList } = this.state;
    let idx = editableRecordIndex
    let selectedWorkflow = _.find(workflows, { workflowId: selectWorkflow });
    let updatedRecords = [...records]

      updatedRecords[idx] = {
        selectWorkflow: selectedWorkflow.name,
        role: role,
        exerciseIdList: exerciseIdList,
        userGroupList: userGroupList
    };

      this.setState({
        records: updatedRecords,
        role: role,
        isEdit: false,
        editableRecordIndex: null
      })
  }

  deleteRecord = idx => {
    const { records } = this.state;
    records.splice(idx, 1);
    this.setState({
      records: records
    });
  };

  editRecord = (idx) => {
    const { records, workflows, user } = this.state;
    let selectedWf = records[idx].selectWorkflow;
    let wf = workflows.find(workflow => workflow.name === selectedWf)

    this.loadUserGroups(wf.workflowId)

    let userWf = wf.workflowId
    let userRole = records[idx].role
    let userEx = wf.exercise

    this.setState({ selectWorkflow: userWf, workflowDetailsVisibility: true, role: userRole, exercises: userEx, isEdit: true, editableRecordIndex: idx })
  }

  openCustomizeCaseAccessWindow = () => {
    let currentWorkflow = this.state.selectWorkflow;
    this.updateWorkflowAttributes(currentWorkflow);
    $(function () {
      var workflowId = $('#modal-customize-case-access').val();
      $('#modal-customize-case-access').modal('show');
    });
  }

  populateCaseMetaDataSelect = () => {
    $(function () {
      $(".select2").select2();
    })
  };

  updateWorkflowAttributes(workflowId){
    WorkflowAPI.getWorkflowAttributes(workflowId).then(data => {
      this.setState({
        workflowAttributes: data
      })
    })
  };

  addCaseAccessExpression = (optionList) => {
    const { caseAccessExpressions, selectWorkflow } = this.state;
    let isCondition = "";
    if (caseAccessExpressions.length > 1) {
      isCondition = $("#condition" + caseAccessExpressions[0].expressionId).val();
    }
    _.forEach(caseAccessExpressions, function (obj) {
      if (isCondition !== "") {
        if (obj.condition !== "" && obj.condition !== isCondition) {
          obj.condition = isCondition;
        }
      }
      let optionValue = $('#attribute' + obj.expressionId).val();
      let option = _.find(optionList, { attribute: optionValue });
      if (option !== undefined && option !== null && option !== "") {
        obj.type = option.type
      }
    }
    );   
    let newExpression = {
      id: null,
      expressionId : 1,
      attribute: "",
      operator: "eq",
      value: "",
      workflowId: selectWorkflow,
      condition: "",
      type: ""
    };

    var latestExpression = caseAccessExpressions[caseAccessExpressions.length-1];

    if(latestExpression!=undefined){
      newExpression.expressionId = latestExpression.expressionId+1
    }

    caseAccessExpressions.push(newExpression);
    this.setState({
      caseAccessExpressions: caseAccessExpressions,
      oldCondition: isCondition,
      selectedValue: ""
    });
  };

  removeCaseAccessExpression = idx => {
    const { caseAccessExpressions } = this.state;
    caseAccessExpressions.splice(idx, 1);
    let isCondition="";
        if(caseAccessExpressions.length>1){
            isCondition=$("#condition"+caseAccessExpressions[0].expressionId).val();
        }
    if (isCondition !== "") {
      _.forEach(caseAccessExpressions, function (obj) {
        if (obj.condition !== "" && obj.condition !== isCondition) {
          obj.condition = isCondition;
        }
      }
      );
    }
    this.setState({
      caseAccessExpressions: caseAccessExpressions,
      oldCondition: isCondition
    });
  };

  changeExpression(id, expressionId) {

  };

  handleSaveExpression = () => {
    const { caseAccessExpressions, selectWorkflow, workflowAttributes } = this.state;
    let newExData = [];
    caseAccessExpressions.forEach(item => {
      let value = "";
      if (item.type.toUpperCase() === "DATE") {
        value = item.value;
      } else {
        value = $('#expression' + item.expressionId).val();
      }
      let savedAttribute = $('#attribute' + item.expressionId).val();
      let workflowAttribute = _.find(workflowAttributes, { attribute: savedAttribute });
      if (workflowAttribute !== undefined && workflowAttribute !== null && workflowAttribute !== "") {
        item.type = workflowAttribute.type;
      }
      let record = {
        id: item.id,
        expressionId: item.expressionId,
        attribute: $('#attribute' + item.expressionId).val(),
        operator: $('#operator' + item.expressionId).val(),
        value: value,
        workflowId: selectWorkflow,
        condition: $('#condition' + item.expressionId).val(),
        type: item.type
      };
      newExData.push(record);
    })

    this.setState({
      caseAccessExpressions: newExData
    });
    $('#modal-customize-case-access').modal('hide');
  }

  changeCondition = event => {
    var id = event.target.id;
    var currentValue = $('#'+id).attr('value');
    if(currentValue=="AND"){
      $('#'+id).attr('value','OR');
      $('#'+id).html('OR');
    } else {
      $('#'+id).attr('value','AND');
      $('#'+id).html('AND');
    }
  }


  inputChangedHandler = (event) => {
    const updatedKeyword = event.target.value;
    // May be call for search result
  }

  handleDateChange = (selectedExpression, val) => {
    const { caseAccessExpressions } = this.state;
    let expression = _.find(caseAccessExpressions, {
      expressionId: selectedExpression.expressionId
    });
    expression.value = val;
    this.setState({
      caseAccessExpressions: caseAccessExpressions
    });
  }
  changeAttribute = (event, optionList) => {
    const { autoSuggestedAttributeValues, caseAccessExpressions } = this.state;
    _.forEach(caseAccessExpressions, function (obj) {
      let attribute = $('#attribute' + obj.expressionId).val();
      let selected = _.find(optionList, { attribute: attribute });
      if (selected !== undefined && selected !== null && selected !== "") {
        obj.type = selected.type;
        if (selected.type.toUpperCase() === 'DATE') {
          if (typeof obj.value === "string") {
            obj.value = new Date();
          }
        } else {
          obj.value = "";
        }
      }
    }
    );
    const selectedValue = event.target.value;
    let selected = _.find(optionList, { attribute: selectedValue });
    if (selected !== undefined && selected !== null && selected !== "") {
      this.setState({
        selectedType: selected.type
      });
    }
    var id = event.target.id;
    id = id.replace('attribute', '');

    $( function() {

      function autocompleteExpression(inp, arr) {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function(e) {
          var a, b, i, val = this.value;
          /*close any already open lists of autocompleted values*/
          closeAllLists();
          if (!val) { return false;}
          currentFocus = -1;
          /*create a DIV element that will contain the items (values):*/
          a = document.createElement("DIV");
          a.setAttribute("id", this.id + "autocomplete-list");
          a.setAttribute("class", "autocomplete-items");
          /*append the DIV element as a child of the autocomplete container:*/
          this.parentNode.appendChild(a);
          /*for each item in the array...*/
          for (i = 0; i < arr.length; i++) {
            /*check if the item starts with the same letters as the text field value:*/
            if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
              /*create a DIV element for each matching element:*/
              b = document.createElement("DIV");
              /*make the matching letters bold:*/
              b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
              b.innerHTML += arr[i].substr(val.length);
              /*insert a input field that will hold the current array item's value:*/
              b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
              /*execute a function when someone clicks on the item value (DIV element):*/
              b.addEventListener("click", function(e) {
                /*insert the value for the autocomplete text field:*/
                inp.value = this.getElementsByTagName("input")[0].value;
                /*close the list of autocompleted values,
                (or any other open lists of autocompleted values:*/
                closeAllLists();
              });
              a.appendChild(b);
            }
          }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function(e) {
          var x = document.getElementById(this.id + "autocomplete-list");
          if (x) x = x.getElementsByTagName("div");
          if (e.keyCode == 40) {
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            addActive(x);
          } else if (e.keyCode == 38) { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
          } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
              /*and simulate a click on the "autocompleteExpressionactive" item:*/
              if (x) x[currentFocus].click();
            }
          }
        });
        function addActive(x) {
          /*a function to classify an item as "active":*/
          if (!x) return false;
          /*start by removing the "active" class on all items:*/
          removeActive(x);
          if (currentFocus >= x.length) currentFocus = 0;
          if (currentFocus < 0) currentFocus = (x.length - 1);
          /*add class "autocomplete-active":*/
          x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
          /*a function to remove the "active" class from all autocomplete items:*/
          for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
          }
        }
        function closeAllLists(elmnt) {
          /*close all autocomplete lists in the document,
          except the one passed as an argument:*/
          var x = document.getElementsByClassName("autocomplete-items");
          for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
              x[i].parentNode.removeChild(x[i]);
            }
          }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
          closeAllLists(e.target);
        });
      }

      if(autoSuggestedAttributeValues[0][selectedValue]!=undefined){
        autocompleteExpression(document.getElementById("expression"+id), autoSuggestedAttributeValues[0][selectedValue]);
      }

    });
  }

  changeExpressionForCurrentCondition(id, condition){
    if(condition!=undefined && condition!=null && condition!=""){
      $('#'+id).attr('value',condition);
      $('#'+id).html(condition);
    }
  }

  generateAttributeName = (attribute) => {
    if(attribute.includes("metadata") || attribute.includes("auditInfo")){
        var valueArray = attribute.split(".");
        if(valueArray[1]!=undefined && valueArray[1]!=null){
            return valueArray[1];
        } else {
            return attribute;
        }
    } else {
        return attribute;
    }
  }

  generateSortedAttributeList =(workflowAttributesTemp)=> {
    let workflowAttributesSorted = workflowAttributesTemp.map(option=> {
      let attribName = this.generateAttributeName(option.attribute);
      if (
        attribName.includes("sessionRef") ||
        attribName.includes("workflowRef") ||
        attribName.includes("worker") ||
        attribName.includes("Attachments") ||
        attribName.includes("Assign Worker") ||
        attribName.includes("sessionRef") ||
        attribName.includes("worker") ||
        attribName.includes("workflowRef") ||
        attribName.includes("workflowId") ||
        attribName.includes("workflowName") ||
        attribName.includes("workflowVersion") ||
        attribName.includes("baseURL") ||
        attribName.includes("continuationURL") ||
        attribName.includes("stateName") ||
        attribName.includes("stateType") ||
        attribName.includes("caseState")) {
        return {
          id: option.id,
          attribute: option.attribute,
          attributeName: `${attribName} (default)`,
          type: option.type
      }
      } else {
        return {
          id: option.id,
          attribute: option.attribute,
          attributeName: attribName,
          type: option.type
        }
      }
        })
    workflowAttributesSorted.sort((a,b) => a.attributeName.localeCompare(b.attributeName))
    return workflowAttributesSorted;
  }

  handleWorkflowDetailsVisibility = event => {
    event.preventDefault()
    this.setState({ workflowDetailsVisibility: !this.state.workflowDetailsVisibility });
  }

  generateTitle = (type, key, title) => {
    const {t} = this.state;
    var translation = t(type+"."+key);
    if(translation.includes(type)){
      return title;
    } else {
      return translation;
    }
  }

  render() {
    const {
      user,
      action,
      errors,
      roles,
      t,
      workflows,
      exercises,
      records,
      selectWorkflow,
      role,
      isEdit,
      exerciseIdList,
      userGroupsList,
      workflowAttributes,
      caseAccessExpressions,
      workflowDetailsVisibility,
      oldCondition,
      isSegmented
    } = this.state;

    var attributesSorted = this.generateSortedAttributeList(workflowAttributes)
    const caseExpressionParams = { t, caseAccessExpressions, workflowAttributes, attributesSorted, oldCondition};   
    
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/protected/users/",
            search: "?action=view",
            state: { state: "view" }
          }}
        />
      );
    }

    return (
      <Fragment>
        <section className="content">
          <div className="row">
            <div className="col-md-6">
              <div className="box box-primary" id="add-user">
                <div className="box-header with-border">
                  <h3 className="box-title">
                    {action === "edit"
                      ? t("userManagement.updateUser.title")
                      : t("userManagement.addUser.title")}
                  </h3>
                </div>
                <form>
                  <div className="box-body">
                    <div
                      className={
                        errors.uniqueId ? "form-group has-error" : "form-group"
                      }
                    >
                      <label htmlFor="uniqueId">
                        {t("userManagement.addUser.uniqueId")}
                      </label>
                      <input
                        type="text"
                        name="uniqueId"
                        className="form-control"
                        id="uniqueId"
                        value={user.uniqueId}
                        onChange={this.handleChange}
                        placeholder={t(
                          "userManagement.addUser.placeholder.uniqueId"
                        )}
                        disabled={action === "edit"}
                      />
                      {errors.uniqueId && (
                        <span className="help-block">{errors.uniqueId}</span>
                      )}
                    </div>
                    <div className="col-lg-6" style={{ paddingLeft: 0 }}>
                      <div
                        className={
                          errors.firstName ? "form-group has-error" : "form-group"
                        }
                      >
                        <label htmlFor="firstName">
                          {t("userManagement.addUser.firstName")}
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          className="form-control"
                          id="firstName"
                          value={user.firstName}
                          onChange={this.handleChange}
                          placeholder={t(
                            "userManagement.addUser.placeholder.firstName"
                          )}
                        />
                        {errors.firstName && (
                          <span className="help-block">{errors.firstName}</span>
                        )}
                        </div>
                    </div>
                    <div className="col-lg-6" style={{ paddingRight: 0 }}>
                      <div
                        className={
                          errors.lastName ? "form-group has-error" : "form-group"
                        }
                      >
                        <label htmlFor="uniqueId">
                          {t("userManagement.addUser.lastName")}
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          className="form-control"
                          id="lastName"
                          value={user.lastName}
                          onChange={this.handleChange}
                          placeholder={t(
                            "userManagement.addUser.placeholder.lastName"
                          )}
                        />
                        {errors.lastName && (
                          <span className="help-block">{errors.lastName}</span>
                        )}
                        </div>
                    </div>
                    <div className="col-lg-6" style={{ paddingLeft: 0 }}>
                      <div
                        className={
                          errors.email ? "form-group has-error" : "form-group"
                        }
                      >
                        <label htmlFor="email">
                          {t("userManagement.addUser.email")}
                        </label>
                        <input
                          type="text"
                          name="email"
                          className="form-control"
                          id="email"
                          value={user.email}
                          onChange={this.handleChange}
                          placeholder={t(
                            "userManagement.addUser.placeholder.email"
                          )}
                        />
                        {errors.email && (
                          <span className="help-block">{errors.email}</span>
                        )}
                        </div>
                    </div>
                    
                    <div className="col-lg-6" style={{ paddingRight: 0 }}>
                      <div
                        className={
                          errors.contactNumber
                            ? "form-group has-error"
                            : "form-group"
                        }
                      >
                        <label htmlFor="contactNumber">
                          {t("userManagement.addUser.contactNumber")}
                        </label>
                        <input
                          type="text"
                          name="contactNumber"
                          className="form-control"
                          id="contactNumber"
                          value={user.contactNumber}
                          onChange={this.handleChange}
                          placeholder={t(
                            "userManagement.addUser.placeholder.contactNumber"
                          )}
                        />
                        {errors.contactNumber && (
                          <span className="help-block">
                            {errors.contactNumber}
                          </span>
                        )}
                        </div>
                      </div>
                    <div
                      className={
                        errors.status ? "form-group has-error" : "form-group"
                      }
                    >
                      <label htmlFor="Status">
                        {t("userManagement.addUser.status")}
                      </label>

                      <div className="checkbox" id="activeSettingUser">
                        <label>
                          <input
                            type="checkbox"
                            name="status"
                            value={user.status}
                            onChange={this.handleChange}
                            defaultChecked={true}
                          />
                          {t("userManagement.addUser.active")}
                        </label>
                        {errors.status && (
                          <span className="help-block">{errors.status}</span>
                        )}
                      </div>
                    </div>

                    <hr/>

                    <h4 className="section-header">{t("userManagement.workflowAccessSection.workflowAccessHeader")} </h4>
                    {!workflowDetailsVisibility && <button className="btn btn-md btn-default" onClick={this.handleWorkflowDetailsVisibility}> {t("userManagement.workflowAccessSection.accessButtonTitle")}   <i className="fa fa-plus-circle"></i></button>}
                    {workflowDetailsVisibility &&
                      <div>
                      <p className="info-text">{t("userManagement.workflowAccessSection.workflowAccessHelpText")}</p>
                      <div
                        className={
                          errors.workflow
                            ? "input-group has-error"
                            : "input-group"
                        }
                      >
                        <div className="col-lg-6" style={{ paddingLeft: 0 }}>
                          <label>
                            {t("userManagement.addUser.placeholder.workflow")}
                          </label>

                          <select
                            className="form-control"
                            id="workflow"
                            name="workflow"
                            onChange={this.handleWorkflowChange}
                            value={
                              workflows
                                ? _.find(workflows, {
                                    workflowId: selectWorkflow
                                  }).name
                                : ""
                            }
                            disabled={
                              records && workflows
                                ? records.length === workflows.length
                                : false
                            }
                            >
                            {workflows &&
                              workflows.map(option => {
                                return (
                                  <option
                                    key={option.workflowId}
                                    value={option.name}
                                  >
                                    {option.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="col-lg-6" style={{ paddingRight: 0 }}>
                          <label>
                            {t("userManagement.addUser.placeholder.role")}
                          </label>

                          <select
                            className="form-control"
                            id="role"
                            name="role"
                            onChange={this.handleWorkflowChange}
                            value={role ? role : ""}
                            disabled={
                              records && workflows
                                ? records.length === workflows.length
                                : false
                            }
                          >
                            {roles &&
                              roles.map(option => {
                                return (
                                  <option key={option.id} value={option.name}>
                                    {this.generateTitle("roles",option.name,option.name)}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                          <div className="col-lg-6" style={{ paddingLeft: 0 }}>
                          <div
                            className="form-group"
                            style={{ marginTop: 15, height:50 }}
                          >
                              <label>
                              {t("userManagement.addUser.placeholder.group")}
                              </label>
                            <select
                              multiple={true}
                              className="form-control"
                              name="userGroupList"
                              onChange={this.handleWorkflowChange}
                              style={{height:50 }}
                            >
                              {userGroupsList &&
                                userGroupsList.map(option => {
                                  return (
                                     <option key={option.id} value={option.groupTitle}>
                                      {option.groupTitle}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                          <div className="col-lg-6" style={{ paddingRight: 0 }}>
                            {exercises.length > 0 && (
                              <div
                                className={
                                  errors.workflow
                                    ? "form-group has-error"
                                    : "form-group"
                                }
                                style={{ marginTop: 15, height:50 }}
                              >
                                <label>{t("userManagement.addUser.placeholder.subflows")}</label>
                                <select
                                  multiple={true}
                                  className="form-control"
                                  name="exerciseIdList"
                                  onChange={this.handleWorkflowChange}
                                  disabled={
                                    records && workflows
                                      ? records.length === workflows.length
                                      : false
                                  }
                                  style={{height:50 }}
                                >
                                  {exercises &&
                                    exercises.map(option => {
                                      return (
                                        <option key={option.id} value={option.code}>
                                          {option.code}
                                        </option>
                                      );
                                    })}
                                </select>
                                {errors.workflow && (
                                  <span
                                    className="help-block"
                                    style={{ color: "#dd4b39" }}
                                  >
                                    {errors.workflow}
                                  </span>
                                )}
                              </div>
                          )}
                        </div>
                      </div>
                    
                      <div className={"form-group"} style={{ marginTop: 15 }}>
                        <button
                            type="button"
                            onClick={this.openCustomizeCaseAccessWindow}
                            className="btn btn-primary"
                            id="caseAccess"
                        >
                            {t("userManagement.caseAccessLevels.buttonTitle")}  <i className="fa fa-pencil"></i>
                        </button>
                        </div>

                      <div className="modal-footer">
                          <button
                            type="button"
                            id="accessSave"
                            className="btn btn-default"
                            onClick={isEdit ? this.updateRecord : this.addRecord}
                            disabled={isSegmented==="Y" && exerciseIdList.length < 1}
                          >{t("common.add")}
                          </button>
                          <button
                            type="button"
                            id="accessCancel"
                            className="btn btn-default"
                            onClick={this.handleWorkflowDetailsVisibility}
                            >{t("common.close")}
                          </button>
                      </div>
                      </div>}
                      {records.length > 0 && (
                      <div className="form-group" style={{ marginTop: 30 }}>
                        <div>
                        <h4 className="section-header">{t("userManagement.workflowAccessSection.grantedAccesHeader")}</h4>
                          {records.length > 0 &&
                              records.map((record, idx) => (
                                <div className="box box-solid">
                                <div className="box-body">
                                  <table className="table table-bordered">
                                  <thead>
                                      <tr>
                                        <th>{t("userManagement.workflowAccessSection.workflow")}</th>
                                        <th style={{ width: 30 }}>{t("userManagement.workflowAccessSection.role")}</th>
                                        <th>{t("userManagement.workflowAccessSection.groups")}</th>
                                        <th style={{ width: 40 }}>{t("userManagement.workflowAccessSection.subflows")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr key={record.selectWorkflow}>
                                        <td>{record.selectWorkflow}</td>
                                        <td> {this.generateTitle("roles",record.role,record.role)}</td>
                                        <td>
                                          {record.userGroupList && record.userGroupList.map((item, idx) => {
                                            return <li key={idx}>{item}</li>;
                                          })}
                                        </td>
                                        <td>
                                          {record.exerciseIdList && record.exerciseIdList.map((item, idx) => {
                                          return <li key={idx}>{item}</li>;
                                          })}
                                        </td>
                                        <td style={{ position:'relative', left: 35 }}>
                                            <i className="fa fa-pencil fa-lg" style={{ marginRight: 5 }} onClick={e => {
                                              e.preventDefault();
                                              this.editRecord(idx);
                                            }}></i>
                                          <i className="fa fa-trash-o fa-lg" aria-hidden="true" onClick={e => {
                                            e.preventDefault();
                                            this.deleteRecord(idx);
                                            }}></i>
                                          </td>
                                        </tr>
                                    </tbody>
                                  </table>
                                </div>
                                </div>
                            ))}
                          </div>
                        </div>
                      )}
                  </div>
                </form>

                <div className="box-footer">
                  <button
                    type="submit"
                    onClick={this.handleSave}
                    className="btn btn-primary"
                  >
                    {action === "edit"
                      ? t("common.update")
                      : t("common.submit")}
                  </button>
                  <button
                    onClick={this.handleCancel}
                    className="btn btn-primary"
                    style={{ marginLeft: 10 }}
                  >
                    {t("common.cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <CaseExpression params={caseExpressionParams}
            addCaseAccessExpression={this.addCaseAccessExpression}
            removeCaseAccessExpression={this.removeCaseAccessExpression}
            handleSaveExpression={this.handleSaveExpression}
            changeAttribute={this.changeAttribute}
            handleDateChange={this.handleDateChange}
            title={t("userManagement.caseAccessLevels.windowTitle")}
          />
        </section>
      </Fragment>
    );
  }
}

export default withTranslation("common")(AddUser);
