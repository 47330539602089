import React, { Component, Fragment } from "react";
import UserAPI from "../../api/UserAPI";
import PortalUser from "../../dto/PortalUser";
import AuthUtil from "../../utils/AuthUtil";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import _ from "lodash";

class Profile extends Component {
  state = {
    user: new PortalUser(),
    exercises: [],
    errors: {},
    redirect: false,
    t: this.props.t,
    records: []
  };

  loadUser = () => {
    AuthUtil.getUserId().then(id => {
      UserAPI.getProfile(id)
        .then(user => {
          let userDto = user;

          let finalRecordsList = [];
          _.forEach(user.workflowList, n => {
            let record = {};

            record["selectWorkflow"] = n["workflow"].name;
            record["role"] = n["role"].name;
            let exercises = [];
            let groups = [];

            n["exercises"].forEach(item => {
              exercises.push(item.code);
            });


            if(n["userGroups"]) {
              n["userGroups"].forEach(item => {
                groups.push(item.groupTitle);
              });

              record["userGroupList"] = groups;
            }

            record["exerciseIdList"] = exercises;

            finalRecordsList.push(record);
          });

          this.setState({ user: userDto, records: finalRecordsList });
        })
        .catch(error => console.error(error));
    });
  };

  componentDidMount() {
    this.loadUser();
  }

  isFormValid = () => {
    const errors = {};
    if (!this.state.user.firstName)
      errors.firstName = "First name is required.";
    if (!this.state.user.lastName) errors.lastName = "Last name is required.";
    if (!this.state.user.email) errors.email = "Email is required.";
    if (!this.state.user.contactNumber)
      errors.contactNumber = "Contact number is required.";

    this.setState({
      errors: errors
    });
    return Object.keys(errors).length === 0;
  };

  handleChange = event => {
    let { value } = event.target;
    if (event.target.options) {
      value = [...event.target.selectedOptions].map(option => option.value);
    }
    this.setState({
      user: {
        ...this.state.user,
        [event.target.name]: value
      }
    });
  };

  handleSave = event => {
    event.preventDefault();
    if (!this.isFormValid()) return;

    let { user } = this.state;

    UserAPI.updateProfile(user)
      .then(() => {
        this.loadUser();
      })
      .catch(error => {});
  };

  generateTitle = (type, key, title) => {
    const {t} = this.state;
    var translation = t(type+"."+key);
    if(translation.includes(type)){
      return title;
    } else {
      return translation;
    }
  }
  render() {
    const { user, errors, records, t } = this.state;
    return (
      <Fragment>
        <div className="content-wrapper">
          <section className="content-header">
            <h1>
              {t("userProfile.title")} <small />
            </h1>
          </section>
          <Fragment>
            <section className="content">
              <div className="row">
                <div className="col-md-6">
                  <div className="box box-primary">
                    <div className="box-header with-border">
                      <h3 className="box-title">{t("userProfile.cardTitle")}</h3>
                    </div>
                    <div className="box-body">
                      <form>
                        <div className="box-body" />

                        <div className="form-group">
                          <label htmlFor="uniqueId">
                            {t("userProfile.uniqueId")}
                          </label>
                          <input
                              type="text"
                              name="uniqueId"
                              className="form-control"
                              id="uniqueId"
                              value={user.uniqueId}
                              disabled
                          />
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                                className={
                                  errors.firstName
                                      ? "form-group has-error"
                                      : "form-group"
                                }
                            >
                              <label htmlFor="firstName">
                                {t("userProfile.firstName")}
                              </label>
                              <input
                                  type="text"
                                  name="firstName"
                                  className="form-control"
                                  id="firstName"
                                  value={user.firstName || ""}
                                  onChange={this.handleChange}
                                  placeholder={t("userProfile.placeholder.firstName")}
                              />
                              {errors.firstName && (
                                  <span className="help-block">
                              {errors.firstName}
                            </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                                className={
                                  errors.lastName
                                      ? "form-group has-error"
                                      : "form-group"
                                }
                            >
                              <label htmlFor="uniqueId">
                                {t("userProfile.lastName")}
                              </label>
                              <input
                                  type="text"
                                  name="lastName"
                                  className="form-control"
                                  id="lastName"
                                  value={user.lastName || ""}
                                  onChange={this.handleChange}
                                  placeholder={t("userProfile.placeholder.lastName")}
                              />
                              {errors.lastName && (
                                  <span className="help-block">
                              {errors.lastName}
                            </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div
                                className={
                                  errors.email ? "form-group has-error" : "form-group"
                                }
                            >
                              <label htmlFor="email">
                                {t("userProfile.email")}
                              </label>
                              <input
                                  type="text"
                                  name="email"
                                  className="form-control"
                                  id="email"
                                  value={user.email || ""}
                                  onChange={this.handleChange}
                                  placeholder={t("userProfile.placeholder.email")}
                              />
                              {errors.email && (
                                  <span className="help-block">{errors.email}</span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div
                                className={
                                  errors.contactNumber
                                      ? "form-group has-error"
                                      : "form-group"
                                }
                            >
                              <label htmlFor="contactNumber">
                                {t("userProfile.contactNumber")}
                              </label>
                              <input
                                  type="text"
                                  name="contactNumber"
                                  className="form-control"
                                  id="contactNumber"
                                  value={user.contactNumber || ""}
                                  onChange={this.handleChange}
                                  placeholder={t(
                                      "userProfile.placeholder.contactNumber"
                                  )}
                              />
                              {errors.contactNumber && (
                                  <span className="help-block">
                              {errors.contactNumber}
                            </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                            className={
                              errors.exerciseIdList
                                  ? "form-group has-error"
                                  : "form-group"
                            }
                        >
                          <label>{t("userProfile.Workflows")}</label>

                          <table className="table table-bordered">
                            <thead>
                            <tr>
                              <th>{t("userProfile.workflow")}</th>
                              <th>{t("userProfile.roles")}</th>
                              <th>{t("userManagement.workflowAccessSection.groups")}</th>
                              <th>{t("userProfile.exerciseId")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {records.map((item, idx) => (
                                <tr key={item.selectWorkflow}>
                                  <td>{item.selectWorkflow}</td>
                                  <td>{this.generateTitle("roles",item.role,item.role)}</td>
                                  <td>
                                    {item.userGroupList && item.userGroupList.map((data, idx) => {
                                      return <li key={idx}>{data}</li>;
                                    })}
                                  </td>
                                  <td>
                                    {item.exerciseIdList.map(
                                        (exerciseId, idx) => {
                                          return (
                                              <li
                                                  key={idx}
                                                  style={{ listStyleType: "none" }}
                                              >
                                                {exerciseId}
                                              </li>
                                          );
                                        }
                                    )}
                                  </td>
                                </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>
                      </form>
                    </div>
                    <div className="box-footer">
                      <button
                        type="submit"
                        onClick={this.handleSave}
                        className="btn btn-primary"
                      >
                        {t("common.update")}
                      </button>
                      <Link
                        to={{
                          pathname: "/protected"
                        }}
                        className="btn btn-primary"
                        style={{ marginLeft: 10 }}
                      >
                        {t("common.cancel")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Fragment>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation("common")(Profile);
