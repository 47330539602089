import { handleResponse, handleError } from "../services/serviceUtil";
import ApiService from "../auth/ApiService";

const apiEndpoint = process.env.REACT_APP_CMP_API_URL;
const resourceUrl = apiEndpoint + "/secured/auth";

const AuthAPI = {

  login: async function() {
    return await new ApiService()
      .callApi(`${resourceUrl}/login`, "post")
      .then(handleResponse)
      .catch(handleError);
  },
  logout: function() {
    return new ApiService()
      .callApi(`${resourceUrl}/logout`, "delete")
      .then(handleResponse)
      .catch(handleError);
  },
};

export default AuthAPI;
